import React, {useContext} from "react";
import { Route, Redirect } from "react-router-dom";
import { UserContext } from "./context";

export default ({ component: Component, ...rest }) => {
  const auth = useContext(UserContext);
  if(auth.loggedIn === null) return null;
  return (
    <Route
    {...rest}
    render={(props) => 
      auth && auth.user ? (
          <Component {...props} auth={auth}/>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location }
            }}
          />
        )
      }
    />
  );
}
