import React, { useState } from 'react'
import styled from 'styled-components'
import coverages from './coverages'
import downChevron from './images/down-chevron-white.svg'

const PaymentCoverage = ({quote}) => {

  const [isToggled, setIsToggled] = useState(true)
  const toggle = () => setIsToggled(val => !val)

  return (
    <div className="payment-coverage">
      <div className="payment-coverage__main">
        <div className="payment-coverage__header">
          <div className="payment-coverage__type">{quoteType(quote)}</div>
          <div className="payment-coverage__view" onClick={toggle}>View coverage <DownChevron src={downChevron} className={isToggled ? 'up' : ''} /></div>
        </div>
        <div className="payment-coverage__premium">
          <div className="payment-coverage__desc">Monthly premium</div>
          <div className="payment-coverage__amount">${quote.monthlyPremium}/month</div>
        </div>
      </div>
      {isToggled && <div className="payment-coverage__detail">
        {coverages.filter(cov => !cov.hideInSummary).map(cov => (
          <div className="payment-coverage__sub" key={cov.key}>
            <div className="payment-coverage__sub-title">{cov.name}</div>
            <div className="payment-coverage__sub-desc">{cov.desc}</div>
            <div className="payment-coverage__sub-amount">
              {cov.format ? cov.format(quote[cov.key]) : quote[cov.key]}
            </div>
          </div>
        ))}
      </div>}
    </div>
  )
}

export default PaymentCoverage

const DownChevron = styled.img`
  height: 0.4rem;
  margin: 0 0.5rem;

  &.up {
    transform: rotate(180deg);
  }
`

const quoteTypeNames = {
  good: 'Good',
  better: 'Better',
  best: 'Best'
}
function quoteType(quote) {
  return quoteTypeNames[quote.name] || quote.name
}
