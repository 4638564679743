import React, {Fragment, useState} from 'react'
import InfoPage from './InfoPage'
import footerImage from './img/search-shapes-footer.svg'
import { Link } from 'react-router-dom'
import AsyncSelect from 'react-select/async'
import classCodeLookup from './config/classCodeLookup'

const loadOptions = searchText => classCodeLookup('AZ', searchText)
const getName = type => type.name

export default () => {
  const [industry_type, setIndustryType] = useState('')
  return (
    <InfoPage className="page search-page" render={render} />
  )

  function render(openQuote) {
    return (
      <Fragment>
        <section className="page-core">
          <div className="regular-container">
            <div className="chapeau">Who we insure</div>
            <h1 className="medium-margin-top">Trym insures small businesses<br /> with 1-20 employees</h1>
            <p>The backbone, lifeblood, and soul of the American economy.</p>
            <div className="search-bar">
              <AsyncSelect
                placeholder="Search for your profession"
                defaultOptions
                loadOptions={loadOptions}
                getOptionLabel={getName}
                getOptionValue={getName}
                value={industry_type}
                onChange={setIndustryType}
              />
            </div>
  
            <div className="action-buttons">
              <button className="button primary big" onClick={openQuote}>Start your quote</button>&nbsp;
              <Link className="button primary big" to="/coverage">See what’s covered</Link>
            </div>
          </div>
        </section>
  
        <img src={footerImage} className="fluid-img" alt="" />
      </Fragment>
    )
  }  
}
