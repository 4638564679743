import firebase from 'firebase/app'

export const uiConfig = {
    'signInSuccessUrl': '.',
    signInOptions: [
        // Leave the lines as is for the providers you want to offer your users.
       // firebase.auth.GoogleAuthProvider.PROVIDER_ID,
       // firebase.auth.FacebookAuthProvider.PROVIDER_ID,
        // firebase.auth.TwitterAuthProvider.PROVIDER_ID,
        firebase.auth.EmailAuthProvider.PROVIDER_ID
    ],
    callbacks: {
        signInSuccessWithAuthResult(authResult, redirectUrl) {
            // var user = authResult.user
            // var credential = authResult.credential
            // var isNewUser = authResult.additionalUserInfo.isNewUser
            // var providerId = authResult.additionalUserInfo.providerId
            // var operationType = authResult.operationType
            // Do something with the returned AuthResult.
            // Return type determines whether we continue the redirect automatically
            // or whether we leave that to developer to handle.
            return false
        },
        signInFailure(error) {
            console.error(error)
            // Some unrecoverable error occurred during sign-in.
            // Return a promise when error handling is completed and FirebaseUI
            // will reset, clearing any UI. This commonly occurs for error code
            // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
            // occurs. Check below for more details on this.
            return null // handleUIError(error);
        }
    },
    // Terms of service url
    tosUrl: '/tos',
    privacyPolicyUrl: '/privacy-policy'
}
