import React from 'react'
import Confetti from 'react-confetti'
import './react-modal.css'
import logo from './images/trym-logo-black.svg'
import { PrimaryButtonLink } from './PrimaryButton'

const Paid = () => {
  return (
    <div className="home q-flow">
      <Confetti colors={['#a1cc37', '#a1cc37', '#a1cc37', '#919bfa', '#ffb36a']} />
      <div className="home__top">
        <div className="home__nav">
          <a className="home__logo" href="/"><img src={logo} alt="trym logo" /></a>
        </div>
        <div className="home__top-container">
          <div style={{fontSize: '1.5em', fontWeight: '500', color: '#222'}}>
            Your business is insured! Watch your inbox for your policy.
          </div>
          <PrimaryButtonLink href="/dashboard">View policy</PrimaryButtonLink>
        </div>
      </div>
    </div>
  )
}

export default Paid