import React, { useState, useEffect, useContext } from "react";
import api from "../../api/index";
import Loading from "../../Loading";
import { UserContext } from "../../context";
import DashboardFooter from "../../components/DashboardFooter";
import Header from "../../components/Header";
import FormError from "../../components/FormError";
import Alert from "../../components/Alert";
import claimLogo from "../../img/info-claim-icon.svg";
import moment from "moment";


export default ({ history, location, auth }) => {
  const [claims, setClaims] = useState("");
  const [success, setSuccess] = useState("");

  const [errors, setError] = useState({
    fields: {
      claimTitle: "",
      claimDetail: "",
      claimDate: "",
      claimFirstName: "",
      claimPhone: "",
      claimRole: "",
      claimEmail: ""
    },
    message: ""
  });

  const userContext = useContext(UserContext);
  const currentUser = userContext && userContext.user;
  useEffect(() => {
    if (!currentUser) return;
    async function getData() {
      const claims = await api.get(userContext.token, `/api/claims`);
      setClaims(claims.data);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const validate = (data) => {
    let hasError = false;
    const formErrors = {};
    if (data.claimTitle === "") {
      hasError = true;
      formErrors.claimTitle = "Title is required";
    }

    if (data.claimDetail === "") {
      hasError = true;
      formErrors.claimDetail = "Claim details are requied";
    }

    if (data.claimDate === "") {
      hasError = true;
      formErrors.claimDate = "Claim date is required";
    }

    if (data.claimFirstName === "") {
      hasError = true;
      formErrors.claimFirstName = "First name is required";
    }

    if (data.claimPhone === "") {
      hasError = true;
      formErrors.claimPhone = "Phone is required";
    }

    if (data.claimRole === "") {
      hasError = true;
      formErrors.claimRole = "Role is required";
    }

    if (data.claimEmail === "") {
      hasError = true;
      formErrors.claimEmail = "Claim email is requied";
    }

    setError({...errors, fields: {...formErrors}});
    return hasError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = {
        claimTitle: e.target.claimTitle.value,
        claimDetail: e.target.claimDetail.value,
        claimDate: e.target.claimDate.value,
        claimFirstName: e.target.claimFirstName.value,
        claimPhone: e.target.claimPhone.value,
        claimRole: e.target.claimRole.value,
        claimEmail: e.target.claimEmail.value,
      };
  
      if (validate(data)) {
        return;
      }
      const resp = await api.post(userContext.token, `/api/claims`, JSON.stringify(data));
      if(!resp.success) {
        setError({...errors, message: resp.message });
      } else {
        setSuccess(resp.message);
      }
    } catch(error) {
      setError({...errors, message: error });
    }    
  };

  if (!userContext || !claims || !userContext.user) {
    return <Loading text="Loading your policy information..." />;
  }

  return (
    <div className="page">
      <Header location={location} />
      <section className="green-hero-section">
        <div className="regular-container">
          <h1 className="page-title text-center">Claims</h1>
        </div>
      </section>

      <section className="main-content">
        <div className="regular-container">
          <div className="main-content_utilities">
            <div className="back-button" onClick={() => history.goBack()}>
              Go back
            </div>
          </div>

          <form onSubmit={handleSubmit}>
            <div className="main-content_box">
              <h2 className="large-margin-bottom">File a new claim</h2>

              <input
                type="text"
                name="claimTitle"
                className={errors.fields.claimTitle ? "error" : ""}
                placeholder="Write a title for what happened (eg. customer slipped and fell)"
              />
              <FormError error={errors.fields.companyName} />
              <textarea
                name="claimDetail"
                placeholder="Please provide all the details on the event. Tell us the story "
                rows="6"
                className={errors.fields.claimDetail ? "error" : ""}
              ></textarea>
              <FormError error={errors.fields.claimDetail} />

              <div className="row">
                <div className="col-1-2">
                  <p className="text-dark small-margin-top">
                    <b>Date of the event</b>
                  </p>
                </div>
                <div className="col-1-2">
                  <input
                    type="date"
                    name="claimDate"
                    className={errors.fields.claimDate ? "error" : ""}
                  />
                  <FormError error={errors.fields.claimDate} />
                </div>
              </div>
              <div className="row">
                <div className="col-1-2">
                  <p className="text-dark small-margin-top">
                    <b>Who is sumitting the claim</b>
                  </p>
                </div>
                <div className="col-1-2">
                  <div className="select-style" >
                    <select>
                      <option>{currentUser.displayName}</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="large-margin-top large-margin-bottom">
                <img
                  alt="Claim Logo"
                  src={claimLogo}
                  style={{ float: "left", marginRight: "15px" }}
                />
                <p className="claim-filer-requirement">
                  Please provide more information so we can
                  <br />
                  follow up on the claim with the right person
                </p>
              </div>
              <div className="row">
                <div className="col-1-2">
                  <input
                    type="text"
                    name="claimFirstName"
                    placeholder="First name"
                    className={errors.fields.claimFirstName ? "error" : ""}
                  />
                  <FormError error={errors.fields.claimFirstName} />
                </div>
                <div className="col-1-2">
                  <input
                    type="phone"
                    name="claimPhone"
                    placeholder="Phone number"
                    className={errors.fields.claimPhone ? "error" : ""}
                  />
                  <FormError error={errors.fields.claimPhone} />
                </div>
              </div>
              <input
                type="email"
                name="claimEmail"
                placeholder="Email"
                className={errors.fields.claimEmail ? "error" : ""}
              />
              <FormError error={errors.fields.claimEmail} />

              <input
                type="text"
                name="claimRole"
                placeholder="Company role"
                className={errors.fields.claimRole ? "error" : ""}
              />
              <FormError error={errors.fields.claimRole} />

              <div className="text-center large-margin-top">
                <button type="submit" className="button primary">Submit claim</button>
                <p>We will send you email updates about your claims status</p>
              </div>

              {errors.message && (
                <Alert type="error" message={errors.message} />
              )}
              {success && (
                <Alert type="success" message={success} />
              )}
            </div>
          </form>
          <div className="main-content_box large-margin-top claim-history">
            <h2 className="large-margin-bottom">Previous claims</h2>

            { claims && claims.map((claim, idx) => {
              return (<div key={idx} className="receipt-table-item">
              <div className="receipt-table-item_left">
                <p className="no-margin">{claim.claimTitle}</p>
                <p className="text-small">Filed on {moment(claim.createdAt).format(
                          "MM/DD/YYYY"
                        )}</p>
              </div>
              <div className="receipt-table-item_right">
                <div className={`claim-status-label ${claim.status}`}>{claim.status}</div>
              </div>
            </div>)
            })

          }
          </div>
          <div className="support-box">
            <div className="support-box_text">
              <h2 className="text-white">Support</h2>
              <p className="text-white">
                For any additional help, pelase send us an email, a fellow human
                will respond to you.
              </p>
            </div>
            <div className="support-box_actions">
              <a
                href="mailto:help@trym.co"
                className="support-box_actions_email-adress"
              >
                help@trym.co
              </a>
            </div>
          </div>
        </div>
      </section>
      <DashboardFooter />
    </div>
  );
};
