import React, { PureComponent } from 'react'
import Header from './Header'
import TrymProgress from './components/TrymProgress'


export default class Pending extends PureComponent {
  state = {}

  answers = () => {
    const state = (this.props.location || {}).state || {}
    return {...state.answers}
  }

  render() {
    const {auth} = this.props

    const {user} = auth

    return (
      <div className="app q-flow">
        <Header>
          <TrymProgress step={3} />
          { user
            ? <button className="header__button button blue" onClick={this.logout}>Logout</button>
            : <button className="header__button button blue" onClick={this.login}>Login</button>
          }
        </Header>
        <div className="app__main">
          <a style={{fontSize: '32px', margin: '10px', textAlign: 'center', display: 'block'}} href={`api/answers-pdf?answers=${encodeURIComponent(JSON.stringify(this.answers()))}`}>View PDF</a>
          <div style={{fontSize: '10px', marginTop: '100px'}}>{JSON.stringify(this.answers(), null, 2)}</div>
        </div>
      </div>
    )
  }

  login = () => {
    this.props.auth.login()
  }
  logout = () => {
    this.props.auth.logout()
  }
}
