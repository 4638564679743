import React, {Fragment} from 'react'
import InfoPage from './InfoPage'
import uiClaims from './img/ui-claims.png'

export default () => (
  <InfoPage className="page claims-page" render={render} />
)

function render(openQuote) {
  return (
    <Fragment>
      <section className="page-header">
        <div className="regular-container">
          <h1>Bad thing happened. Claim filed.<br /><span className="text-green">Problem solved.</span></h1>
        </div>
        <div className="regular-container">
          <img src={uiClaims} className="fluid-img claims-ui" alt="Page to file a claim. Write a title for what happened (for example, customer slipped and fell). Please provide all the details of the event. Tell us the whole story." />
        </div>
      </section>

      <section className="hiw-certificate">
        <div className="regular-container">
          <h2 className="large-margin-bottom">How it works</h2>
          
          <div className="list-item">
            <div className="list-number">1</div>
            <div>
              <h3>You tell us what happened</h3>
              <p>Fill out a simple form and upload any relevant docs and images. It only takes a few minutes.</p>
            </div>
          </div>
          <div className="list-item">
            <div className="list-number">2</div>
            <div>
              <h3>We review your claim</h3>
              <p>Our team will confirm what happened is covered in your policy, and will reach out with any follow up questions.</p>
            </div>
          </div>
          <div className="list-item">
            <div className="list-number">3</div>
            <div>
              <h3>Your claim is resolved</h3>
              <p>We’ll let you know as soon as we’ve completed reviews on our end, then begin the payment process.</p>
            </div>
          </div>
          <div className="list-item green">
            <div className="list-number">4</div>
            <div>
              <h3>You get paid</h3>
              <p>So you can fix the bad thing that happened. Insurance does come in handy after all.</p>
            </div>
          </div>

        </div>
      </section>


      <div className="bottom-cta">
        <div className="large-container">
          <div className="cta-box">
            <h2 className="text-center medium-margin-bottom">Buy a policy,<br />insure your business</h2>
            <button className="button primary full-width" onClick={openQuote}>Start your quote</button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
