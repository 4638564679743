import React from 'react'
import styled from 'styled-components'
import _Select from './Select'
import _SelectLookup from './SelectLookup'
import _Slider, {IntervalSlider as _IntervalSlider} from './Slider'
import _Text, {Money as _Money, Paragraph as _Paragraph} from './Text'
import _Toggle from './Toggle'
import _Calculated from './Calculated'
import _AddMoreQuestionGroup from './AddMoreQuestionGroup'

const NullComponent = () => null
export const Select = updater(wrapQuestion(_Select, 'label', true))
export const SelectLookup = updater(wrapQuestion(_SelectLookup))
export const Slider = updater(wrapQuestion(_Slider))
export const IntervalSlider = updater(wrapQuestion(_IntervalSlider))
export const Text = updater(wrapQuestion(_Text, 'label', true))
export const Money = updater(wrapQuestion(_Money, 'label', true))
export const Paragraph = updater(wrapQuestion(_Paragraph))
export const Toggle = updater(wrapQuestion(_Toggle))
export const Calculated = updater(wrapQuestion(_Calculated))
export const AddMoreQuestionGroup = updater(wrapQuestion(_AddMoreQuestionGroup, 'div'))
export const Statement = wrapQuestion(NullComponent)

export function updater(Cmp) {
  return props => {
    const {answers, setAnswers, propPath} = props
    const value = propPath.reduce((obj, p) => obj[p], answers)
    const onChange = setFactory(setAnswers)(...propPath)
    return <Cmp {...props} value={value} onChange={onChange} />
  }
}

function wrapQuestion(Cmp, Wrapper = 'label', hasPlaceholderAsLabel = false) {
  return props => {
    const {className, title, desc, bulletDesc, automaticallyIncluded, comingSoon} = props
    const showLeft = title != null && (desc != null || !hasPlaceholderAsLabel)
    const renderedCmp = <Cmp {...props} />

    return (
      <Wrapper className={'question' + (className ? ' ' + className : '')}>
        {showLeft && <Left>
          {automaticallyIncluded === true && <div className="chiclet green">AUTOMATICALLY INCLUDED</div>}
          {!!comingSoon && <div className="chiclet black">{comingSoon}</div>}
          { title ? <div className="question__title">{title}</div> : null }
          { desc ? <div className="question__desc">{desc}</div> : null }
        </Left>}
        {renderedCmp != null && <Right>
          { renderedCmp }
        </Right>}
        { bulletDesc
          ? <ul className="question__bullet-desc">
              {bulletDesc.map((x, i) => <li key={i}><span className="question__desc">{x}</span></li>)}
            </ul>
          : null }
      </Wrapper>
    )
  }
}

export const setFactory = setValue => (...path) => newValue => {
  setValue(value => {
    function drill(objOrArray, propIndex) {
      if (propIndex === path.length) return newValue
      const prop = path[propIndex]
      const newObjOrArray = Array.isArray(objOrArray) ? [...objOrArray] : Object.assign({}, objOrArray)
      newObjOrArray[prop] = drill(objOrArray[prop], propIndex + 1)
      return newObjOrArray
    }
    return drill(value, 0)
  })
}
const Left = styled.div`
  flex: 3;
  padding-right: 1rem;
`
const Right = styled.div`
  flex: 2;
`
