import React, {Fragment} from 'react'
import InfoPage from './InfoPage'
import states from './config/licenses'

export default () => (
  <InfoPage className="page licenses-page" render={render} />
)

function render(openQuote) {
  return (
    <Fragment>
      <section className="page-header" style={{background: '#ffffff'}}>
        <div className="small-container">
          <div className="chapeau">Where we insure</div>
          <h1 className="medium-margin-top">States we're in</h1>
        </div>
      </section>

      <section className="content">
        <div className="regular-container">
          { states.map(x => <License key={x.state} number={x.license} initials={x.initials} state={x.state} />) }
        </div>
      </section>
    </Fragment>
  )
}

const License = ({initials, state, number}) => (
  <div className="one-state-row tbd">
    <div className="one-state-row_initials">{initials}</div>
    <div className="one-state-row_name">{state} <span className="text-green">is covered by Trym</span></div>
    <div className="one-state-row_licence">LIC : {number}</div>
  </div>
)
