import React, { useEffect } from 'react'
import { Router, Redirect, Route, Switch, withRouter } from 'react-router-dom'
import {loadStripe} from '@stripe/stripe-js';
import { Elements} from '@stripe/react-stripe-js';
import { UserProvider } from './context'
import { useLocalSchemaVersion } from './utils'
import config from './config'

// pages
import Home from './Home'
import Payment from './Payment'
import Paid from './Paid'
import HowClaimsWork from './HowClaimsWork'
import Licenses from './Licenses'
import StatesWereIn from './StatesWereIn'
import Privacy from './Privacy'
import Story from './Story'
import SwitchToTrym from './SwitchToTrym'
import Agents from './Agents'
import Pending from './Pending'
import Login from './Login'
import ForgotPassword from './ForgotPassword';
import TermsOfService from './TermsOfService'
import history from './history'
import WhoWeInsure from './WhoWeInsure'
import Certificate from './Certificate'
import TailorQuestions from './pages/quoteFlow/TailorQuestions'
import TrymQuestions from './pages/quoteFlow/TrymQuestions'
import Quotes from './pages/quoteFlow/Quotes';
import Coverage from './pages/dashboard/Coverage'
import Policy from './pages/dashboard/Policy'
import BillingHistory from './pages/dashboard/BillingHistory'
import CoverageInfo from './pages/dashboard/Coverage'
import GenerateCert from './pages/dashboard/GenerateCert';
import BusinessInformation from './pages/dashboard/BusinessInfo';
import Claims from './pages/dashboard/Claims';


import QuoteDenial from './pages/QuoteDenial';

import PrivateRoute from './PrivateRoute';
import { useAuth } from './auth';


const stripePromise = loadStripe(config.stripe.pk)

// TODO: we need to update the routes that useAuth to use context instead
const withAuth = Component => props => {
  const auth = useAuth()
  return <Component {...props} auth={auth} />
}

const _ScrollToTop = ({children, location}) => {
  useEffect(() => window.scrollTo(0, 0), [location.pathname])

  return children
}
const ScrollToTop = withRouter(_ScrollToTop)

const App = () => {
  useLocalSchemaVersion('1.0')
  return (
    <Router history={history}>
      <UserProvider history={history}>
        <ScrollToTop>
          <Switch>
            <Route exact path="/([a-zA-Z]+\.[a-zA-Z0-9]+)?" component={Home} />
            <Route exact path="/login" component={Login} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/agents" component={Agents} />
            <Route exact path="/whats-covered" component={Coverage} />
            <Route exact path="/certificate-of-insurance" component={Certificate} />
            <Route exact path="/how-claims-work" component={HowClaimsWork} />
            <Route exact path="/licenses" component={Licenses} />
            <Route exact path="/states" component={StatesWereIn} />
            <Route exact path="/who-we-insure" component={WhoWeInsure} />
            <Route exact path="/privacy-policy" component={Privacy} />
            <Route exact path="/our-story" component={Story} />
            <Route exact path="/switch-to-trym" component={SwitchToTrym} />
            <Route exact path="/terms-of-service" component={TermsOfService} />
            
            <Route exact path="/quotes" component={Quotes} />      
            <Route exact path="/trym" component={TrymQuestions} />
            <Route exact path="/tailor" component={TailorQuestions} />
            <Route exact path="/pending" component={Pending} />
            <Route exact path="/paid" component={withAuth(Paid)} />
            <Route exact path="/denial" component={QuoteDenial} />
            <Route exact path="/quotes/:quoteName/select" component={withAuth(Payment)} />
            <PrivateRoute exact path="/billing-history" component={BillingHistory} />
            <PrivateRoute exact path="/coverage/:policyIndex?" component={CoverageInfo} />
            <PrivateRoute exact path="/dashboard/:policyIndex?" component={Policy} />
            <PrivateRoute exact path="/payment" component={withAuth(Payment)} />
            <PrivateRoute exact path="/generate-certificate" component={GenerateCert} />
            <PrivateRoute exact path="/claims/:policyIndex?" component={Claims} />
            {/* I think this has to be last or the pages under it don't render */}
            <Elements stripe={stripePromise}>
              <PrivateRoute exact path="/business-information" component={BusinessInformation} />
            </Elements>
            <Redirect to="/" />
          </Switch>
        </ScrollToTop>
      </UserProvider>
    </Router>
  )
}

export default App
