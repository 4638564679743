import React from 'react'
import {TitledInfoPage} from './InfoPage'
import states from './config/licenses'

const pairwise = arr => arr.reduce((acc, elem, index) => {
  index % 2 ? acc[(index-1)/2][1] = elem : acc.push([elem])
  return acc
}, [])
const statePairs = pairwise(states)

export default () => (
  <TitledInfoPage className="licenses" title="Terms of service">
    <p className="text-center tbd">Last Revised: February 8, 2019</p>
    <div className="divider"></div>
    
    { statePairs.map(p => (
      <div className="row tbd" key={p[0].initials}>
        <License number={p[0].license} state={p[0].state} />
        {p.length > 1 && <License number={p[1].license} state={p[1].state} />}
      </div>
    )) }
  </TitledInfoPage>
)

const License = ({state, number}) => (
  <div className="col-1-2 text-center large-margin-bottom">
    <h3 className="no-margin">{state}</h3>
    <p className="no-margin">License: {number}</p>
  </div>  
)
