module.exports = [
  {value: "Alabama", key: "AL"},
  {value: "Alaska", key: "AK"},
  {value: "Arizona", key: "AZ"},
  {value: "Arkansas", key: "AR"},
  {value: "California", key: "CA"},
  {value: "Colorado", key: "CO"},
  {value: "Connecticut", key: "CT"},
  {value: "Delaware", key: "DE"},
  {value: "District of Columbia", key: "DC"},
  {value: "Florida", key: "FL"},
  {value: "Georgia", key: "GA"},
  {value: "Hawaii", key: "HI"},
  {value: "Idaho", key: "ID"},
  {value: "Illinois", key: "IL"},
  {value: "Indiana", key: "IN"},
  {value: "Iowa", key: "IA"},
  {value: "Kansas", key: "KS"},
  {value: "Kentucky", key: "KY"},
  {value: "Louisiana", key: "LA"},
  {value: "Maine", key: "ME"},
  {value: "Maryland", key: "MD"},
  {value: "Massachusetts", key: "MA"},
  {value: "Michigan", key: "MI"},
  {value: "Minnesota", key: "MN"},
  {value: "Mississippi", key: "MS"},
  {value: "Missouri", key: "MO"},
  {value: "Montana", key: "MT"},
  {value: "Nebraska", key: "NE"},
  {value: "Nevada", key: "NV"},
  {value: "New Hampshire", key: "NH"},
  {value: "New Jersey", key: "NJ"},
  {value: "New Mexico", key: "NM"},
  {value: "New York", key: "NY"},
  {value: "North Carolina", key: "NC"},
  {value: "North Dakota", key: "ND"},
  {value: "Ohio", key: "OH"},
  {value: "Oklahoma", key: "OK"},
  {value: "Oregon", key: "OR"},
  {value: "Pennsylvania", key: "PA"},
  {value: "Rhode Island", key: "RI"},
  {value: "South Carolina", key: "SC"},
  {value: "South Dakota", key: "SD"},
  {value: "Tennessee", key: "TN"},
  {value: "Texas", key: "TX"},
  {value: "Utah", key: "UT"},
  {value: "Vermont", key: "VT"},
  {value: "Virginia", key: "VA"},
  {value: "Washington", key: "WA"},
  {value: "West Virginia", key: "WV"},
  {value: "Wisconsin", key: "WI"},
  {value: "Wyoming", key: "WY"}
]