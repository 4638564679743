import React, {useRef, useState} from 'react'
import whiteCheck from './images/white-checkmark.svg'
import topTestimonialPhoto from './images/mark-jen-online-store.png'
import trymBadgeLight from './images/trym-badge-light.svg'
import trymBadgeDark from './images/trym-badge-dark.svg'
import starShield from './images/star-shield.svg'
import coverageGeneral from './images/coverage-general.svg'
import coverageLegal from './images/coverage-legal.svg'
import coverageBuilding from './images/coverage-building.svg'
import coverageIncome from './images/coverage-income.svg'
import coverageEpli from './images/coverage-epli.svg'
import coverageProfessional from './images/coverage-professional.svg'
import coverageCyber from './images/coverage-cyber.svg'
import coverageAll from './images/coverage-all.svg'
import founderBox from './images/founder-box.png'
import whiteX from './images/white-x-dark-circle.svg'
import biz1 from './images/biz-1.png'
import biz2 from './images/biz-2.png'
import biz3 from './images/biz-3.png'
import biz4 from './images/biz-4.png'
import biz5 from './images/biz-5.png'
import biz6 from './images/biz-6.png'
import quoteBadge from './images/quote-badge.svg'
import dakota from './images/dakota.png'
import cf from './images/cf.svg'
import plus from './images/plus.svg'
import styled from 'styled-components'
import { PrimaryButtonLink } from './PrimaryButton'
import { gray, darkGray, lightGreen, shadow, mobileMedia } from './styles'
import HomeNav, { Top, FinalStartQuote } from './HomeNav'
import Footer from './Footer'
import { useReferral } from './referrals'
import {getQuoteProgress} from './utils'
import history from './history'

const Home = () => {
  useReferral(true)
  const home = useRef(null)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const quoteProgress = getQuoteProgress()
  const openInfo = () => setIsInfoModalOpen(true)
  const resumeQuote = () => {
    if (quoteProgress == null) return
    history.push(quoteProgress.url, quoteProgress.state)
  }

  return (
    <Root ref={home} className="q-flow">
      <Top>
        <HomeNav rootRef={home} isInfoModalOpen={isInfoModalOpen} setIsInfoModalOpen={setIsInfoModalOpen} />
        <TopMain>
          <TopInfo>
            <H1>Insure your small business in a few clicks</H1>
            <PrimaryButtonLink onClick={openInfo}>Start your quote</PrimaryButtonLink>
            {quoteProgress != null && (
              <GrayLink className="wide" onClick={resumeQuote}>Continue your quote</GrayLink>
            )}
            <Bullets>
              <Bullet><GreenCircleCheck />Robust coverage</Bullet>
              <Bullet><GreenCircleCheck />Made for small businesses</Bullet>
              <Bullet><GreenCircleCheck />Takes 5 minutes to buy</Bullet>
            </Bullets>
          </TopInfo>
          <TopTestimonial>
            <Relative>
              <TopTestimonialImage src={topTestimonialPhoto} />
              {/* <TopTestimonialBox>
                <TrymBadgeContainer>
                  <TrymBadge src={trymBadgeLight} />
                  <TopRightCheck />
                </TrymBadgeContainer>
                <TestimonialText>
                  <span>Mark &amp; Jen&apos;s online store </span>
                  <TestimonialCovered>is covered by Trym </TestimonialCovered>
                  <TestimonialBusinessDesc>Freelance Frame Maker in Phoenix, AZ</TestimonialBusinessDesc>
                </TestimonialText>
              </TopTestimonialBox> */}
            </Relative>
          </TopTestimonial>
        </TopMain>
      </Top>
      <CoverageContainer>
        <Coverage>
          <StarShield src={starShield} />
          <H2>Robust coverage</H2>
          <CoverageOverview>Customers slip and fall. Clients sue for &quot;bad advice.&quot; Hackers...hack. With Trym, say bye to these worries and hello to peace of mind.</CoverageOverview>
          <CoverageCards>
            <CoverageCard className="dog-ear">
              <CoverageCardTop>
                <CoverageIconContainer><CoverageIcon src={coverageGeneral} /></CoverageIconContainer>
                <CoverageTitle>General liability</CoverageTitle>
              </CoverageCardTop>
              <CoverageDesc>Covers third-party bodily injury and property damage, as well as libel and slander. Every business needs this to pay for settlements.</CoverageDesc>
            </CoverageCard>
            <CoverageCard>
              <CoverageCardTop>
                <CoverageIconContainer><CoverageIcon src={coverageLegal} /></CoverageIconContainer>
                <CoverageTitle>Legal defense</CoverageTitle>
              </CoverageCardTop>
              <CoverageDesc>Pays your legal bills and attorney fees if your business is sued. Avoid $5,000 retainers and $250/hr lawyer fees.</CoverageDesc>
            </CoverageCard>
            <CoverageCard>
              <CoverageCardTop>
                <CoverageIconContainer><CoverageIcon src={coverageBuilding} /></CoverageIconContainer>
                <CoverageTitle>Building &amp; property insurance</CoverageTitle>
              </CoverageCardTop>
              <CoverageDesc>Covers replacement costs if your building, equipment, furniture, and inventory suffers damage or loss.</CoverageDesc>
            </CoverageCard>
            <CoverageCard>
              <CoverageCardTop>
                <CoverageIconContainer><CoverageIcon src={coverageIncome} /></CoverageIconContainer>
                <CoverageTitle>Business income protection</CoverageTitle>
              </CoverageCardTop>
              <CoverageDesc>Covers lost income if your business shuts down because of a disaster. You’ll still be able to pay your rent and make payroll.</CoverageDesc>
            </CoverageCard>
            <CoverageCard>
              <CoverageCardTop>
                <CoverageIconContainer><CoverageIcon src={coverageEpli} /></CoverageIconContainer>
                <CoverageTitle>Employment practices liability</CoverageTitle>
              </CoverageCardTop>
              <CoverageDesc>Covers harassment, wrongful termination, discrimination, defamation, and other claims made by employees against you.</CoverageDesc>
            </CoverageCard>
            <CoverageCard>
              <CoverageCardTop>
                <CoverageIconContainer><CoverageIcon src={coverageProfessional} /></CoverageIconContainer>
                <CoverageTitle>Professional liability <div className="chiclet green">COMING SOON</div></CoverageTitle>
              </CoverageCardTop>
              <CoverageDesc>Covers your errors, omissions, and mistakes from your work with clients. Essential if you make professional recommendations.</CoverageDesc>
            </CoverageCard>
            <CoverageCard>
              <CoverageCardTop>
                <CoverageIconContainer><CoverageIcon src={coverageCyber} /></CoverageIconContainer>
                <CoverageTitle>Cyber insurance <div className="chiclet green">COMING SOON</div></CoverageTitle>
              </CoverageCardTop>
              <CoverageDesc>Covers data breaches and computer system damage by hackers. Vital for small businesses without strong cyber security.</CoverageDesc>
            </CoverageCard>
            <CoverageCard className="green">
              <CoverageCardTop>
                <CoverageIconContainer><CoverageIcon src={coverageAll} /></CoverageIconContainer>
                <CoverageTitle>You are covered. No worries.</CoverageTitle>
              </CoverageCardTop>
              <CoverageDesc className="white">Once you tell us about your business, we’ll recommend the coverage you need and nothing you don’t. You already wear dozens of hats. &quot;Small Business Insurance Expert&quot; doesn’t have to be one of them.</CoverageDesc>
            </CoverageCard>
          </CoverageCards>
        </Coverage>
      </CoverageContainer>
      <ComparisonContainer>
        <FounderBox>
          <FounderTrymBadge src={trymBadgeDark} />
          <FounderText>After a decade serving small businesses with insurance, our founder crafted Trym's coverage exclusively for you.</FounderText>
        </FounderBox>
        <Comparison>
          <H2>Faster than buying through an agent</H2>
          <VersusContainer>
            <VersusWay className="align-right">
              <VersusWayTitle>The Trym way</VersusWayTitle>
              <VersusTime className="green">5 min</VersusTime>
              <VersusStep>You answer a few questions</VersusStep>
              <VersusStep>We show you options in seconds</VersusStep>
              <VersusStep>You pick a plan</VersusStep>
              <VersusStep className="green bold">Boom, you're covered <GreenCircleCheck className="right" /></VersusStep>
            </VersusWay>
            <VersusVS><VersusLine /><VersusVSText>vs</VersusVSText></VersusVS>
            <VersusWay>
              <VersusWayTitle className="thin">Typical insurance process</VersusWayTitle>
              <VersusTime>Up to 10 weeks</VersusTime>
              <VersusStep>Find an agent</VersusStep>
              <VersusStep>Call the agent</VersusStep>
              <VersusStep>Choose the agent</VersusStep>
              <VersusStep>Fill out a bunch of paperwork</VersusStep>
              <VersusStep>Wait for the agent to get back</VersusStep>
              <VersusStep>Review the quote</VersusStep>
              <VersusStep>Make changes</VersusStep>
              <VersusStep className="black bold"><X src={whiteX} /> Rinse and repeat</VersusStep>
            </VersusWay>
          </VersusContainer>
        </Comparison>
      </ComparisonContainer>
      <BusinessTypes>
        <Biz src={biz2} className="b" />
        <Biz src={biz3} className="c" />
        <Biz src={biz4} className="d" />
        <Biz src={biz5} className="e" />
        <Biz src={biz6} className="f" />
        <Biz src={biz1} className="a" />
        <NumTypes>Over 270 types of business insured</NumTypes>
        <BusinessTypeP>Small businesses are the backbone of the United States economy. That's who we insure.</BusinessTypeP>
        <BusinessTypeSamples>
          <div>Barber shops</div>
          <div>Locksmiths</div>
          <div>Accountants</div>
        </BusinessTypeSamples>
        <BusinessTypeP className="wider">If you are wondering whether Trym insures your business type, the answer is probably <I>yes</I>.</BusinessTypeP>
        <BusinessTypesCTA>
          <PrimaryButtonLink onClick={openInfo}>Start your quote</PrimaryButtonLink>
          {quoteProgress != null && (
            <GrayLink onClick={resumeQuote}>Continue your quote</GrayLink>
          )}
        </BusinessTypesCTA>
        {/* <BottomTestimonial>
          <BottomTestimonialMain>
            <TrymBadge src={quoteBadge} className="big" />
            <BottomTestimonialText>Trym made it easy for me to save big while protecting what I've worked so hard to start. I even understand my coverage!</BottomTestimonialText>
            <BottomTestimonialName>Dakota Johnson, CPA</BottomTestimonialName>
            <BottomTestimonialBusiness>Freelance Accountant in Phoenix, AZ</BottomTestimonialBusiness>
          </BottomTestimonialMain>
          <BottomTestimonialImage src={dakota} />
        </BottomTestimonial> */}
      </BusinessTypes>
      <Partnership>
        <PartnershipIcons>
          <TrymBadge className="big" src={cf} />
          <Plus src={plus} />
          <TrymBadge className="big" src={trymBadgeDark} />
        </PartnershipIcons>
        <PartnershipDescContainer>
          <PartnershipDesc>Trym's policies are backed by Crum &amp; Forster, a 195-year-old insurance company rated A Excellent by A.M. Best with $10+ billion in revenues and assets. C&amp;F is a member of Fairfax Financial Holding Limited, a $42B global firm engaged in insurance, reinsurance, and investment management. In partnership with C&amp;F, Trym is here for the long haul.</PartnershipDesc>
        </PartnershipDescContainer>
        <FinalStartQuote onStart={openInfo} quoteProgress={quoteProgress} resumeQuote={resumeQuote} />
      </Partnership>
      <Footer />
    </Root>
  )
}

const Root = styled.div`
  background-color: white;
`

const GrayLink = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: ${gray};
  text-decoration: none;
  font-size: 0.8rem;
  margin: 1rem;
  &.wide { padding: 0 2.5rem; }
`

const TopMain = styled.div`
  display: flex;
  padding: 0 1rem 4rem 1rem;
  ${mobileMedia} {
    flex-direction: column;
    padding: 0;
  }
`
const TopInfo = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  ${mobileMedia} {
    padding: 0 1em;
    align-items: center;
  }
`
const H1 = styled.h1`
  font-size: 3rem;
  font-weight: 900;
  margin: 4rem 4rem 3rem 0;
  ${mobileMedia} {
    font-size: 2rem;
    margin: 2rem 0;
    text-align: center;
  }
`
const Relative = styled.div`position: relative;`
const TopTestimonial = styled.div`
  flex: 1;
  position: relative;
`
const TopTestimonialImage = styled.img`
  width: 541px;
  margin-left: 41px;
  margin-bottom: 44px;
  ${mobileMedia} {
    margin: 0;
    width: 100vw;
    height: unset;
  }
`
const TopTestimonialBox = styled.div`
  min-width: 63%;
  max-width: 412px;
  box-sizing: border-box;
  display: flex;
  background-color: white;
  padding: 1.25rem;
  position: absolute;
  bottom: 0;
  left: 0;
  ${shadow};

  ${mobileMedia} {
    width: 90%;
    max-width: 90%;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    position: absolute;
    bottom: -5rem;
    left: 5%;
  }
`

const TrymBadgeContainer = styled.div`
  display: inline;
  position: relative;
`
const TrymBadge = styled.img`
  height: 2.1rem;
  &.big { height: 3.15rem; }
`
const TopRightCheck = () => (
  <TopRight>
    <SmallDarkGreenCircleCheck />
  </TopRight>
)
const TopRight = styled.div`
  position: absolute;
  top: -0.25rem;
  right: -0.3rem;
`
const SmallDarkGreenCircleCheck = () => (
  <SmallDarkGreenCircle>
    <SmallWhiteCheck src={whiteCheck} />
  </SmallDarkGreenCircle>
)
const SmallDarkGreenCircle = styled.div`
  background-color: ${lightGreen};
  height: 0.76rem;
  width: 0.76rem;
  border-radius: 0.38rem;
  display: flex;
  justify-content: center;
  align-items: center;
`
const SmallWhiteCheck = styled.img`
  width: 0.38rem;
  height: 0.38rem;
`

const TestimonialText = styled.div`
  flex: 1;
  padding-left: 1rem;
  font-size: 0.80rem;
  line-height: 1.1rem;

  ${mobileMedia} {
    margin-top: 0.75rem;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`
const TestimonialCovered = styled.span`
  color: ${lightGreen};
  font-weight: 500;
`
const TestimonialBusinessDesc = styled.span`
  color: ${gray};
`

const Bullets = styled.ul`
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  ${mobileMedia} {margin-inline-start: 1rem;}
`
const Bullet = styled.li`
  display: flex;
  align-items: center;
  margin: 0.6rem 0;
`
const GreenCircleCheck = ({className}) => (
  <GreenCircle className={className}>
    <WhiteCheck src={whiteCheck} />
  </GreenCircle>
)
const GreenCircle = styled.div`
  background-color: #d0e59b;
  height: 2rem;
  width: 2rem;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;

  &.right {
    margin-right: 0;
    margin-left: 1rem;
  }
`
const WhiteCheck = styled.img`
  width: 1rem;
  height: 1rem;
`

const CoverageContainer = styled.div`
  background-color: #f3f3f3;
`
const Coverage = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  padding-top: 3rem;
  padding-bottom: 7rem;
  max-width: 960px;

  ${mobileMedia} {
    padding-top 8rem;
    padding-bottom: 0;
    width: unset;
  }
`
const StarShield = styled.img`
  height: 3rem;
`
const H2 = styled.h2`
  font-size: 2.33rem;
  font-weight: 900;
  color: #222;
  margin: 0.5rem;
  width: 60%;
  text-align: center;

  ${mobileMedia} {
    width: 70%;
    font-size: 1.5rem;
  }
`
const CoverageOverview = styled.div`
  max-width: 430px;
  text-align: center;
  color: ${darkGray};
  font-size: 0.9rem;
  line-height: 1.2rem;
  margin: 0 1rem 3rem 1rem;
  ${mobileMedia} {
    max-width: unset;
  }
`

const CoverageCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  ${mobileMedia} {
    flex-wrap: nowrap;
    flex-direction: column;
    margin: unset;
    width: unset;
  }
`
const CoverageCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  width: 470px;
  margin: 10px;
  padding: 1.6rem;
  box-sizing: border-box;
  ${shadow};

  &.green {
    background-color: ${lightGreen};
    color: white;
    ${mobileMedia} {
      margin: 1rem 0 0 0;
      padding: 3rem 2rem 6rem 2rem;
    }
  }

  ${mobileMedia} {
    width: unset;
    margin: 0.5rem 0.8rem;
    padding: 1.3rem;
  }
`
const CoverageCardTop = styled.div`
  display: flex;
  align-items: center;
`
const CoverageIconContainer = styled.div`
  width: 3rem;
`
const CoverageIcon = styled.img`
  max-width: 1.8rem;
  max-height: 1.8rem;
`
const CoverageTitle = styled.h3`
  font-size: 1.0rem;
  font-weight: 500;
  margin: 0;
`
const CoverageDesc = styled.p`
  color: ${darkGray}
  font-size: 0.8rem;
  line-height: 1.3rem;
  margin: 0.5rem 0 0 3rem;

  &.white {color: white;}

  ${mobileMedia} {
    margin-left: 0;
  }
`

const FounderBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 3rem 2rem;
  align-items: center;
  background-image: url(${founderBox});
  background-position: center;
  position: absolute;
  top: -4rem;
  ${shadow};
  margin: 0 auto;
  left: 0;
  right: 0;
  width: 960px;
  box-sizing: border-box;

  ${mobileMedia} {
    margin: 0 auto;
    padding: 2rem 1.8rem 3rem 1.8rem;
    width: 90%;
    background-image: unset;
    background-color: white;
  }
`
const FounderTrymBadge = styled.img`
  height: 2rem;
  margin-bottom: 1.2rem;
`
const FounderText = styled.div`
  color: ${darkGray};
  width: 45%;
  text-align: center;
  line-height: 1.4rem;

  ${mobileMedia} {
    width: unset;
    font-size: 0.8rem;
  }
`

const ComparisonContainer = styled.div`
  padding-top: 11rem;
  position: relative;
`
const Comparison = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 700px;
  margin: 0 auto;

  ${mobileMedia} {
    width: unset;
    margin: unset;
  }
`
const VersusContainer = styled.div`
  display: flex;
  padding-top: 1.5rem;

  ${mobileMedia} {
    flex-direction: column;
  }
`
const VersusWay = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-bottom: 4.5rem;

  &.align-right { align-items: flex-end; }

  ${mobileMedia} {
    align-items: center;
    &.align-right { align-items: center; }
    padding-bottom: 3.5rem;
    &:not(:last-child) { padding-bottom: 0; }
  }
`
const VersusWayTitle = styled.h3`
  margin: 2.4rem 0;
  &.thin { font-weight: 500; }

  ${mobileMedia} {
    margin-top: 2rem;
    margin-bottom: 0.6rem;
    font-size: 1rem;
  }
`
const VersusTime = styled.div`
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 1rem;

  &.green {
    color: ${lightGreen};
  }
`
const VersusStep = styled.div`
  color: ${darkGray};
  line-height: 3rem;
  display: flex;
  align-items: center;

  &.black { color: black; }
  &.green { color: ${lightGreen}; }

  &.bold {
    font-weight: 500;
  }

  ${mobileMedia} {
    align-self: flex-start;
    line-height: 2.5rem;
  }
`
const VersusVS = styled.div`
  padding: 0 2.5rem;
  position: relative;
  z-index: 1;

  ${mobileMedia} {
    padding: 0;
    margin-top: 1rem;
  }
`
const VersusLine = styled.div`
  width: 1px;
  height: 108%;
  margin: 0 auto;
  background-color: #d8d8d8;

  ${mobileMedia} {
    width: 100%;
    height: 1px;
    margin: 1rem 0;
  }
`
const VersusVSText = styled.span`
  position: absolute;
  text-align: center;
  color: ${gray};
  font-weight: 500;
  font-size: 1.4rem;
  top: 1.9rem;
  left: 0;
  right: 0;
  background-color: white;
  padding: 0.4rem 0;

  ${mobileMedia} {
    top: 0;
    padding: 0 0.5rem;
    width: fit-content;
    line-height: 2rem;
    margin: 0 auto;
  }
`
const X = styled.img`
  height: 2rem;
  margin-right: 1rem;
`

const BusinessTypes = styled.div`
  background-color: #222;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 1rem;
  position: relative;

  > * { z-index: 1; }
`
const NumTypes = styled.h2`
  font-size: 2.33rem;
  font-weight: 500;
  color: #fff;
  width: 350px;
  text-align: center;
  margin: 6rem 0 1rem 0;

  ${mobileMedia} {
    font-size: 1.5rem;
    margin-top: 2rem;
    width: 80%;
  }
`
const BusinessTypeP = styled.p`
  width: 330px;
  text-align: center;
  font-size: 0.9rem;
  line-height: 1.4rem;

  &.wider {
    width: 460px;
    ${mobileMedia} { width: unset; }
  }

  ${mobileMedia} {
    width: unset;
  }
`
const BusinessTypeSamples = styled.div`
  margin: 1rem 0;
  font-weight: 900;
  text-align: center;
  position: relative;
  width: 100%;
  height: 8rem;

  ${mobileMedia} { height: 5rem; }

  > * {
    position: absolute;
    width: 100%;
  }

  > :nth-child(3) {
    font-size: 5rem;
    ${mobileMedia} { font-size: 2.6rem; }
  }
  > :nth-child(2) {
    font-size: 4.5rem;
    color: #444;
    top: 2.4rem;
    ${mobileMedia} {
      font-size: 2.5rem;
      top: 1.4rem;
    }
  }
  > :first-child {
    font-size: 3rem;
    color: #333;
    top: 5rem;

    ${mobileMedia} {
      font-size: 1.9rem;
      top: 2.7rem;
    }
  }
`
const BusinessTypesCTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 4rem 0 8rem 0;

  ${mobileMedia} {
    margin: 3rem 0 8rem 0;
  }
`
const Biz = styled.img`
  position: absolute;
  z-index: 0;
  height: 30%;
  ${mobileMedia} {display: none;}

  &.a { top: 0;   left: 8%;  }
  &.b { top: 0;   right: 7%; }
  &.c { top: 23%; left: 0%;  }
  &.d { top: 35%; right: 0;  }
  &.e { bottom: 5%; left: 5%; }
  &.f { bottom: 0; right: 5%; }
`
const I = styled.span`font-style: italic;`

const BottomTestimonial = styled.div`
  position: absolute;
  width: 960px;
  margin: 0 auto;
  left: 0;
  right: 0;
  bottom: -15rem;
  background-color: white;
  ${shadow};
  display: flex;

  ${mobileMedia} {
    width: 90%;
    bottom: -10rem;
  }
`
const BottomTestimonialMain = styled.div`
  padding: 2rem;
  position: relative;

  ${mobileMedia} {
    align-items: center;
    padding-top: 4rem;

    > img {
      position: absolute;
      margin: auto;
      left: 0;
      right: 0;
      top: -0.7rem;
    }
  }
`
const BottomTestimonialText = styled.div`
  color: ${darkGray};
  margin: 1em 0 2em 0;
  font-size: 1.2rem;
  line-height: 1.8rem;
  ${mobileMedia} {
    margin-top: 0;
    text-align: center;
    font-size: 1rem;
    line-height: 1.25rem;
  }

  @media (max-width: 350px) {
    font-size: 0.8rem;
    line-height: 1.1rem; 
  }
`
const BottomTestimonialName = styled.div`
  color: black;
  font-weight: 500;
  margin-bottom: 0.3rem;
  font-size: 1.1rem;
  ${mobileMedia} { text-align: center; }
`
const BottomTestimonialBusiness = styled.div`
  color: ${gray};
  font-size: 1rem;
  ${mobileMedia} { text-align: center; }
`
const BottomTestimonialImage = styled.img`
  width: 478px;
  height: 400px;
  ${mobileMedia} { display: none; }
`

const Partnership = styled.div`
  padding: 20rem 0 13rem 0;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  text-align: center;

  ${mobileMedia} {
    padding: 13rem 0 0 0;
  }
`
const PartnershipIcons = styled.div`
  display: flex;
  align-items: center;
`
const Plus = styled.img`
  height: 1rem;
  margin: 0 1.5rem;
`

const PartnershipDescContainer = styled.div`
  border-bottom: 1px solid #d8d8d8;
  width: 800px;
  ${mobileMedia} {
    width: unset;
  }
`
const PartnershipDesc = styled.p`
  color: ${darkGray};
  font-size: 0.8rem;
  line-height: 1.3rem;
  width: 520px;
  margin: 0 auto;
  padding: 2rem 0 3.5rem 0;

  ${mobileMedia} {
    text-align: left;
    width: unset;
    margin: 0 1rem;
  }
`

export default Home
