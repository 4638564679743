import React, { useState, useEffect, useContext } from "react";
import { NavLink, Link } from "react-router-dom";
import api from "../api/index";
import { UserContext } from "../context";
import trymLogoWhite from "../img/trym-logo-white.svg";

export default ({location}) => {
  const [policies, setPolicies] = useState([]);
  const userContext = useContext(UserContext);
  const currentUser = userContext && userContext.user;

  useEffect(() => {
    if (!currentUser) return;
    async function getData() {
      const policies = await api.get(userContext.token, `/api/policies`);
      setPolicies(policies);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  if (!userContext || !policies || !userContext.user) {
    return null;
  }

  return(
    <header>
        <Link to="/">
          <div className="branding">
            <img src={trymLogoWhite} className="header-logo" alt="Trym" />
          </div>  
        </Link>
        <div className="navigation">
          <div className="user-info">
            <span className="user-info_name">{currentUser.displayName}</span>
            {policies.map((p, i) => (
              <span className="user-info_company" key={p.quote.quoteId}>
                |
                <NavLink to={`/dashboard/${i}`} activeClassName="selected">
                  {p.quote.insuredName}
                </NavLink>
              </span>
            ))}
          </div>
          <button
            type="button"
            onClick={() => userContext.signOut()}
            className="button secondary white nowrap"
          >
            Sign out
          </button>
        </div>
      </header>
  )
}