import React from 'react'
import styled, { keyframes } from 'styled-components'
import loadingImage from './images/loader-trym-200.gif'
import logo from './images/trym-logo-black.svg'

const Loading = ({text}) => {
  return (
    <Container className="q-flow">
      <Header>
        <Logo src={logo} alt="Trym" />
      </Header>
      <Spinner src={loadingImage} alt="Retrieving quote..." />
      { text != null && <Text>{text}</Text> }
    </Container>
  )
}

const Container = styled.div `
  display: flex;
`

const Header = styled.div `
  display: flex;
  flex: 1;
  height: 5rem;
  max-height: 20vh;

  justify-content: center;
  align-items: center;
`

const Logo = styled.img `
  display: block;
  max-width: 61.8%;
`

const Spinner = styled.img `
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -100px;
  margin-left: -100px;
  height: 200px;
  width: 200px;
`

const fadeIn = keyframes `
  0%   { opacity: 0; }
  50%  { opacity: 0; }
  100% { opacity: 0.6; }
`

const Text = styled.div `
  position: absolute;
  top: 50%;
  width: 100%;
  opacity: 0.6;
  text-align: center;
  margin: 100px auto;
  animation: ${fadeIn} 6s;
`

export default Loading