import React from 'react'
import InfoPage from './InfoPage'
import scott from './img/scott-bradley-small.png'

export default () => (
  <InfoPage className="story-page">
    <section className="page-header">
      <div className="small-container">
        <div className="chapeau">Our story</div>
        <h1 className="medium-margin-top">Why we decided to Trym small business insurance</h1>
      </div>
    </section>

    <section className="content">
      <div className="regular-container">
        <p className="text-medium">Hi there! I’m Scott, Founder of Trym. If you’re interested, I’d love to share with you the heart behind why we decided to give small business insurance a clean, fresh look.</p>

        <p>During my ten years in sales and executive leadership for two of the world’s largest global insurance brokers, I was repeatedly irritated and frustrated with one small but specific aspect of the business. The way my friends and family were treated (even by my own company) when they asked me to help them buy insurance for their business.</p>

        <p>See, I’ve been blessed with a vast and rich community of people in my life, but not a single one of them own a billion-dollar business. You know what’s crazier? Most of them don’t even want to! But I do have many friends who I greatly admire who’ve taken the immeasurable personal risk to launch out and start a business of their own. They are the salt-of-the-earth kind of people.</p>

        <p>Believe it or not, my community is an actual reflection of the backbone of American entrepreneurship. Over 98% of small businesses in the United States have less than 10 employees. We believe starting something small isn’t just the future of entrepreneurship, it’s the lifeblood.</p>

        <p>Here’s what was silly to me. The insurance industry defines “small business” as companies with 1 to 99 employees. I don’t think I need to go into the details on how an organization with nearly 100 employees is a lot different than a solopreneur or small business like yours. Building a side-hustle from home or pursuing a passion out of a small office doesn’t carry the same level of risk as these larger companies do. So, the type of insurance you need and how you buy it should be different too.</p>

        <p>But the industry is run by multi-billion-dollar corporate giants whose average age is 100 years old (think: pre-everything modern, even post-it notes!), and thrives on complexity (think: confusion), commissions (think: layers of agents and middle-men who earn more than your business) and ego (think: arrogance and greed). </p>

        <p>As a result, true small businesses are forced to go through the same outdated underwriting process (lasting up to several weeks), manually fill out the same over-sized applications with questions that don’t even apply to your business (some as many as 30 pages long), and buy between two and seven of the same over-complicated insurance policies designed and built for the 2% minority of medium to larger businesses. </p>

        <p>While frustrating for sure, the complexity and commissions are not even the part that used to piss me off the most. It was the arrogance and disrespect my friends were treated with. Because the size of the premiums are naturally smaller, small businesses have to filter through less-than-best advice by underqualified and uninterested agents. I was actually trained how to kindly tell a customer we can’t help them because they are “too small.” </p>

        <blockquote>One day it just hit me: small business insurance isn’t broken, <span>it doesn’t even exist.</span></blockquote>

        <p>Hear my heart though, I’m not bashing the people in the industry. An industry as old, rich, entrenched, large, and regulated as the insurance industry makes slaves out of everyone. The undefeatable monarch in power that keeps everyone in line is the endless, aimless and passionless pursuit of a dollar. It’s a systemic problem, not a people problem. </p>

        <p>So in 2015, a group of us set out to build the first insurance experience designed exclusively for actual small businesses. Instead of an underwriting process that takes weeks of back and forth with an agent, you can buy the insurance you need online in just 5 minutes. Get a quote, choose your plan, and you’re covered. It’s that simple. You can even get your proof of insurance instantly too.</p>

        <p>Where’d we come up with the name Trym? Well, it best fit what we set out to do. There was no one-big-quick-fix. It was more like getting rid of the excess and waste in a thousand small successive cuts. Each one reducing, diminishing, stripping away and peeling back different parts of the process, paperwork, costs, middle-men, policies, and underwriting time until what was left was a clean, convenient, and custom-fit experience and product for just for you. </p>

        <p>We’re not out to disrupt an industry. We just don’t want buying business insurance to disrupt your day or your budget like it used to. We hope your experience with us feels like that fresh-cut feeling you have when you walk out of your barbershop and get on with the rest of your day. </p>

        <p className="text-medium"><span className="text-green">We’re Trym.</span> We love first. Our dress code is humility. We speak life. We celebrate, serve, and sweat the small stuff.</p> 

        <div className="author">
          <div>
            <img src={scott} height="80px" alt="" />
          </div>
          <div className="info">
            <h3>Scott Bradley</h3>
            <a href="tel:6029317609">602-931-7609</a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="mailto:scott@trym.co">Scott@trym.co</a>
          </div>
        </div>

        <p style={{fontStyle: 'italic'}}>P.S. We really mean that last part. No one is insignificant to us. As founder, I’ve put my personal cell phone here and would love to help you if I can!</p>
        
      </div>
    </section>
  </InfoPage>
)
