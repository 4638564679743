import React, {useRef, useState} from 'react'
import styled from 'styled-components'
import HomeNav, {Top} from './HomeNav'
import Footer from './Footer'

const InfoPage = ({className, children, render}) => {
  const home = useRef(null)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)
  const open = () => setIsInfoModalOpen(true)

  return (
    <Root ref={home} className={className}>
      <Top>
        <HomeNav rootRef={home} isInfoModalOpen={isInfoModalOpen} setIsInfoModalOpen={setIsInfoModalOpen} />
      </Top>
      {(render && render(open)) || children}
      <Footer />
    </Root>
  )
}

export default InfoPage

export const TitledInfoPage = ({className, title, children}) => (
  <InfoPage className={className}>
    <section className="page-header">
      <div className="small-container">
        <h1>{title}</h1>
      </div>
    </section>

    <section className="content">
      <div className="regular-container">
        {children}
      </div>
    </section>
  </InfoPage>
)

const Root = styled.div`
  background-color: white;
`
