import React, { PureComponent } from 'react'
import styled from 'styled-components'
import logo from './images/trym-logo-black.svg'
import backArrow from './images/back-arrow.svg'
import { gray, lightGreen, mobileMedia } from './styles';
export default class Header extends PureComponent {
  render() {
    // const profile = this.props.auth.profile()
    // const isAdmin = !!profile && profile.roles.includes('admin')
    return (
      <header className="header">
        <Back onClick={goBack}><BackArrow src={backArrow} /><BackText>Back</BackText></Back>
        <Logo src={logo} alt="trym logo" />
        <div>
          <HelpEmail href="mailto:help@trym.co">help@trym.co</HelpEmail>
          {/* <PrimaryButtonLink className="small tbd">Live chat</PrimaryButtonLink> */}
        </div>


        {/* {isAdmin && <img className="header__admin" src={lock} alt="admin" />} */}
        {/* {this.props.children} */}
        {/* <div className="header__right">
          <span className="header__phone">1-800-123-4567</span>
          <div className="header__chat button blue tbd">Live chat</div>
        </div> */}
        {/* <div className="header__hang">
          { profile
            ? [
                <div key="button" className="profile-button button">{profile.name}</div>,
                <div key="menu" className="profile-menu">
                  <button onClick={this.logout}>Logout</button>
                </div>
              ]
            : <div className="profile-button button" onClick={this.login}>Login</div>
          }
        </div> */}
      </header>
    )
  }

  // login = () => {
  //   this.props.auth.login()
  //   this.setState(({iter}) => ({iter: iter + 1}))
  // }
  // logout = () => {
  //   this.props.auth.logout()
  //   this.setState(({iter}) => ({iter: iter + 1}))
  // }
}

function goBack() { window.history.back(); }

const Back = styled.a`
  color: ${gray};
  cursor: pointer;
  display: flex;
`

const Logo = styled.img`
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
`

const BackArrow = styled.img`
  height: 1.25rem;
  margin-right: 1rem;
`
const BackText = styled.span`
  @media(max-width: 375px) {
    visibility: hidden;
  }
`

const HelpEmail = styled.a`
  ${mobileMedia} { display: none; }
  color: ${lightGreen};
  font-weight: 900;
  text-decoration: none;
  margin-right: 1rem;
`
export const PrimaryButtonLink = styled.a`
  ${mobileMedia} { display: none; }
  color: white;
  cursor: pointer;
  background-color: ${lightGreen};
  font-size: 1rem;
  font-weight: 900;
  padding: 1rem 3rem;
  border-radius: 1.5rem;

  &.small {
    padding: 0.5rem 1.5rem;
    border-radius: 1.08rem;  
  }
`
