import React from "react";
import checkmark from "../img/checkmark-white.svg";
import x from "../img/x-white.svg";


export default ({type, message}) => {
  return (
    <div className={  `alert-container ${type === 'success' ? 'success' : 'error'}` }>
      <span>{message}</span>
      <span>
        {type === 'success' && <img alt="success checkmark"src={checkmark} />}
        {type === 'error' && <img alt="error checkmark" src={x} />}
      </span>
    </div>
  );
}