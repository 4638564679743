import styled from 'styled-components'
import {charcoal, darkGray, gray, lightGray, lightGreen} from './styles'

export default styled.button`
  white-space: nowrap;
  border: none;
  color: white;
  cursor: pointer;
  background-color: ${lightGreen};
  font-size: 1.16rem;
  font-weight: 500;
  padding: 1rem 3rem;
  border-radius: 1.58rem;

  &:disabled {
    background-color: ${gray}
  }

  &.small {
    padding: 0.5rem 1.5rem;
    border-radius: 1.08rem;  
  }

  &.extra.small {
    font-size: 0.9rem;
    padding: 0.25rem 0.8rem;
  }

  &.secondary {
    background-color: ${darkGray};
  }

  &.outline {
    border: 1px solid ${lightGreen};
    color: ${lightGreen};
    background: transparent;
  }

  &.gray {
    background-color: ${lightGray};
    color: ${charcoal}
  }
`

export const PrimaryButtonLink = styled.a`
  color: white;
  cursor: pointer;
  background-color: ${lightGreen};
  font-size: 1.16rem;
  font-weight: 500;
  padding: 1rem 3rem;
  border-radius: 1.58rem;
  text-decoration: none;

  &.small {
    padding: 0.5rem 1.5rem;
    border-radius: 1.08rem;  
  }

  &.extra.small {
    font-size: 0.9rem;
    padding: 0.25rem 0.8rem;
  }
`
