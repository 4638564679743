import React from 'react'
import styled from 'styled-components'
import { mobileMedia } from '../styles'

export default ({onChange, value, disabled}) => {
  if (value === undefined) value = false
  const _onChange = e => onChange(e.target.checked)
  return (
    <Switch>
      <input type="checkbox" checked={value} disabled={disabled} onChange={_onChange} />
      <div className="drag round">
        <div className="toggle" />
      </div>
    </Switch>
  )
}

const height = 34;
const width = 90;
const padding = 6;

const Switch = styled.label`
  display: inline-block;
  height: ${height}px;
  position: relative;
  float: right;
  width: ${width}px;

  ${mobileMedia} {
    float: unset;
    margin: 1rem auto 0 auto;
    display: block
    align-self: center;
  }

  & input {
    display: none;
  }

  .drag {
    background-color: #f3f3f3;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: .4s;
    overflow: hidden;
  
    .toggle {
      background-color: #d8d8d8;
      bottom: ${padding}px;
      height: ${height - padding*2}px;
      line-height: ${height - padding*2}px;
      font-size: 12px;
      font-weight: 900;
      color: #666;
      left: ${padding}px;
      position: absolute;
      transition: .4s;
      width: ${height - padding*2}px;

      :before {
        content: "YES";
        color: #a1cc37;
        left: -${width - height}px;
        width: ${width - height - padding}px;
        text-align: center;
        position: absolute;
      }
      :after {
        content: "NO";
        left: ${height - padding}px;
        width: ${width - height - padding}px;
        text-align: center;
        position: absolute;
      }
    }

    &.round {
      border-radius: ${height}px;

      .toggle {
        border-radius: 50%
      }
    }
  }

  input:checked + .drag {
    background-color: #e7f2cd;

    .toggle {
      transform: translateX(${width - height}px);
      background-color: #a1cc37;
    }
  }
`
