import {useEffect, useState} from 'react'
import auth0 from 'auth0-js'
import config from '../config'
import history from '../history'
import firebase from 'firebase/app'

export {AuthCallback} from './AuthCallback'
export {useLogin} from './useLogin'

export function useAuth() {
  const [auth, setAuth] = useState(null)
  useEffect(() => {
    const firebaseAuth = firebase.auth()
    firebaseAuth.onAuthStateChanged(
      user => setAuth({
        user,
        signOut: () => firebaseAuth.signOut()
      }),
      error => console.error(error))
  }, [])
  return auth
}

export default class Auth {
  auth0 = new auth0.WebAuth(config.auth0)

  login = () => {
    localStorage.setItem('auth0_origLocation', history.location.pathname)
    localStorage.setItem('auth0_origState', JSON.stringify(history.location.state || null))
    this.auth0.authorize()
  }

  handleAuthentication = () => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult)
        const origLocation = localStorage.getItem('auth0_origLocation')
        const origState = JSON.parse(localStorage.getItem('auth0_origState') || 'null')
        history.replace(origLocation, origState)
        //history.replace('/')
      } else if (err) {
        history.replace('/')
        console.log(err)
      }
    })
  }

  setSession = authResult => {
    // Set the time that the Access Token will expire at
    let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime())
    localStorage.setItem('auth0_access_token', authResult.accessToken)
    localStorage.setItem('auth0_id_token', authResult.idToken)
    localStorage.setItem('auth0_expires_at', expiresAt)
    localStorage.setItem('auth0_profile', JSON.stringify(authResult.idTokenPayload || {}))
    // navigate to the home route
    history.replace('/')
  }

  logout = () => {
    // Clear Access Token and ID Token from local storage
    localStorage.removeItem('auth0_access_token')
    localStorage.removeItem('auth0_id_token')
    localStorage.removeItem('auth0_expires_at')
    localStorage.removeItem('auth0_profile')
    // navigate to the home route
    history.replace('/')
  }

  isAuthenticated = () => {
    // Check whether the current time is past the 
    // Access Token's expiry time
    const expiresAt = JSON.parse(localStorage.getItem('auth0_expires_at'))
    return new Date().getTime() < expiresAt
  }

  lastProfileJSON = null
  lastProfile = null
  profile = () => {
    // Returns the current user's profile, or null if not logged in.
    // The object will be the same instance on subsequent calls unless
    // the login state has changed.
    if (!this.isAuthenticated()) return null
    const profileJSON = localStorage.getItem('auth0_profile')
    if (this.lastProfileJSON === profileJSON) return this.lastProfile
    const profile = profileJSON ? simplifyCustomClaims(JSON.parse(profileJSON)) : null
    this.lastProfileJSON = profileJSON
    this.lastProfile = profile
    return profile
  }

  // downloadProfile = () => {
  //   this.auth0.client.userInfo(localStorage.getItem('auth0_access_token'), (err, profile) => {
  //     profile = simplifyCustomClaims(profile)
  //     console.log(profile)
  //   })
  // }
}

const namespace = 'https://trym.co/'
const simplifyCustomClaims = obj => {
  Object.keys(obj).forEach(key => {
    if (key.startsWith(namespace)) {
      obj[key.substring(namespace.length)] = obj[key]
      delete obj[key]
    }
  })
  return obj
}