import env from './index-env'

// const redirectUris = {
//   development: 'http://localhost:3000/auth/callback',
//   production: 'https://trym-co.herokuapp.com/auth/callback'
// }

export default {
  // auth0: {
  //   domain: 'trym.auth0.com',
  //   clientID: 'RY6cpuKQEc9dkZfnVMQ4210HCwCnhenM',
  //   redirectUri: redirectUris[NODE_ENV] || redirectUris.production,
  //   audience: 'https://trym.auth0.com/userinfo',
  //   responseType: 'token id_token',
  //   scope: 'openid profile'
  // },
  identity: env.identity,
  stripe: {
    pk: 'pk_test_fw0bYLEMGpds4l8c4dMmn2GV00HoSPDJuV'
  },
  sentry: {
    dsn: "https://bae5bb11797a4f0ea4ce47c1c3a4b27c@o462871.ingest.sentry.io/5467395"
  }
}
