module.exports = [
  {value: "Association, Labor Union, Religious Organization", key: "AssociationLaborUnionReligiousOrganization"},
  {value: "Common Ownership", key: "CommonOwnership"},
  {value: "Corporation", key: "Corporation"},
  {value: "Executor or Trustee", key: "ExecutororTrustee"},
  {value: "Governmental Entity/Public Property", key: "PublicProperty"},
  {value: "Individual/Sole Proprietor", key: "SoleProprietor"},
  {value: "Joint Employers", key: "JointEmployers"},
  {value: "Joint Venture", key: "JointVenture"},
  {value: "Limited Liability Partnership", key: "LimitedLiabilityPartnership"},
  {value: "Limited Partnership", key: "LimitedPartnership"},
  {value: "LLC", key: "LLC"},
  {value: "Multiple Status", key: "MultipleStatus"},
  {value: "Partnership", key: "Partnership"},
  {value: "Trust or Estate", key: "Trust"},
  {value: "Other", key: "Other"}
]
