import React, { useState } from 'react'
import FormError from '../components/FormError';

const Text = ({onChange, allowed, template, value, isValid, maxLength, placeholder, autoComplete, error}) => {
  value = value || ''
  const [oldValue, setOldValue] = useState(value)
  function _onChange(e) {
    const {value} = e.target
    if (template) {
      let stripped = Array.from(value).filter(c => c.match(allowed))

      let hydrated = ''
      for (let i = 0; i < template.length; i++) {
        if (template[i] === 'X') {
          if (stripped.length) {
            hydrated += stripped.shift()
          } else {
            break
          }
        } else {
          hydrated += template[i]
        }
      }

      if (oldValue && oldValue.length > value.length && hydrated.length === oldValue.length) {
        // Deletion. May need to skip over a template character to delete.
        let lastOpenIndex = -1
        for (let i = 0; i < oldValue.length && i < template.length; ++i) {
          if (template[i] === 'X') lastOpenIndex = i
          if (template[i] !== 'X' && oldValue[i] === template[i] && value[i] !== template[i]) {
            const tmp = Array.from(hydrated)
            tmp.splice(lastOpenIndex, i - lastOpenIndex + 1)
            hydrated = tmp.join('')
            break
          }
        }
      }

      setOldValue(hydrated)
      onChange(hydrated)
    } else {
      onChange(value)
    }
  }

  const isError = isValid != null && value != null && value !== '' && !isValid(value)

  return (
    <>
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={_onChange}
        autoComplete={autoComplete || 'off'}
        className={isError || error ? 'error' : ''}
        maxLength={maxLength}
      />
      <FormError error={error} />
    </>
  )
}
export default Text

export const Money = props => <span><span className="input-dollar">$</span><Text {...props} /></span>

export const Paragraph = ({placeholder, value, onChange}) => {
  const _onChange = e =>{
    onChange(e.target.value)
  }

  return (
    <textarea
      placeholder={placeholder}
      value={value}
      onChange={_onChange}
    />
  )  
}
