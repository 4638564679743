import React from 'react'

export default ({onChange, value, defaults, addText, renderItem}) => {
  const remove = i => () => onChange([
    ...value.slice(0,i), 
    ...value.slice(i+1, value.length)
  ])

  const addMore = () => {
    onChange([...value, defaults])
  }

  return (
    <div>
      { value.map((_, i) => (
        <div className="add-more__group" key={i}>
          { value.length < 2 ? null : <button className="add-more__remove" onClick={remove(i)}>Remove</button> }
          {renderItem(i)}
        </div>
      ))}
      {value.length < 3 && 
        <span className="add-more__add" onClick={addMore}>{addText}</span>
      }
    </div>    
  )
}
