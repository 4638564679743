import React, { useCallback, useState } from "react";
import firebase from "firebase/app";
import trymLogoWhite from "./img/trym-logo-white.svg";
import { Link } from 'react-router-dom'
import DashboardFooter from "./components/DashboardFooter";

const ForgotPassword = ({ history }) => {
  const [errors, setError] = useState("");
  const [formState, setFormState] = useState({
    emailSent: false,
    isLoading: false,
  });

  const handlSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { email } = e.target.elements;
      if (email.value === "") {
        setError({ ...errors, email: "Please enter a valid email address" });
        return;
      }

      try {
        setError("");
        setFormState({...formState, isLoading: true});
        await firebase
          .auth()
          .sendPasswordResetEmail(email.value);
        setFormState({...formState, emailSent: true});
      } catch (error) {
        const errorCode = error.code;
        if (errorCode === "auth/invalid-email") {
          setError({ ...errors, email: "The email address is invalid" });
        } else if (errorCode === "auth/user-not-found") {
          setError({ ...errors, email: "The user does not exist" });
        } else {
          setError({ ...errors, unknown: error.message });
          console.error(error);
        }
      }
    },
    [errors, formState]
  );

  return (
    <div className="page">
      <Link to="/">
        <header className="logo-only">
          <img src={trymLogoWhite} className="header-logo" alt="Trym" />
        </header>
      </Link>

      <section className="green-hero-section">
        <div className="small-container">
          <h1 className="text-center">Reset your Password</h1>
        </div>
      </section>

      <section className="main-content">
        <div className="small-container">
          <div class="main-content_utilities">
            <div class="back-button" onClick={() => history.goBack()}>
              Go back
            </div>
          </div>
          {formState.emailSent && (
            <div className="main-content_box">
              <h2 className="text-center">Reset Password</h2>
              <div className="text-center medium-margin-top">
                An email has been sent. Please click the link when you recieve
                it.
              </div>
            </div>
          )}

          {!formState.emailSent && (
            <form onSubmit={handlSubmit}>
              <div className="main-content_box">
                {errors.unknown && (
                  <div role="alert" className="error-text signup__error">
                    {errors.unknown}
                  </div>
                )}
                <input
                  type="text"
                  name="email"
                  placeholder="Email address"
                  className={errors.email ? "error" : ""}
                />
                {errors.email && (
                  <span role="alert" className="error-text">
                    {errors.email}
                  </span>
                )}
                <p>
                  Please enter your email address. You will receive an email
                  message with instructions on how to reset your password.
                </p>
                <div className="text-center large-margin-top">
                  <button type="submit" disabled={formState.isLoading} className="button primary full-width">
                    Send reset instructions
                  </button>
                </div>
              </div>
            </form>
          )}
        </div>
      </section>

      <DashboardFooter />
    </div>
  );
};

export default ForgotPassword;
