import React, { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import api from "../../api/index";
import Header from "../../components/Header";
import Loading from "../../Loading";
import { UserContext } from "../../context";
import DashboardFooter from "../../components/DashboardFooter";

const moneyFormat = (x) => x && `$${x.toLocaleString()}`;

export default ({ history, location, auth }) => {
  const [policies, setPolicies] = useState("");
  const [activePolicy, setActivePolicy] = useState("");
  const userContext = useContext(UserContext);
  const currentUser = userContext && userContext.user;
  
  let { policyIndex } = useParams();
  policyIndex = +policyIndex || 0;
  const search = location.search || "";

  useEffect(() => {
    if (!currentUser) return;
    async function getData() {
      const policies = await api.get(userContext.token, `/api/policies`);
      setPolicies(policies);
      setActivePolicy(policies[policyIndex]);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  if (!userContext || !policies || !userContext.user || !activePolicy) {
    return <Loading text="Loading your policy information..." />;
  }

  return (
    <div className="page">
      <Header location />
      <section className="green-hero-section">
        <div className="regular-container">
          <h1 className="page-title text-center">Coverage Information</h1>
        </div>
      </section>

      <section className="main-content">
        <div className="regular-container">
          <div className="main-content_utilities">
            <div className="back-button" onClick={() => history.goBack()}>
              Go back
            </div>
            <a
              className="download-policy-button"
              target="_blank"
              href={`/api/policies/${activePolicy.quote.quoteId}/pdf${search}`}
            >
              Download the policy
            </a>
          </div>
          <div className="main-content_box">
            <h2 className="large-margin-bottom">
              Trym {activePolicy.bundleName}{" "}
              <span className="active-plan-pricing">
                ${activePolicy.quote.monthlyPremium || 0}/mo
              </span>
              {/* <a href="#" className="button secondary change-plan-button">
                Change my plan
              </a> */}
            </h2>

            <div className="receipt-table-item">
              <div className="receipt-table-item_left">
                <h4 className="no-margin">General liability</h4>
                <p>
                  Covers third-party bodily injury, property damage, libel,
                  slander
                </p>
              </div>
              <div className="receipt-table-item_right">
                <p className="no-margin">
                  {moneyFormat(activePolicy.quote.generalLiability) || 0}
                </p>
                {/* <a href="#" className="green-link">
                  Increase your limit
                </a> */}
              </div>
            </div>

            <div className="receipt-table-item">
              <div className="receipt-table-item_left">
                <h4 className="no-margin">Legal defense</h4>
                <p>
                  Covers your legal bills and attorney fees if your business is
                  sued
                </p>
              </div>
              <div className="receipt-table-item_right">
                <p className="no-margin">{activePolicy.quote.defenseCosts || 0}</p>
                {/* <a href="#" className="green-link">
                    Lower your deductible
                  </a> */}
              </div>
            </div>

            <div className="receipt-table-item">
              <div className="receipt-table-item_left">
                <h4 className="no-margin">Building insurance</h4>
                <p>Covers physical damage to your building</p>
              </div>
              <div className="receipt-table-item_right">
                <p className="no-margin">
                  {activePolicy.quote.buildingInsurance || 0}
                </p>
              </div>
            </div>

            <div className="receipt-table-item">
              <div className="receipt-table-item_left">
                <h4 className="no-margin">Property insurance</h4>
                <p>Covers all the moveable items owned by your business</p>
              </div>
              <div className="receipt-table-item_right">
                <p className="no-margin">
                  {moneyFormat(activePolicy.quote.property) || 0}
                </p>
              </div>
            </div>

            <div className="receipt-table-item">
              <div className="receipt-table-item_left">
                <h4 className="no-margin">Property deductible</h4>
                <p>Your out-of-pocket costs before coverage kicks in</p>
              </div>
              <div className="receipt-table-item_right">
                <p className="no-margin">
                  {moneyFormat(activePolicy.quote.deductible) || 0}
                </p>
                {/* <a href="#" className="green-link">
                    Lower your deductible
                  </a> */}
              </div>
            </div>

            <div className="receipt-table-item">
              <div className="receipt-table-item_left">
                <h4 className="no-margin">Business income protection</h4>
                <p>
                  Covers lost income if your business shuts down because of a
                  disaster
                </p>
              </div>
              <div className="receipt-table-item_right">
                <p className="no-margin">
                  {activePolicy.quote.businessIncomeProtection || 0}
                </p>
                {/* <a href="#" className="green-link">
                    Increase your limit
                  </a> */}
              </div>
            </div>

            <div className="receipt-table-item">
              <div className="receipt-table-item_left">
                <h4 className="no-margin">Employment practices liability</h4>
                <p>Covers HR related claims against your business</p>
              </div>
              <div className="receipt-table-item_right">
                <p className="no-margin">
                  {moneyFormat(activePolicy.quote.employmentPractices) || 0}
                </p>
                {/* <a href="#" className="green-link">
                    Increase your limit
                  </a> */}
              </div>
            </div>

            <div className="receipt-table-item">
              <div className="receipt-table-item_left">
                <h4 className="no-margin">Equipment breakdown</h4>
                <p>Covers replacement costs for damaged essential equipment</p>
              </div>
              <div className="receipt-table-item_right">
                <p className="no-margin">
                  {moneyFormat(activePolicy.quote.equipmentBreakdown) || 0}
                </p>
                {/* <a href="#" className="green-link">
                    Increase your limit
                  </a> */}
              </div>
            </div>

            <div className="receipt-table-item">
              <div className="receipt-table-item_left">
                <h4 className="no-margin">Professional liability</h4>
                <p>
                  Covers your errors and omissions from your work with clients
                </p>
              </div>
              <div className="receipt-table-item_right">
                <p className="no-margin">
                  {activePolicy.quote.professionalLibility || "Coming soon"}
                </p>
                {/* <a href="#" className="green-link">
                    Increase your limit
                  </a> */}
              </div>
            </div>

            <div className="receipt-table-item">
              <div className="receipt-table-item_left">
                <h4 className="no-margin">Cyber insurance</h4>
                <p>
                  Covers you if a data breach happens and sensitive customer
                  data gets out
                </p>
              </div>
              <div className="receipt-table-item_right">
                <p className="no-margin">
                  {activePolicy.quote.cyberSecurity || "Coming soon"}
                </p>
                {/* <a href="#" className="green-link">
                    Increase your limit
                  </a> */}
              </div>
            </div>

            <div className="receipt-table-item">
              <div className="receipt-table-item_left">
                <h4 className="no-margin">Monthly premium</h4>
              </div>
              <div className="receipt-table-item_right">
                <p className="no-margin text-green">
                  <b>${activePolicy.quote.monthlyPremium || 0}/mo</b>
                </p>
              </div>
            </div>
          </div>

          <div className="support-box">
            <div className="support-box_text">
              <h2 className="text-white">Support</h2>
              <p className="text-white">
                For any additional help, pelase send us an email, a fellow human
                will respond to you.
              </p>
            </div>
            <div className="support-box_actions">
              <a
                href="mailto:help@trym.co"
                className="support-box_actions_email-adress"
              >
                help@trym.co
              </a>
            </div>
          </div>
        </div>
      </section>
      <DashboardFooter />
    </div>
  );
};
