import React, { useState } from "react";
import PrimaryButton from "../../PrimaryButton";
import TrymProgress from "../../components/TrymProgress";
import FraudWarnings from "../../FraudWarnings";
import { useLocalState, setQuoteProgress } from "../../utils";
import { quoteDefaultAnswers } from '../../utils/quoteDefaults';

import { sendAnswers } from "../../api";
import { Slider, Money, Toggle } from "../../questions/questions";
import Alert from "../../components/Alert";

const TrymQuestions = ({ history }) => {
  const [answers, setAnswers] = useLocalState(
    "quote-progress",
    {
      state: { ...quoteDefaultAnswers},
      url: "/trym"
    },
    null
  );

  // if the user has come here without an local storage, they need to start over. 
  if (!answers || !answers.state.contact || !answers.state.contact.email) document.location.href = "/";

  const [errors, setError] = useState({
    fields: {
      buildings: {}
    },
    message: "",
  });

  const validateAnswers = (data) => {
    let hasError = false;
    let defaultMessage = "There are errors to correct before you can continue";
    let formErrors = {};

    let buildingErrors = {};
    data.buildings.forEach((x, i) => {
      let hasBuildingError = false;
      let buildingError = {};
      if (!x.revenue || x.revenue === 0) {
        hasBuildingError = true;
        buildingError.revenue = "Approximate business revenue required"
      }

      if (hasBuildingError) {
        hasError = hasBuildingError;
        buildingErrors = { ...buildingErrors, [i]: buildingError };
      }
    });

    if (answers.disqualifying_includes && !hasError) {
      hasError = true;
      defaultMessage =
        "Unfortunately, based on your responses, we cannot consider your application for Business Owners or Cyber Risk Insurance at this time.";
    }

    setError({
      fields: { buildings: buildingErrors, ...formErrors },
      message: hasError
        ? defaultMessage
        : "",
    });

    return hasError;
  };

  function nextStep() {
    if (validateAnswers(answers.state)) {
      return;
    }

    const quotesUrl = "/quotes";
    sendAnswers(answers.state);
    history.replace("/trym", answers.state);
    setQuoteProgress(quotesUrl, answers.state);
    history.push(quotesUrl, answers.state);
  }

  return (
    <div className="app q-flow">
      <div className="app__main">
        <div className="questions">
          <header>
            <h1>Let's Trym it a bit</h1>
            <h2>Tell us a little more about your business</h2>
          </header>
          <TrymProgress step={2} />
          <div className="questions__main">
            <div className="questions__form">
              <div className="questions__container">
                <div className="question-list">
                  <div className="question-group">
                    {answers.state.buildings &&
                      answers.state.buildings.map((x, i) => {
                        return (
                          <div key={x}>
                            <Slider
                              propPath={["state", "buildings", i, "revenue"]}
                              answers={answers}
                              setAnswers={setAnswers}
                              title={`Approximately how much revenue will your business at ${x.building_address} make this year?`}
                              min={0}
                              max={6025000}
                              step={25000}
                              tooltips={["$0", "$6,000,000+"]}
                              tooltipFormat={moneyFormat}
                              error={
                                errors.fields.buildings &&
                                errors.fields.buildings[i] &&
                                errors.fields.buildings[i].revenue
                              }
                            />
                            {x.revenue >= 6000000 && (
                              <div className="question-group">
                                <Money
                                  propPath={["state", "buildings", i, "revenue_high"]}
                                  answers={answers}
                                  setAnswers={setAnswers}
                                  title="Whoa! We see you, big-timer. It's okay, we'll still let everyone think you're small."
                                  desc="Type in your actual revenue ($)"
                                  placeholder="6,000,000"
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}

                    <div className="br" />
                    <Slider
                      propPath={["salaries"]}
                      answers={answers}
                      setAnswers={setAnswers}
                      title="How much do you pay in salaries?"
                      min={0}
                      max={5000000}
                      step={25000}
                      tooltips={["$0", "$5,000,000+"]}
                      tooltipFormat={moneyFormat}
                    />
                    {answers.salaries >= 5000000 && (
                      <div className="question-group">
                        <Money
                          propPath={["salaries_high"]}
                          answers={answers}
                          setAnswers={setAnswers}
                          title=" "
                          desc="Type in your actual salaries ($)"
                          placeholder="5000000"
                        />
                      </div>
                    )}
                    <div className="br" />
                    <Toggle
                      propPath={["has_losses"]}
                      answers={answers}
                      setAnswers={setAnswers}
                      title="Have you had any loss occurrences to your business, or claims against you by others, whether covered by insurance or not, regardless of fault, in the last 3 years?"
                    />
                    {answers.has_losses === true && (
                      <div className="question-group">
                        <Slider
                          propPath={["property_losses"]}
                          answers={answers}
                          setAnswers={setAnswers}
                          title="Property loss events or claims"
                          min={0}
                          max={3}
                          step={1}
                          tooltips={["0", "3 or more"]}
                        />
                        <Slider
                          propPath={["general_losses"]}
                          answers={answers}
                          setAnswers={setAnswers}
                          title="General liability events or claims"
                          min={0}
                          max={3}
                          step={1}
                          tooltips={["0", "3 or more"]}
                        />
                        <Slider
                          propPath={["professional_losses"]}
                          answers={answers}
                          setAnswers={setAnswers}
                          title="Professional liability or errors &amp; omissions events, losses or claims"
                          min={0}
                          max={3}
                          step={1}
                          tooltips={["0", "3 or more"]}
                        />
                      </div>
                    )}
                    <div className="br" />
                    <Toggle
                      propPath={["disqualifying_includes"]}
                      answers={answers}
                      setAnswers={setAnswers}
                      title="Do your businesses include any of the following?"
                      bulletDesc={disqIncludes}
                    />
                  </div>
                </div>
              </div>
              <FraudWarnings
                mailing={answers.state.mailing}
                buildings={answers.state.buildings}
              />
            </div>
          </div>
          {errors.message && <Alert type="error" message={errors.message} />}
          <PrimaryButton className="questions__quote" onClick={nextStep}>
            View my quote
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default TrymQuestions;

const moneyFormat = (x) => `$${x.toLocaleString()}`;

const disqIncludes = [
  "Sale of firearms, ammunition or weapons of any kind?",
  "Owning or operating any aviation devices such as aircraft, helicopters or drones?",
  "Products or services for any military, law enforcement or any other armed services?",
  "Directly importing more than 5% of your goods from outside the U.S.?",
  "Requirements to post any motor carrier financial responsibility filings to any agency?",
  "Current or past operations involving manufacturing, relabeling, or repackaging of components or products made by others?",
  "Hiring drivers who are not your employees to deliver your goods or services?",
  "Current or past operations involving storage, transportation, or disposal of hazardous materials or substances?",
  "Employees using their personal vehicles to deliver food more than 10 days a month?",
  "Have a physical location open 24 hours or after midnight?",
];
