import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import Loading from "../../Loading";
import { useForm } from "../../hooks/useForm";
import Header from "../../components/Header";
import DashboardFooter from "../../components/DashboardFooter";
import api from "../../api/index";
import FormError from "../../components/FormError";
import { UserContext } from "../../context";

export default ({ history, location, auth }) => {
  const [policies, setPolicies] = useState();
  const [existingCerts, setExistingCerts] = useState();
  const [errors, setError] = useState({
    fields: {
      companyName: "",
      firstName: "",
      lastName: "",
      companyAddress1: "",
      companyAddress2: "",
      city: "",
      state: "",
      postalCode: "",
    },
    message: "",
  });

  const [certInfo, handleChange] = useForm({
    companyName: "",
    firstName: "",
    lastName: "",
    companyAddress1: "",
    companyAddress2: "",
    city: "",
    state: "",
    postalCode: "",
    addInsured: false,
    addLanguage: false,
    additionalLanguage: "",
    waiverOfSubregation: false,
    bundleAdditionalInsured: false,
    additionalInsured: false,
  });

  const userContext = useContext(UserContext);
  const currentUser = userContext && userContext.user;
  useEffect(() => {
    if (!currentUser) return;
    async function getData() {
      try {
        setPolicies(await api.get(userContext.token, `/api/policies`));
        setExistingCerts(await api.get(userContext.token, `/api/certificates`));
      } catch (err) {
        setError({ ...errors, message: err });
        console.error(err);
      }
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const getPaymentAmount = () => {
    if (certInfo.bundleAdditionalInsured) {
      return "125";
    }

    if (certInfo.addInsured) {
      return "25";
    }
  };

  const requiresPayment = () => {
    return (
      certInfo.additionalInsured &&
      !userContext.profile.policyInfo.blanketInsuredPaid
    );
  };

  const validate = (data) => {
    let hasError = false;
    const formErrors = {};
    if (data.companyName === "") {
      hasError = true;
      formErrors.companyName = "Company name is required";
    }

    if (data.firstName === "") {
      hasError = true;
      formErrors.firstName = "First name are requied";
    }

    if (data.lastName === "") {
      hasError = true;
      formErrors.lastName = "Last name is required";
    }

    if (data.companyAddress1 === "") {
      hasError = true;
      formErrors.companyAddress1 = "Address is required";
    }

    if (data.city === "") {
      hasError = true;
      formErrors.city = "City is required";
    }

    if (data.state === "") {
      hasError = true;
      formErrors.state = "State is required";
    }

    if (data.postalCode === "") {
      hasError = true;
      formErrors.postalCode = "Zip is required";
    }

    setError({ ...errors, fields: { ...formErrors } });
    return hasError;
  };

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      if (validate(certInfo)) {
        return;
      }

      const certUrl = await api.post(
        userContext.token,
        `/api/certificates`,
        JSON.stringify(certInfo)
      );
      window.open(certUrl.url);
    } catch (err) {
      setError({ ...errors, message: err });
    }
  }

  if (!policies || !userContext.profile) {
    return <Loading text="Loading your policy information..." />;
  }

  return (
    <div className="page">
      <Header location={location} />

      <section className="green-hero-section">
        <div className="regular-container">
          <h1 className="page-title text-center">Certificate of insurance</h1>
        </div>
      </section>

      <section className="main-content">
        <div className="regular-container">
          <div className="main-content_utilities">
            <div className="back-button" onClick={() => history.goBack()}>
              Go back
            </div>
          </div>
          <div className="main-content_box">
            <h2>Generate a new certificate</h2>
            <p>
              Fill out the form below and we will prepare your proof of
              insurance for your certificate holder
            </p>
            <div className="divider"></div>

            <h3>Certificate holder’s information</h3>
            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="companyName"
                value={certInfo.companyName}
                onChange={handleChange}
                className={errors.fields.companyName ? "error" : ""}
                placeholder="Company name"
              />
              <FormError error={errors.fields.companyName} />

              <div className="row">
                <div className="col-1-2">
                  <input
                    type="text"
                    name="firstName"
                    value={certInfo.firstName}
                    className={errors.fields.firstName ? "error" : ""}
                    onChange={handleChange}
                    placeholder="First name"
                  />
                  <FormError error={errors.fields.firstName} />
                </div>
                <div className="col-1-2">
                  <input
                    type="text"
                    name="lastName"
                    value={certInfo.lastName}
                    className={errors.fields.lastName ? "error" : ""}
                    onChange={handleChange}
                    placeholder="Last name"
                  />
                  <FormError error={errors.fields.lastName} />
                </div>
              </div>
              <input
                type="text"
                name="companyAddress1"
                value={certInfo.companyAddress1}
                className={errors.fields.companyAddress1 ? "error" : ""}
                onChange={handleChange}
                placeholder="Company address"
              />
              <FormError error={errors.fields.companyAddress1} />

              <input
                type="text"
                name="companyAddress2"
                value={certInfo.companyAddress2}
                onChange={handleChange}
                placeholder="Company address line 2 (optional)"
              />

              <input
                type="text"
                name="city"
                placeholder="City"
                className={errors.fields.city ? "error" : ""}
                value={certInfo.city}
                onChange={handleChange}
              />
              <FormError error={errors.fields.city} />

              <div className="row">
                <div className="col-1-2">
                  <div
                    className={`select-style ${
                      errors.fields.state ? "error" : ""
                    }`}
                  >
                    <select
                      name="state"
                      value={certInfo.state}
                      onChange={handleChange}
                    >
                      <option value="">State</option>
                      <option value="CA">CA</option>
                      <option value="NJ">NJ</option>
                      <option value="AZ">AZ</option>
                      <option value="NV">NV</option>
                      <option value="ID">ID</option>
                      <option value="IN">MN</option>
                    </select>
                  </div>
                  <FormError error={errors.fields.state} />
                </div>
                <div className="col-1-2">
                  <input
                    type="number"
                    name="postalCode"
                    value={certInfo.postalCode}
                    className={errors.fields.postalCode ? "error" : ""}
                    onChange={handleChange}
                    placeholder="Zip Code"
                  />
                  <FormError error={errors.fields.postalCode} />
                </div>
              </div>

              <div className="medium-margin-bottom">
                <div className="switch-button-container">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="addInsured"
                      checked={certInfo.addInsured}
                      onChange={handleChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <h4>
                  Do you need to add certificate holder as an Additional Insured
                  to your policy?
                </h4>
                <div className="text-medium" style={{ maxWidth: "90%" }}>
                  Additional Insureds increase your liability but may be a
                  necessary evil or contractual obligation. For example, if the
                  Additional Insured is named in a lawsuit because of your
                  operations your coverage would kick in to cover their
                  associated costs.
                </div>
              </div>

              {certInfo.addInsured && (
                <div>
                  <h4>Which Best describes the third-party you're adding?</h4>
                  <div className="text-medium">
                    <input
                      id="landlord-checkbox"
                      type="checkbox"
                      name="addLandlord"
                      checked={certInfo.addLandlord}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="landlord-checkbox"
                      className="certificate-checkbox"
                    >
                      <span>
                        Landlord or property manager you lease space from?
                        <span className="text-pill"> free </span>
                      </span>
                    </label>
                  </div>
                  <div className="text-medium">
                    <input
                      type="checkbox"
                      id="lender-checkbox"
                      name="addLender"
                      checked={certInfo.addLender}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="lender-checkbox"
                      className="certificate-checkbox"
                    >
                      <span>
                        Lender or mortgage holder?
                        <span className="text-pill">free</span>
                      </span>
                    </label>
                  </div>
                  <div className="text-medium">
                    <input
                      id="businessProperty-chckbox"
                      type="checkbox"
                      name="addLenderInterest"
                      checked={certInfo.addLenderInterest}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="businessProperty-chckbox"
                      className="certificate-checkbox"
                    >
                      <span>
                        Lender or Org who holds a financial interest in your
                        business property?
                        <span className="text-pill">free</span>
                      </span>
                    </label>

                    {certInfo.addLenderInterest && (
                      <div className="small-margin-top">
                        <div>
                          What specific business property? Please write a
                          description. Examples: A forklift that was leased,
                          espresso machine you rented, or computer servers you
                          took a loan out on.
                        </div>
                        <textarea
                          placeholder="Type here..."
                          rows="6"
                          className="text-medium"
                          name="additionalLanguage"
                          value={certInfo.additionalLanguage}
                          onChange={handleChange}
                        ></textarea>
                      </div>
                    )}
                  </div>
                  <div className="text-medium medium-margin-top">
                    <input
                      id="additionalInsured-checkbox"
                      type="checkbox"
                      name="additionalInsured"
                      checked={certInfo.additionalInsured}
                      onChange={handleChange}
                    />
                    <label
                      htmlFor="additionalInsured-checkbox"
                      className="certificate-checkbox"
                    >
                      <span>
                        A customer, person, or other organization you have
                        agreed to add as an Additional Insured by a written
                        contract?
                        <span className="text-pill--inverse">
                          {!userContext.profile.policyInfo.blanketInsuredPaid &&
                            `$25 per Additional Insured`}
                          {userContext.profile.policyInfo.blanketInsuredPaid &&
                            `Already Paid`}
                        </span>
                      </span>
                    </label>
                  </div>
                  {!userContext.profile.policyInfo.blanketInsuredPaid &&
                    certInfo.additionalInsured && (
                      <div className="text-medium medium-margin-top">
                        <div className="switch-button-container--left medium-margin-right">
                          <label className="switch">
                            <input
                              type="checkbox"
                              name="bundleAdditionalInsured"
                              checked={certInfo.bundleAdditionalInsured}
                              onChange={handleChange}
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <p>
                          Will you need to add more than five Additional
                          Insureds to your policy in the next year? If so, buy
                          the Blanket Additional Insured for $125 to save money
                          and add as many as you want.
                        </p>
                      </div>
                    )}
                </div>
              )}

              <div className="medium-margin-bottom">
                <div className="switch-button-container">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="waiverOfSubregation"
                      checked={certInfo.waiverOfSubregation}
                      onChange={handleChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <div className="medium-margin-bottom">
                  <h4>
                    Does the additional insured want a Waiver of Subregation?
                    <span className="text-pill">free</span>
                  </h4>
                </div>
                <div className="text-medium">
                  A Waiver of Subrogation prohibits your insurance company from
                  recovering money from your Additional Insured for their
                  negligence. This is often required by bigger businesses in
                  contracts, but you can negotiate to keep it out because it
                  increases your liability
                </div>
              </div>

              <div>
                <div className="switch-button-container">
                  <label className="switch">
                    <input
                      type="checkbox"
                      name="addLanguage"
                      checked={certInfo.addLanguage}
                      onChange={handleChange}
                    />
                    <span className="slider round"></span>
                  </label>
                </div>
                <h4 className="no-margin">
                  Is there any specific language your certificate holder wants
                  you to add?
                </h4>
                <div className="text-medium">
                  An example would be the name of the project you’re working on.
                  <br />
                  Put the notes here and we’ll include the language in the
                  certificate.
                </div>
              </div>
              {certInfo.addLanguage && (
                <textarea
                  placeholder="Type here..."
                  rows="6"
                  name="additionalLanguage"
                  className="text-medium"
                  value={certInfo.additionalLanguage}
                  onChange={handleChange}
                ></textarea>
              )}

              {requiresPayment() && (
                <div className="additional-insured__confirmation medium-margin-top">
                  Click the button below to submit a payment of $
                  {getPaymentAmount()} to the card on file ending in{" "}
                  {userContext.profile.paymentInfo.lastFour}
                </div>
              )}
              <div className="text-center medium-margin-top">
                <button className="button primary">
                  {!requiresPayment() && `Download Certificate`}
                  {requiresPayment() && `Pay and Download`}{" "}
                </button>
                <p
                  className="text-small"
                  style={{ width: "250px", margin: "20px auto 0 auto" }}
                >
                  When clicking the button, a PDF will download that you can
                  send as an attachment from your work email.
                </p>
              </div>
            </form>
          </div>

          <div className="main-content_box large-margin-top">
            <h2 className="large-margin-bottom">
              Previously created certificates
            </h2>

            {existingCerts &&
              existingCerts.map((certInfo) => {
                return (
                  <div className="receipt-table-item">
                    <div className="receipt-table-item_left">
                      <p className="no-margin">{certInfo.companyName}</p>
                      <p className="text-small">
                        {`generated on ${moment(certInfo.createdDate).format(
                          "YYYY-MM-DD"
                        )}`}
                      </p>
                    </div>
                    <div className="receipt-table-item_right">
                      <a className="uppercase-link" href={certInfo.url}>
                        Download
                      </a>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="support-box">
            <div className="support-box_text">
              <h2 className="text-white">Support</h2>
              <p className="text-white">
                For any additional help, please send us an email, a fellow human
                will respond to you.
              </p>
            </div>
            <div className="support-box_actions">
              <a
                href="mailto:help@trym.co"
                className="support-box_actions_email-adress"
              >
                help@trym.co
              </a>
            </div>
          </div>
        </div>
      </section>
      <DashboardFooter />
    </div>
  );
};
