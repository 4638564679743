function makeRequest(idToken, method, url, body) {
  return fetch(url, {
    method,
    headers: {
      accept: "application/json",
      "content-type": "application/json",
      authorization: `Bearer ${idToken}`,

    },
    body,
  }).then((res) => {
    if (!res.ok) {
      if (res.status === 404 && method === "get") return null;
      return res
        .json()
        .then((body) => {
          throw Error(JSON.stringify(body));
        })
        .catch(() => {
          throw Error(res.status);
        });
    }

    return res.json();
  });
}

const api = {
  get(idToken, url) {
    return makeRequest(idToken, "get", url);
  },
  post(idToken, url, body) {
    return makeRequest(idToken, "post", url, body);
  },
  put(idToken, url, body) {
    return makeRequest(idToken, "put", url, body);
  },
  patch(idToken, url, body) {
    return makeRequest(idToken, "patch", url, body);
  },
  delete(idToken, url, body) {
    return makeRequest(idToken, "del", url, body);
  },
  head(idToken, url, query) {
    return makeRequest(idToken, "head", url, query);
  },
};

export default api;
