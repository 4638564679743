import React, {useRef, useState} from 'react'

import styled from 'styled-components'
import HomeNav, {Top} from './HomeNav'
import Footer from './Footer'

import states from "./config/states"

import logos from './img/logos.png'
import returnArrowWhite from './img/return-arrow-white.svg'
import returnArrowGray from './img/return-arrow-gray.svg'
import checkmarkWhite from './img/checkmark-white.svg'
import checkmarkOrange from './img/checkmark-orange.svg'
import caretRightGreen from './img/caret-right-green.svg'
import greenCircleCheckmark from './img/green-circle-checkmark.svg'
import snapIcon from './img/snap-icon.svg'

const Agents = () => {
  const home = useRef(null)
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false)

  return (
    <Root ref={home} className="agents-page">
      <Top>
        <HomeNav rootRef={home} isInfoModalOpen={isInfoModalOpen} setIsInfoModalOpen={setIsInfoModalOpen} />
      </Top>

      <section className="agents-page_hero">
      <div className="agents-page_hero_left">
        
        <h1><span className="text-green">Earn big</span> on your small clients</h1>
        
        <SignupForm className="agents-page_hero_left_lead-form" />
      </div>

      <div className="agents-page_hero_right">
          <h2>Here’s how it works</h2>
          <ol>
            <li>Sign up on Trym’s insurance agent platform</li>
            <li>Receive a custom referral link within 24 hours</li>
            <li>Share the link with small business insurance leads that have 1-19 employees</li>
            <li className="text-green">Collect a 12% commission every time a referral buys a policy</li>
          </ol>
      </div>
    </section>

    <section className="agents-page_commissions">
      <div className="regular-container">
        <h2 className="text-center large-margin-bottom">Bigger commissions</h2>
        <div className="row">
            <div className="col-1-2">
              <div className="agents-page_commissions_box trym">
                <h3>Trym math</h3>
                <div className="agents-page_commissions_box_item">$1,000 total premium</div>
                <div className="agents-page_commissions_box_item"><img src={returnArrowWhite} alt="" />12% commission</div>
                <div className="agents-page_commissions_box_item"><img src={checkmarkWhite} alt="" />$120 in your pocket</div>
              </div>
            </div>
            <div className="col-1-2">
              <div className="agents-page_commissions_box agency">
                <h3>Agency math</h3>
                <div className="agents-page_commissions_box_item">$1,000 total premium</div>
                <div className="agents-page_commissions_box_item"><img src={returnArrowGray} alt="" />15% commission</div>
                <div className="agents-page_commissions_box_item"><img src={caretRightGreen} alt="" />$150 to agency</div>
                <div className="agents-page_commissions_box_item"><img src={returnArrowGray} alt="" />20% your commission</div>
                <div className="agents-page_commissions_box_item orange"><img src={checkmarkOrange} alt="" /><strong>$30</strong> in your pocket</div>
              </div>
            </div>
          </div>
      </div>
    </section>

    <section className="agents-page_compare-process">
      <div className="small-container text-center large-margin-bottom">
        <img src={snapIcon} alt="Snap! Less work" />
        <h2>Less work</h2>
        <p>Writing new accounts manually takes forever. Skip the weeks of back and forth work and send leads to Trym in seconds.</p>
      </div>
      <div className="regular-container">
        <div className="agents-page_compare-process_box">
          <h3>The Trym way <span className="text-green">30sec</span></h3>
          <p>
            Meet your prospect
            <span>></span>
            Send your prospect to Trym
            <span>></span>
            <strong className="text-green">Get paid for seconds of work</strong>
          </p>
        </div>
        <div className="agents-page_compare-process_box">
          <h3>The agency way <span>Up to 10 weeks</span></h3>
          <p>
            Meet your prospect
            <span>></span>Give your prospect applications
            <span>></span>Load applications into your CRM
            <span>></span>Type info into agency quoting platforms or send to underwriter
            <span>></span>Answer underwriter follow up questions
            <span>></span>Go back and forth with client to answer follow up questions
            <span>></span>Review quote with client
            <span>></span>Make necessary changes (repeat steps 3-7)
            <span>></span>Get approval, signed applications, and down payment
            <span>></span>Send issuing instructions to insurance company
            <span>></span>Switch customer from prospect to customer in agency CRM
            <span>></span>Set up billing
            <span>></span>Send “proof of insurance” certificates to customer
            <span>></span>Receive final bound insurance policies from insurance company
            <span>></span>Check policy for mistakes/inaccuracies (about 30% have errors and make corrections)
            <span>></span>Receive final correct policy and send to customer

          </p>
        </div>
      </div>
    </section>

      <section className="agents-page_happy-customer">
        <div className="small-container text-center">
          <h2 className="text-white">Happier clients <img src={greenCircleCheckmark} alt="" /></h2>
          <p className="text-white">Turning away potential small business clients to focus on bigger deals is always a punch in the gut. Avoid the pain, get your people the coverage they need, and increase your book of satisfied clients.</p>
        </div>
      </section>

      <section className="agents-page_mid-cta">
        <div className="large-container">
          <div className="agents-page_mid-cta_cta-box">
            <h2 className="text-center medium-margin-bottom">It’s a no-brainer, sign up now</h2>
            <SignupForm className="agents-page_happy-customer_cta-box_cta-form" />
          </div>
        </div>
      </section>

      <section className="agents-page_partnership-explainer">
        <div className="regular-container text-center">
          <img src={logos} height="58px" alt="" />
          <p className="large-margin-top">Trym is the easiest and fastest way to buy small business insurance online. The company is backed by Crum & Forster, a 195-year-old insurance company Rated A Excellent by A.M. Best with $10+ billion in revenues and assets. C&F is a member of Fairfax Financial Holdings Limited, a $42B global firm engaged in insurance, reinsurance and investment management.</p>
        </div>
      </section>

      <section className="agents-page_faqs">
        <div className="large-container">
          <h2 className="large-margin-bottom">FAQ</h2>
          <div className="one-faq closed">
            <div className="one-faq_question">What kind of insurance license do I need to sell Trym policies?</div>
            <div className="one-faq_answer">Let’s be honest. Small business owners get the short end of the stick when it comes to insurance. Because as agents, we know it’s the same amount of work as big clients but for less money. We wanted to create a referral model that’s a win-win for agents and small business owners, so we did it. </div>
          </div>
          <div className="one-faq open">
            <div className="one-faq_question">What’s the story behind Trym’s agent referral model?</div>
            <div className="one-faq_answer">Let’s be honest. Small business owners get the short end of the stick when it comes to insurance. Because as agents, we know it’s the same amount of work as big clients but for less money. We wanted to create a referral model that’s a win-win for agents and small business owners, so we did it. </div>
          </div>
          <div className="one-faq closed">
            <div className="one-faq_question">What businesses can I refer to Trym?</div>
            <div className="one-faq_answer">Let’s be honest. Small business owners get the short end of the stick when it comes to insurance. Because as agents, we know it’s the same amount of work as big clients but for less money. We wanted to create a referral model that’s a win-win for agents and small business owners, so we did it. </div>
          </div>
          <div className="one-faq closed">
            <div className="one-faq_question">How do insurance agents make money with Trym?</div>
            <div className="one-faq_answer">Let’s be honest. Small business owners get the short end of the stick when it comes to insurance. Because as agents, we know it’s the same amount of work as big clients but for less money. We wanted to create a referral model that’s a win-win for agents and small business owners, so we did it. </div>
          </div>
          <div className="one-faq closed">
            <div className="one-faq_question">Is Trym’s agent program a new model for the insurance industry?</div>
            <div className="one-faq_answer">Let’s be honest. Small business owners get the short end of the stick when it comes to insurance. Because as agents, we know it’s the same amount of work as big clients but for less money. We wanted to create a referral model that’s a win-win for agents and small business owners, so we did it. </div>
          </div>
        </div>
      </section>
      <Footer />
    </Root>
  )
}

const Root = styled.div`
  background-color: white;
`

const SignupForm = ({className}) => (
  <form className={className} action="https://agents.stage.trym.co/signup" method="POST">
    <div className="row">
      <div className="col-1-2">
        <input type="text" name="agent-lead-first-name" placeholder="First name" />
      </div>
      <div className="col-1-2">
        <input type="text" name="agent-lead-last-name" placeholder="Last name" />
      </div>
    </div>
    <input name="agent-lead-email" type="email" placeholder="Email" />

    <div className="row">
      <div className="col-1-2">
        <input type="text" name="agent-lead-license" placeholder="License #" />
      </div>
      <div className="col-1-2">
        <div className="select-style">
          <select defaultValue="">
            <option disabled="disabled" value="">State</option>
            {states.map(({value, key}) => <option value={key} key={key}>{value}</option>)}
          </select>
        </div>
      </div>
    </div>
    <button type="submit" className="button primary full-width medium-margin-top">Receive my referral link</button>
  </form>
)
export default Agents