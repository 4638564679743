import React, { useState, useEffect, useContext } from "react";
import api from '../../api/index';
import Loading from "../../Loading";
import { UserContext } from "../../context";
import DashboardFooter from "../../components/DashboardFooter";
import Alert from "../../components/Alert";
import Header from "../../components/Header";
import moment from 'moment'

export default ({ history, location, auth }) => {
  const [policies, setPolicies] = useState();
  const [billingHistory, setBillingHistory] = useState();
  const [error, setError] = useState("");

  const userContext = useContext(UserContext);
  const currentUser = userContext && userContext.user;

  useEffect(() => {
    if (!currentUser) return;
    async function getData() {
      try {
        setPolicies(await api.get(userContext.token, `/api/policies`));
        const returnVal = await api.get(userContext.token, `/api/billinghistory`)
        setBillingHistory(returnVal.data);
      } catch (err) {
        setError(err);
        console.error(err);
      }
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  if (!currentUser || !policies || !userContext.profile || !billingHistory) {
    return <Loading text="Loading your policy information..." />;
  }

  return (
    <div className="page">
      <Header location={location} />

      <section className="green-hero-section">
        <div className="regular-container">
          <h1 className="page-title text-center">Billing History</h1>
        </div>
      </section>

      <section className="main-content">
        <div className="regular-container">
          <div className="main-content_utilities">
            <div className="back-button" onClick={() => history.goBack()}>Go back</div>
          </div>
          <div className="main-content_box">
            <h2 className="large-margin-bottom">Payments made</h2>
              {error && (
                <Alert type="error" message={error} />
              )}
              { billingHistory && billingHistory.map((charge, idx) => {
                return (
                  <div key={idx} className="receipt-table-item">
                    <div className="receipt-table-item_left">
                      <p className="no-margin">{charge.description} <b>${charge.amount/100}</b></p>
                      <p className="text-small">paid on {moment(charge.created).format('YYYY-MM-DD')}</p>
                    </div>
                    <div className="receipt-table-item_right">
                      <a href={charge.receipt_url} rel="noopener noreferrer" target="_blank" className="uppercase-link">Download Receipt</a>
                    </div>
                  </div>
                )
              })}        
          </div>

          <div className="support-box">
            <div className="support-box_text">
              <h2 className="text-white">Support</h2>
              <p className="text-white">For any additional help, pelase send us an email, a fellow human will respond to you.</p>
            </div>
            <div className="support-box_actions">
              <a href="mailto:help@trym.co" className="support-box_actions_email-adress">help@trym.co</a>
            </div>
          </div>
        </div>
      </section>

      <DashboardFooter />
    </div>
  );
};
