import React from "react";


export default () => {
  return (
    <footer>
      <div className="regular-container">
        <p className="copyright">
          <span className="copyright_entity">
            &copy; <script>document.write(new Date().getFullYear());</script>
            Trym Inc.
          </span>
          <a href="/terms-of-service" className="copyright_link">
            Terms of service
          </a>
          <a href="/privacy-policy" className="copyright_link">
            Privacy policy
          </a>
        </p>
      </div>
  </footer>
  );
}