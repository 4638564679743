import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logo from './images/trym-logo-black.svg'

const ErrorPage = ({text}) => {
  return (
    <Container>
      <Link to="/"><Logo src={logo} alt="Trym" /></Link>
      <Text>{text}</Text>
      <a href="mailto:help@trym.co">help@trym.co</a>
      <Link to="/"><Logo src={logo} alt="Trym" /></Link>
    </Container>
  )
}

const Container = styled.div `
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Logo = styled.img `
  display: block;
  height: 2rem;
  margin: 1rem 0;
`

const Text = styled.div `
  margin: 3rem 0;
  max-width: 500px;
  text-align: center;
`

export default ErrorPage
