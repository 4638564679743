import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import config from "./config";
import firebase from "firebase/app";
import Header from "./Header";
import SignUp from "./SignUp";
import Loading from "./Loading";
import TrymProgress from "./components/TrymProgress";
import PaymentCoverage from "./PaymentCoverage";
import "./stripe.css";
import PrimaryButton from "./PrimaryButton";
import { useReferral } from "./referrals";
import { getLocalJSON } from "./utils";
import Toggle from "./questions/Toggle";

const stripe = window.Stripe(config.stripe.pk);
const elements = stripe.elements();

const onChange = (setter) => (e) => setter(e.target.value);
const onCheckChange = (setter) => (e) => setter(e.target.checked);
const Payment = ({ auth, history, location }) => {
  useRestoreScroll();
  const referralCode = useReferral();
  const { state } = location || {};
  const paymentState = state || getLocalJSON("payment-state");
  const { answers, contact, trymId } = paymentState;
  const { mailing } = answers;
  const [validationError, setValidationError] = useState(null);
  const quote = paymentState.quote;
  const firstName = (contact || {}).firstName;
  const lastName = (contact || {}).lastName;
  const phone = mailing.business_phone;
  const [paymentPlan, setPaymentPlan] = useState("full");
  const [changingDate, setChangingDate] = useState(false);
  const payMonthly = () => setPaymentPlan("monthly");
  const payInFull = () => setPaymentPlan("full");

  const [address] = useState(mailing.mailing_address);
  const [city] = useState(mailing.mailing_city);
  const [addrState] = useState(mailing.mailing_state);
  const [zip] = useState(mailing.mailing_zip);

  const [differentBilling, setDifferentBilling] = useState(false);
  const [billingAddress, setBillingAddress] = useState("");
  const [billingAddress2, setBillingAddress2] = useState("");
  const [billingCity, setBillingCity] = useState("");
  const [billingState, setBillingState] = useState("");
  const [billingZip, setBillingZip] = useState("");
  const [tria, setTria] = useState(false);
  const [startDate, setStartDate] = useState(
    tomorrow().isAfter(quote.effectiveDate) ? tomorrow() : quote.effectiveDate
  );

  useEffect(initForm, [auth]);
  useEffect(() => {
    const selectedEffectiveDate = moment(startDate).format(dateFormat);
    if (selectedEffectiveDate !== quote.effectiveDate) {
      setChangingDate(true);
      fetch(`/api/quotes/${mailing.business_name}/${trymId}`, {
        method: "PATCH",
        headers: {
          accept: "application/json",
          "content-type": "application/json",
        },
        body: JSON.stringify({ effectiveDate: selectedEffectiveDate }),
      })
        .then((res) => {
          if (!res.ok)
            return res.json().then((body) => {
              throw { status: res.status, ...body };
            });
          return res.json();
        })
        .then((quote) => {
          paymentState.quote.effectiveDate = selectedEffectiveDate;
          localStorage.setItem("payment-state", JSON.stringify(paymentState));
          history.replace(window.location.pathname, paymentState);
          setChangingDate(false);
          //setQuote(quote)
        })
        .catch(() => setChangingDate(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, quote.effectiveDate, trymId, mailing.business_name]);

  if (!auth) return <Loading text="Almost done..." />;

  const disabled = !auth.user || changingDate || !!validationError;

  return (
    <div className="app q-flow">
      <Header>
        <TrymProgress step={4} />
        {/* <button className="header__button button blue tbd" disabled={false}>Live Chat</button> */}
      </Header>
      <div className="app__main">
        <div className="payment">
          <header>
            <h1>Payment time</h1>
            <h2>
              Get ready to breathe an "I'm covered" sigh of
              relief.
            </h2>
            <TrymProgress step={4} />
          </header>
          <div className="payment__main">
            <div className="payment__form">
              <PaymentCoverage quote={quote} />
              {auth.user && auth.user.email && (
                <div id="payment-login">
                  Signed in as {auth.user.email}
                  <PrimaryButton
                    className="extra small secondary"
                    onClick={logOut}
                  >
                    Log out
                  </PrimaryButton>
                </div>
              )}
              {!auth.user && (
                <div className="question-group">
                  <div>
                    <div className="question__title">Create your login</div>
                    <div className="question__desc">
                      You will use this to manage your insurance on web and
                      mobile devices.
                    </div>
                    <SignUp />
                  </div>
                </div>
              )}

              <div className="question-group" style={{ display: "flex" }}>
                <div style={{ flex: 3, paddingRight: "1rem" }}>
                  <div className="question__title">
                    <div>Do you want to remove terrorism coverage?</div>
                    <div>
                      {" "}
                      You'd save{" "}
                      <span style={{ color: "#a1cc37" }}>{dc(quote.triaPremium)}/mo</span>
                    </div>
                  </div>
                  <div className="question__desc">
                    We're required to include terrorism insurance (TRIA) in
                    every quote, but you can remove the coverage now before
                    purchasing. Changes will be reflected on your policy after
                    you purchase
                  </div>
                </div>
                <div style={{ flex: 2 }}>
                  <Toggle
                    value={tria}
                    onChange={(checked) => setTria(checked)}
                  />
                </div>
              </div>
            </div>
            <form action="/api/payments" method="post" id="payment-form">
              <div className="payment__form">
                <input type="hidden" name="quote" value={quote.name} />
                <input
                  type="hidden"
                  name="referralCode"
                  value={referralCode || ""}
                />
                <input type="hidden" name="paymentPlan" value={paymentPlan} />
                <fieldset className="payment__container">
                  {/* <div className="question-group" id="payment-login">
                    <div className="question__title">Create your login</div>
                    <div className="question__desc">You will use this to manage your insurance on web and mobile devices</div>
                    <div className="question"><input name="email" type="email" placeholder="first.last@gmail.com" value={email} onChange={onChange(setEmail)} /></div>
                    <div className="question"><input name="password" type="password" placeholder="Password" value={password} onChange={onChange(setPassword)} /></div>
                    <div className="question"><input name="passwordConfirm" type="password" placeholder="Confirm password" value={passwordConfirm} onChange={onChange(setPasswordConfirm)} /></div>
                  </div> */}
                  {/* <div className="question-group">
                    <div className="question__title">Confirm your contact information</div>
                    <div className="question__desc">We&apos;ll use the info to verify your business.</div>
                    <div className="question">
                      <div className="payment__row">
                        <input name="firstName" type="text" className="half" placeholder="First name" value={firstName} onChange={onChange(setFirstName)} />
                        <input name="lastName" type="text" className="half" placeholder="Last name" value={lastName} onChange={onChange(setLastName)} />
                      </div>
                    </div>
                    <div className="question"><input name="phone" type="text" placeholder="Phone number" value={phone} onChange={onChange(setPhone)} /></div>
                    <div className="question"><input name="address1" type="text" placeholder="Address" value={address} onChange={onChange(setAddress)} /></div>
                    <div className="question"><input name="city" type="text" placeholder="City" value={city} onChange={onChange(setCity)} className="payment__city" /></div>
                    <div className="question">
                      <div className="payment__row">
                        <input name="state" type="text" placeholder="State" value={addrState} onChange={onChange(setAddrState)} maxLength={2} className="payment__state" />
                        <input name="zip" type="text" placeholder="ZIP Code" value={zip} onChange={onChange(setZip)} maxLength={10} className="payment__zip" />
                      </div>
                    </div>
                  </div> */}
                  <input name="firstName" type="hidden" value={firstName} />
                  <input name="lastName" type="hidden" value={lastName} />
                  <input name="phone" type="hidden" value={phone} />
                  <input name="address1" type="hidden" value={address} />
                  <input name="city" type="hidden" value={city} />
                  <input name="state" type="hidden" value={addrState} />
                  <input name="zip" type="hidden" value={zip} />

                  <div className="question-group">
                    {quote.firstThreeMonths != null && (
                      <div className="question__title payment__plans-title">
                        Choose your payment plan
                      </div>
                    )}
                    <div className="payment__plans">
                      {quote.firstThreeMonths != null && (
                        <div
                          className={
                            paymentPlan === "monthly" ? "selected" : ""
                          }
                        >
                          <div className="payment__plan-title">OPTION 1</div>
                          <div className="payment__plan-desc">
                            Pay 3 months upfront,
                          </div>
                          <div className="payment__plan-desc">
                            then 9 monthly payments
                          </div>
                          <div className="payment__installment t">
                            {quote.triaPremium}
                            {dc(quote.firstThreeMonths - quote.triaPremium)} today
                            <div className="payment__installment-desc">
                              Includes taxes and fees
                            </div>
                          </div>
                          <div className="payment__installment t then">
                            Then {dc(quote.monthly)}/mo for 9 months
                          </div>
                          <PrimaryButton
                            type="button"
                            className={`small${
                              paymentPlan !== "monthly" ? " outline" : ""
                            }`}
                            onClick={payMonthly}
                            style={{ width: "100%" }}
                          >
                            Choose plan
                          </PrimaryButton>
                        </div>
                      )}
                      <div className={paymentPlan === "full" ? "selected" : ""}>
                        <div className="payment__plan-title">
                          {quote.firstThreeMonths != null ? "OPTION 2" : ""}
                        </div>
                        <div className="payment__plan-desc">Pay in full,</div>
                        <div className="payment__plan-desc">today</div>
                        <div className="payment__installment t">
                          { tria && `${dc(quote.fullPayment - quote.triaPremium )} today`  }
                          { !tria && `${dc(quote.fullPayment )} today`  }
                          <div className="payment__installment-desc">
                            Includes taxes and fees
                          </div>
                        </div>
                        <div className="payment__installment then">&nbsp;</div>
                        {quote.firstThreeMonths != null && (
                          <PrimaryButton
                            type="button"
                            className={`small${
                              paymentPlan !== "full" ? " outline" : ""
                            }`}
                            onClick={payInFull}
                            style={{ width: "100%" }}
                          >
                            Choose plan
                          </PrimaryButton>
                        )}
                      </div>
                    </div>
                  </div>
                  {auth.user ? (
                    <div className="question-group payment">
                      <div className="question__title">
                        Enter your payment method
                      </div>
                      <div className="question__desc">
                        You will be charged the amount indicated.
                      </div>
                      <div className="form-row">
                        <div id="card-element" />
                        <div id="card-errors" role="alert"></div>
                      </div>
                      <label className="payment__different-billing">
                        <input
                          name="differentBilling"
                          type="checkbox"
                          checked={differentBilling}
                          onChange={onCheckChange(setDifferentBilling)}
                        />
                        My billing address is different than above
                      </label>
                      {differentBilling && (
                        <div>
                          <div
                            className="question"
                            style={{ marginTop: "1rem" }}
                          >
                            <input
                              name="billingAddress1"
                              type="text"
                              placeholder="123 Somewhere Street"
                              value={billingAddress}
                              onChange={onChange(setBillingAddress)}
                            />
                          </div>
                          <div className="question">
                            <input
                              name="billingAddress2"
                              type="text"
                              placeholder="Suite number, etc."
                              value={billingAddress2}
                              onChange={onChange(setBillingAddress2)}
                            />
                          </div>
                          <div className="question">
                            <input
                              name="billingCity"
                              type="text"
                              placeholder="City"
                              value={billingCity}
                              onChange={onChange(setBillingCity)}
                              className="payment__city"
                            />
                          </div>
                          <div className="question">
                            <div className="payment__row">
                              <input
                                name="billingState"
                                type="text"
                                placeholder="State"
                                value={billingState}
                                onChange={onChange(setBillingState)}
                                maxLength={2}
                                className="payment__state"
                              />
                              <input
                                name="billingZip"
                                type="text"
                                placeholder="ZIP"
                                value={billingZip}
                                onChange={onChange(setBillingZip)}
                                maxLength={10}
                                className="payment__zip"
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null}
                  <div className="question-group">
                    <div className="question__title">
                      When would you like your coverage to begin?
                    </div>
                    <div className="question__desc">Choose a date below</div>
                    <StartDate
                      value={startDate}
                      onChange={setStartDate}
                      disabled={changingDate}
                    />
                  </div>
                  <button
                    type="submit"
                    className="payment__complete button blue"
                    disabled={disabled}
                  >
                    Purchase my policy
                  </button>
                  {validationError != null && (
                    <div className="payment__validation-error">
                      {validationError}
                    </div>
                  )}
                </fieldset>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );

  function logOut(event) {
    event.preventDefault();
    auth.signOut();
  }

  function initForm() {
    if (!auth || !auth.user) return;

    // Custom styling can be passed to options when creating an Element.
    const style = {
      base: {
        // Add your base input styles here. For example:
        fontSize: "16px",
        color: "#32325d",
      },
    };

    // Create an instance of the card Element.
    const card = elements.create("card", { style, hidePostalCode: true });

    // Add an instance of the card Element into the `card-element` <div>.
    card.mount("#card-element");

    card.addEventListener("change", (event) => {
      const displayError = document.getElementById("card-errors");
      if (event.error) {
        displayError.textContent = event.error.message;
        setValidationError(event.error.message);
      } else {
        displayError.textContent = "";
        setValidationError(null);
      }
    });

    // Create a token or display an error when the form is submitted.
    const form = document.getElementById("payment-form");
    function submitListener(event) {
      event.preventDefault();

      stripe.createToken(card).then((stripeResult) => {
        if (stripeResult.error) {
          // Inform the customer that there was an error.
          var errorElement = document.getElementById("card-errors");
          errorElement.textContent = stripeResult.error.message;
        } else {
          firebase
            .auth()
            .currentUser.getIdToken(/* forceRefresh */ true)
            .then((idToken) => {
              addFieldsAndSubmit({
                idToken,
                stripeToken: stripeResult.token.id,
                trymId,
                hasTria: tria
              });
            })
            .catch((e) => alert(e));
        }
      });
    }
    form.addEventListener("submit", submitListener);

    function addFieldsAndSubmit(fields) {
      // Insert the token ID into the form so it gets submitted to the server
      const form = document.getElementById("payment-form");
      Object.keys(fields).forEach((key) =>
        addHiddenInput(form, key, fields[key])
      );

      form.submit();
    }

    return function cleanup() {
      card.destroy();
      form.removeEventListener("submit", submitListener);
    };
  }
};

export default Payment;

const today = () =>
  moment()
    .utc()
    .add(-4, "hour")
    .set("hour", 0)
    .set("minute", 0)
    .set("second", 0)
    .set("millisecond", 0);
const tomorrow = () => today().add(1, "day");
const dateFormat = "YYYY-MM-DD";
const fullDateFormat = "dddd MMMM D, YYYY";
export const StartDate = ({ value, onChange, disabled }) => {
  value = moment(value);
  const valueString = value.format(dateFormat);
  const tomorrowString = tomorrow().format(dateFormat);
  const thiryDaysFromTomorrowString = tomorrow()
    .add(1, "month")
    .format(dateFormat);
  const [isTomorrow, setIsTomorrow] = useState(valueString === tomorrowString);

  const startTomorrow = () => {
    setIsTomorrow(true);
    onChange(tomorrowString);
  };
  const chooseFuture = () => {
    setIsTomorrow(false);
  };
  const onDateChange = (e) => {
    const val = e.currentTarget.value;
    if (val < tomorrowString || val > thiryDaysFromTomorrowString) {
      startTomorrow();
    } else {
      onChange(val);
    }
  };

  return (
    <div className="payment__start-date">
      <div className="payment__start-date-buttons">
        <PrimaryButton
          type="button"
          onClick={startTomorrow}
          className={isTomorrow ? "primary" : "gray"}
        >
          Start coverage tomorrow
        </PrimaryButton>
        <PrimaryButton
          type="button"
          onClick={chooseFuture}
          className={isTomorrow ? "gray" : "primary"}
        >
          Choose future date
        </PrimaryButton>
      </div>
      {!isTomorrow && (
        <input
          type="date"
          className="payment__start-date-cal"
          value={valueString}
          min={tomorrowString}
          max={thiryDaysFromTomorrowString}
          required
          onChange={onDateChange}
        />
      )}
      <div className="payment__start-date-text">
        Your coverage will begin {value.format(fullDateFormat)}.
      </div>
      <div className={`payment__start-date-mask${disabled ? " show" : ""}`}>
        <span>Updating and verifying...</span>
      </div>
    </div>
  );
};

function addHiddenInput(form, name, value) {
  const hiddenInput = document.createElement("input");
  hiddenInput.setAttribute("type", "hidden");
  hiddenInput.setAttribute("name", name);
  hiddenInput.setAttribute("value", value);
  form.appendChild(hiddenInput);
}

const dc = (x) =>
  x == null
    ? ""
    : x.toLocaleString("en-US", { style: "currency", currency: "USD" });

function useRestoreScroll() {
  const ref = useRef(Math.random());
  useEffect(() => {
    if (window.localStorage) {
      function setScroll() {
        window.localStorage.setItem("payment-scroll", window.scrollY);
      }
      let timer = null;
      function scrollListener() {
        clearTimeout(timer);
        timer = setTimeout(setScroll, 500);
      }
      window.addEventListener("scroll", scrollListener);

      return () => window.removeEventListener("scroll", scrollListener);
    }
  }, [ref.current]);

  useEffect(() => {
    if (window.localStorage) {
      const scrollPos = window.localStorage.getItem("payment-scroll");
      if (scrollPos && document.body.clientHeight >= +scrollPos) {
        window.localStorage.removeItem("payment-scroll");
        window.scrollTo(0, +scrollPos);
      }
    }
  }, [document.body.clientHeight]);
}
