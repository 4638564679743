import React, {Fragment} from 'react'
import InfoPage from './InfoPage'
import questionHeaderIcon from './img/question-header-icon.svg'
import switchIcon1 from './img/switch-icon-1.svg'
import switchIcon2 from './img/switch-icon-2.svg'
import switchIcon3 from './img/switch-icon-3.svg'

export default () => (
  <InfoPage className="page switch-to-trym-page" render={render} />
)

function render(openQuote) {
  return (
    <Fragment>
      <section className="page-header">
        <div className="regular-container text-center">
          <img src={questionHeaderIcon} height="55px" alt="" />
          <h1>Are you overpaying for your business insurance? Find out in 5 minutes.</h1>
          <button onClick={openQuote} className="button primary large-margin-bottom large-margin-top">Get a quote today</button>
          <p>Trym was built from the ground up to give small business owners the best coverage for less money. Spending five minutes comparing prices could save you money every month.</p>
        </div>
      </section>

      <section className="content">
        <div className="regular-container">

          <h2 className="large-margin-bottom large-margin-top">Why switch to Trym</h2>

          <div className="side-by-side-item ">
            <img src={switchIcon1} alt="" />
            <div>
              <h3 className="no-margin">Better coverage</h3>
              <p>If you get sued. Hacked. Your stuff breaks. A customer gets hurt at your office or in your store. An employee sues you for inappropriate HR. If a disaster leaves your business with no income. All this and more is included in your Trym coverage.</p>
            </div>
          </div>

          <div className="side-by-side-item">
            <img src={switchIcon2} alt="" />
            <div>
              <h3 className="no-margin">Less money</h3>
              <p>Bloated insurance companies charge higher premiums to cover their massive overhead. We’ve cut the fat. Lots of it. And passed the savings onto you.</p>
            </div>
          </div>

          <div className="side-by-side-item">
            <img src={switchIcon3} alt="" />
            <div>
              <h3 className="no-margin">Made for you</h3>
              <p>After a decade serving small businesses with insurance, our founder crafted Trym’s coverage exclusively for you, the entrepreneur who had the guts to pave their own way.</p>
            </div>
          </div>

          <div className="divider"></div>

          <h2 className="large-margin-bottom">How the switch works</h2>
          
          <div className="list-item p">
            <div className="list-number">1</div>Get a Trym policy in 5 minutes
          </div>
          <div className="list-item">
            <div className="list-number">2</div>Cancel with your current business insurance company
          </div>
          <div className="list-item">
            <div className="list-number">3</div>Ask for refunds if you were in the middle of your policy
          </div>
          <div className="list-item">
            <div className="list-number">4</div>Save with Trym, month after month after month
          </div>

        </div>
      </section>

      <section className="text-center cta-section">
        <div className="small-container">
          <h2 className="text-white">Compare your savings</h2>
          <p className="text-white large-margin-bottom">We’ll be honest. If your current provider offers you better coverage for less money, then stick with them. But, that’s a big if.</p>
          <button onClick={openQuote} className="button primary">Get a quote today</button>
        </div>
      </section>
    </Fragment>
  )
}
