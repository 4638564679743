import React, {useState, useContext} from 'react'
import { UserContext } from "./context";
import { Link } from 'react-router-dom'
import {useLocalState, getQuoteProgress, setQuoteProgress} from './utils'
import states from './config/states'
import Modal from 'react-modal'
import history from './history'
import './react-modal.css'
import logo from './images/trym-logo-black.svg'
import rightChevron from './images/right-chevron.svg'

import styled from 'styled-components'
import HamburgerToggleFactory from './HamburgerToggle'
import { PrimaryButtonLink } from './PrimaryButton'
import { gray, mobileMedia, shadow } from './styles'

const HomeNav = ({rootRef, isInfoModalOpen, setIsInfoModalOpen}) => {
  const openInfo = () => setIsInfoModalOpen(true)
  const closeInfo = () => setIsInfoModalOpen(false)

  return (
    <NavBar openInfo={openInfo}>
      <Modal
        isOpen={isInfoModalOpen}
        appElement={rootRef.current}
        onRequestClose={closeInfo}
        className="home__info"
        overlayClassName="home__info-overlay"
      >
        <Intro />
      </Modal>
    </NavBar>
  )
}

const Nav = styled.div`
  height: 4em;
  display: flex;
  align-items: center;
  padding: 0 1rem;

  ${mobileMedia} {
    display: none;
  }
`

const NavMobile = styled.div`
  display: none;
  ${mobileMedia} {
    display: flex;
  }

  padding: 0 10px;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
`

const NavLogo = styled.img`
  height: 2rem;
  margin: 0.2rem 2rem 0 0;
  padding-top: 0.2rem;
  ${mobileMedia} {
    margin-right: 0;
    height: 1.75rem;
  }
`
const Menu = styled.div`
  display: flex;
  &.opened, &.collapsed { display: none; }

  ${mobileMedia} {
    &.opened, &.collapsed { display: flex; }
    overflow: hidden;
    max-height: 50vh;

    &.collapsed {
      max-height: 0;
    }

    flex-direction: column;
    background-color: #f3f3f3;

    transition: max-height ease-in 0.5s;
  }
`
const MenuItemAnchor = styled.a`
  font-size: 0.8rem;
  display: flex;
  align-items: baseline;
  color: #a5a5a5;
  text-decoration: none;

  :not(:last-child) {
    margin-right: 2rem;
  }
`
const MenuItemAnchorMobile = styled.a`
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a5a5a5;
  text-decoration: none;

  &:first-child { padding-top: 1rem; }
  &:last-child { padding-bottom: 1rem; }
`
const RightChevron = styled.img`
  height: 0.6rem;
  margin: 0 0.5rem;
`
const QuoteNav = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  ${mobileMedia} {
    display: none;
  }
`

const GrayLink = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  color: ${gray};
  text-decoration: none;
  font-size: 0.8rem;
  margin: 1rem;
  &.wide { padding: 0 2.5rem; }
`

const MenuItem = ({title, href, auth}) => {
 
  if(auth && auth.user && href === "/login") {
    title = "Dashboard";
    href = "/dashboard"
  }
  return(
    <MenuItemAnchor href={href}>
      {title}
    </MenuItemAnchor>
  )
}

const MenuItemMobile = ({title, href}) => (
  <MenuItemAnchorMobile href={href}>
    <div>{title}</div><RightChevron src={rightChevron} />
  </MenuItemAnchorMobile>
)

const tailorUrl = '/tailor'
const Intro = () => {
  const [firstName, setFirstName] = useLocalState('contact-firstName', '')
  const [lastName, setLastName] = useLocalState('contact-lastName', '')
  const [email, setEmail] = useLocalState('contact-email', '')
  const [state, setState] = useLocalState('contact-state', '')

  const onFirstNameChange = e => setFirstName(e.target.value)
  const onLastNameChange = e => setLastName(e.target.value)
  const onEmailChange = e => setEmail(e.target.value)
  const onStateChange = e => setState(e.target.value)


  const buildingDefaults = {bpp: 1000, building_rebuild_cost: 1000, revenue: 0}
  const defaultAnswers = {
    business_age: 0,
    num_employees: 1,
    num_parttime_employees: 0,
    mailing: {},
    buildings: [buildingDefaults],
    cyber: false,
    professional: false,
    tria: true,
    salaries: 0,
    property_losses: 0,
    general_losses: 0,
    professional_losses: 0
  }

  const onOK = () => {
    const historyState = {contact: {firstName, lastName, email, state}, ...defaultAnswers }
    setQuoteProgress(tailorUrl, historyState)
    history.push(tailorUrl)
  }
  const disabled = !(firstName && lastName && email && state)

  return (
    <div>
      <ModalHeader>
        <h1>Let's get on a first name basis</h1>
      </ModalHeader>
      <ModalForm>
        <div>
          <ModalInput type="text" placeholder="First name" className="half" value={firstName} onChange={onFirstNameChange} />
          <ModalInput type="text" placeholder="Last name" className="half" value={lastName} onChange={onLastNameChange} />
        </div>
        <ModalInput type="text" placeholder="Email address" value={email} onChange={onEmailChange} />
        <ModalSelect className="select" value={state} onChange={onStateChange}>
          <option value="">State</option>
          { states.map(({value, key}) => (
            <option value={key} key={key}>{value}</option>
          )) }
        </ModalSelect>
        <Policies>
          <Link to="/terms-of-service" target="_blank">Terms of service</Link> | <Link to="/privacy-policy" target="_blank">Privacy policy</Link>
        </Policies>
        <ModalButton className="button home__info-button" onClick={onOK} disabled={disabled}>Start your quote</ModalButton>
      </ModalForm>
    </div>
  )
}

const menuLinks = [
  ["What's covered", "/whats-covered"],
  ["Who we insure", "/who-we-insure"],
  ["How claims work", "/how-claims-work"],
  ["Log in", "/login"]
]

const NavBar = ({children, openInfo}) => {
  const [opened, setOpened] = useState(false)
  const auth = useContext(UserContext);
  const quoteProgress = getQuoteProgress()
  const resumeQuote = () => {
    if (quoteProgress == null) return
    history.push(quoteProgress.url, quoteProgress.state)
  }

  return (
    <NavBarStyled>
      <Nav>
        <a href="/">
          <NavLogo src={logo} alt="trym logo" />
        </a>
        <Menu>
          {menuLinks.map((m,i) => <MenuItem key={i}  title={m[0]} href={m[1]} auth={auth} />)}
        </Menu>
        <QuoteNav>
          {quoteProgress != null && <GrayLink onClick={resumeQuote}>Continue your quote</GrayLink> }
          <PrimaryButtonLink className="small" onClick={openInfo}>Start your quote</PrimaryButtonLink>
        </QuoteNav>
      </Nav>
      <NavMobile>
        <Hamburger value={opened} onChange={setOpened} />
        <a href="/">
          <NavLogo src={logo} alt="trym logo" />
        </a>
         <PrimaryButtonLink className="extra small" onClick={openInfo}>Quote</PrimaryButtonLink>
      </NavMobile>
      <Menu className={opened ? 'opened' : 'collapsed'}>
        {menuLinks.map((m,i) => <MenuItemMobile key={i} title={m[0]} href={m[1]} />)}
      </Menu>
      {children}
    </NavBarStyled>
  )
}
const NavBarStyled = styled.div`
  line-height: normal;
`

const HamburgerToggle = HamburgerToggleFactory(22)
const Hamburger = ({value, onChange}) => (
  <MobileHamburger>
    <HamburgerToggle value={value} onChange={onChange} />
  </MobileHamburger>
)
const MobileHamburger = styled.div`
  padding: 10px;
  margin-right: 15px;
`

const ModalHeader = styled.div`
  padding: 15px;
  text-align: center;
  h1 {
    font-size: 125%;
    font-weight: normal;
    margin: 1em 0 0.25em 0;  
  }
`
const ModalForm = styled.form`padding: 20px;`
const ModalInput = styled.input`
  margin: 10px 0;
  background-color: #f8ffe7;
  padding: 15px;
  font-size: 90%;
  border-radius: 5px;
  &.half {
    display: inline-block;
    width: 48%;
    &:last-child {float: right;}
  }
`
const ModalSelect = styled.select`
  margin: 10px 0px;
  padding: 0px 11px;
  font-size: 90%;
  border-radius: 5px;
`


const ModalButton = styled.button`
  margin-top: 20px;
  width: 100%;
  background-color: #a1cc37;
`

const Policies = styled.div`
  margin-top: 0.7rem;
  color: gray;
  text-align: center;
  a, a:visited {
    color: gray;
  }
`

export default HomeNav

export const Top = styled.div`
  background-color: #fff;
  min-width: 800px;
  max-width: 1200px;
  margin: 0 auto;
  ${mobileMedia} {
    min-width: unset;
    max-width: unset;
  }
`
export const FinalStartQuote = ({onStart, quoteProgress, resumeQuote}) => (
  <FinalCTA>
    <FinalCTATitle>Let's get your small business insured</FinalCTATitle>
    <PrimaryButtonLink className="small" onClick={onStart}>Start your quote</PrimaryButtonLink>
    {quoteProgress != null && <GrayLink onClick={resumeQuote}>Continue your quote</GrayLink> }
  </FinalCTA>
)

const FinalCTA = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  position: absolute;
  bottom: -2rem;
  width: 800px;
  box-sizing: border-box;
  padding: 1rem;
  ${shadow};

  ${mobileMedia} {
    position: unset;
    bottom: unset;
    width: unset;
    box-shadow: unset;
  }
`
const FinalCTATitle = styled.h2`
  margin: 1rem 0;
  font-size: 2rem;
  color: #222;
  font-weight: 900;
  text-align: center;
  width: 400px;

  ${mobileMedia} {
    width: unset;
    font-size: 1.5rem;
  }
`