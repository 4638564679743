import React, { useState, useEffect } from 'react'
import { useParams, useLocation, Link } from 'react-router-dom'
import moment from 'moment'
import Cookies from 'js-cookie'
import Loading from '../../Loading'
import Header from '../../components/Header'
import greenCheckCircle from '../../img/green-check-circle.svg'
import certificateIconGreen from '../../img/certificate-icon-green.svg'
import businessInfoIconGreen from '../../img/business-info-icon-green.svg'
import claimIconGreen from '../../img/claim-icon-green.svg'
import billingIconGreen from '../../img/billing-icon-green.svg'
import Login from '../../Login'

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Policy = ({history, location, auth}) => {
  let { policyIndex } = useParams()
  let query = useQuery()
  policyIndex = +policyIndex || 0
  const [policies, setPolicies] = useState()
  const policy = policies && (policies[policyIndex] || policies[0])
  const [profile, setProfile] = useState()
  const [err, setError] = useState(null)

  const currentUser = auth && auth.user
  const signOut = () => {
    auth.signOut()
    history.replace('/')
  }

  const search = location.search || ''
  useEffect(() => {
    if (!currentUser) return
    currentUser.getIdToken().then(idToken => {
      Cookies.set('idToken', idToken)
      get(idToken, `/api/policies${search}`).then(policies => {
        if (query.get('pdf') && policy) {
          document.location.href = `/api/policies/${policy.quote.quoteId}/pdf${search}`
        } else {
          setPolicies(policies)
        }
      }).catch(setError)
      get(idToken, `/api/profile${search}`).then(profile => {
        if (profile == null) {
          signOut()
        }
        setProfile(profile)
      }).catch(setError)
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  if (err) {
    return <div>Error: {err.message}</div>
  }
  if (!currentUser) return <Login />
  if (!policies || !profile) return <Loading text="Loading your policy information..." />
  if (!policy) {
    history.replace(policyIndex === 0 ? '/tailor' : '/dashboard')
    return
  }

  return (
    <div className="page dashboard-homescreen">
      <Header location />

      <section className="green-hero-section">
        <div className="regular-container">

          <h1 className="welcome-message large-margin-bottom">
            <div className="welcome-message_line-1">Hello {profile.firstName || currentUser.displayName},</div>
            <div className="welcome-message_line-2 text-white"><img src={greenCheckCircle} alt="" />You are covered</div>
          </h1>

          <div className="quick-actions">
            <div className="quick-actions_label">Quick actions</div>
            <div className="row small-margin-top">
              <div className="col-1-3">
                <a className="quick-actions_box" href={`/api/policies/${policy.quote.quoteId}/pdf${search}`} target="_blank" rel="noopener noreferrer">
                  Download <br />my coverage
                </a>
              </div>
              <div className="col-1-3">
                <a className="quick-actions_box" href="/generate-certificate">
                  Generate a <br />new certificate
                </a>
              </div>
              <div className="col-1-3">
                <a className="quick-actions_box" href="/claims">
                  File a claim
                </a>
              </div>
            </div>
          </div>

        </div>
      </section>

      <section className="main-content consumer-dashboard-hub" key={policy.quote.quoteId}>
        <div className="regular-container">

          <div className="coverage-recap large-margin-bottom">
            <div className="coverage-recap_left">
              <h2>My insurance coverage</h2>
              <p>Review and/or change your current policy, limits, deductible and premium.</p>
              <a href={`/coverage/${policyIndex}`} className="uppercase-link">View coverage</a>
            </div>
            <div className="coverage-recap_right">
              <div className="coverage-recap_right_table-row">
                <div className="coverage-recap_right_table-row_left small-margin-top"><h2 className="text-white no-margin">Trym {policy.bundleName}</h2></div>
                <div className="coverage-recap_right_table-row_right"><p className="small-margin-top">{moneyFormat(policy.quote.monthlyPremium)}/mo
                {policy.quote.tria && <div className="text-medium">{moneyFormat(policy.quote.termPremium)} yearly</div>}
                {!policy.quote.tria && <div className="text-medium">{moneyFormat(policy.quote.fullPayment-policy.quote.triaPremium)} yearly</div>}
                </p>
                </div>
              </div>
              <div className="coverage-recap_right_table-row">
                <div className="coverage-recap_right_table-row_left"><p><b>Effective Date</b></p></div>
                <div className="coverage-recap_right_table-row_right small-margin-top text-medium"><p>{dateFormat(policy.quote.effectiveDate)}</p></div>
              </div>
              <div className="coverage-recap_right_table-row">
                <div className="coverage-recap_right_table-row_left"><p><b>Expiration Date</b></p></div>
                <div className="coverage-recap_right_table-row_right small-margin-top text-medium "><p>{dateFormat(policy.quote.expirationDate)}</p></div>
              </div>
            </div>
          </div>
          
          <div className="main-content_box">
            <div className="consumer-dashboard-hub_item">
              <div className="consumer-dashboard-hub_item_icon">
                <img src={certificateIconGreen} alt="" />
              </div>
              <div className="consumer-dashboard-hub_item_name">
                <h2>Certificate of insurance</h2>
                <p>Share proof of insurance with your landlord, client or any other third-party.</p>
              </div>
              <div className="consumer-dashboard-hub_item_links">
                <Link to="/generate-certificate" className="uppercase-link">New certificate</Link>
                <Link to="/generate-certificate" className="uppercase-link">View all certificates</Link>
              </div>
            </div>

            <div className="consumer-dashboard-hub_item large-margin-top">
              <div className="consumer-dashboard-hub_item_icon">
                <img src={businessInfoIconGreen} alt="" />
              </div>
              <div className="consumer-dashboard-hub_item_name">
                <h2>Business information</h2>
                <p>Update or review your contact and billing information for <span><b>{policy.quote.insuredName}</b></span></p>
              </div>
              <div className="consumer-dashboard-hub_item_links">
                <Link to="/business-information" className="uppercase-link">View information</Link>
              </div>
            </div>

            <div className="consumer-dashboard-hub_item large-margin-top">
              <div className="consumer-dashboard-hub_item_icon">
                <img src={claimIconGreen} alt="" />
              </div>
              <div className="consumer-dashboard-hub_item_name">
                <h2>Claims</h2>
                <p>File or check on an insurance claim status</p>
              </div>
              <div className="consumer-dashboard-hub_item_links">
                <a href="/claims" className="uppercase-link">File claim</a>
                <a href="/claims" className="uppercase-link">See all claims</a>
              </div>
            </div>

            <div className="consumer-dashboard-hub_item large-margin-top">
              <div className="consumer-dashboard-hub_item_icon">
                <img src={billingIconGreen} alt="" />
              </div>
              <div className="consumer-dashboard-hub_item_name">
                <h2>Billing history</h2>
                <p>View and download your previous bills</p>
              </div>
              <div className="consumer-dashboard-hub_item_links">
                <Link to="/billing-history" className="uppercase-link">View bills</Link>
              </div>
            </div>
          </div>

          <div className="support-box">
            <div className="support-box_text">
              <h2 className="text-white">Support</h2>
              <p className="text-white">For any additional help, please send us an email. A fellow human will respond to you.</p>
            </div>
            <div className="support-box_actions">
              <a href="mailto:help@trym.co" className="support-box_actions_email-adress">help@trym.co</a>
            </div>
          </div>

        </div>
      </section>

      <footer>
        <div className="regular-container">
          <p className="copyright">
            <span className="copyright_entity">&copy; {new Date().getFullYear()} Trym Inc. </span>
            <a href="/terms-of-service" className="copyright_link">Terms of service</a>
            <a href="/privacy-policy" className="copyright_link">Privacy policy</a>
          </p>
        </div>
      </footer>

    </div>
  )
}

export default Policy;

const dateFormat = x => moment(x, 'YYYY-MM-DD').format('MMMM Do, YYYY');

const moneyFormat = x => `$${x.toLocaleString()}`
function get(idToken, url) {
  return fetch(url, {
    method: 'GET',
    headers: {accept: 'application/json', authorization: `Bearer ${idToken}`}
  }).then(res => {
    if (!res.ok) return res.json().then(body => {
      throw Error(JSON.stringify(body))
    }).catch(() => {
      if (res.status === 404) {
        return null
      } else {
        throw Error(res.status)
      }
    })
    return res.json()
  })
}