import React, { PureComponent } from 'react'

export default class Calculated extends PureComponent {
  render() {
    const {rootAnswers, question} = this.props
    return (
      <div className="calculated">
        {question.value(rootAnswers)}
      </div>
    )
  }
}
