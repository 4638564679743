import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import firebase from "firebase/app";

import api from "../../api/index";
import Loading from "../../Loading";
import { UserContext } from "../../context";
import DashboardFooter from "../../components/DashboardFooter";
import FormError from "../../components/FormError";
import Alert from "../../components/Alert";
import Header from "../../components/Header";
import "../../stripe.css";

import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

export default ({ history, location, auth }) => {
  const stripe = useStripe();
  const elements = useElements();
  const userContext = useContext(UserContext);
  const currentUser = userContext && userContext.user;
  const [userEmail, setUserEmail] = useState("");
  const [profileInfo, handleChange] = useState({
    companyName: "",
    firstName: "",
    lastName: "",
    city: "",
    state: "",
    zip: "",
    address1: "",
    address2: "",
    phone: ""
  });
  const [policies, setPolicies] = useState();
  const [editPayment, setEditPayment] = useState(false);
  const [differentBilling, setDifferentBilling] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errors, setError] = useState({
    email: "",
    requiresLogin: false,
    genericMessage: "",
    companyName: "",
    firstName: "",
    lastName: "",
    address1: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
  });


  useEffect(() => {
    if (!currentUser) return;

    async function getData() {
      const policies = await api.get(userContext.token, `/api/policies`);
      setPolicies(policies);
      handleChange({
        companyName: policies[0].quote.insuredName || "",
        firstName: userContext.profile.firstName || "",
        lastName: userContext.profile.lastName || "",
        city: userContext.profile.city || "",
        state: userContext.profile.state || "",
        zip: userContext.profile.zip || "",
        address1: userContext.profile.address1 || "",
        address2: userContext.profile.address2 || "",
        phone: userContext.profile.phone || "",
      });
      setUserEmail(currentUser.email);
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  if (!policies || !userContext.profile) {
    return <Loading text="Loading your policy information..." />;
  }

  const handleEditPayment = (e) => setEditPayment(!editPayment);
  const handleResetPassword = (e) => {
    e.preventDefault();
    try {
      firebase.auth().sendPasswordResetEmail(currentUser.email);
      setSuccessMessage("A password reset link was sent to your email");
    } catch (e) {
      console.error(e);
    }
  };

  const validate = () => {
    let hasError = false;
    const formErrors = {};
    if (userEmail === "" || profileInfo.email) {
      hasError = true;
      formErrors.email = "Email is required";
    }

    if (profileInfo.companyName === "") {
      hasError = true;
      formErrors.companyName = "Company name is required";
    }

    if (profileInfo.firstName === "") {
      hasError = true;
      formErrors.firstName = "First name is required";
    }

    if (profileInfo.lastName === "") {
      hasError = true;
      formErrors.lastName = "Last name is required";
    }

    if (profileInfo.address1 === "") {
      hasError = true;
      formErrors.address1 = "Address is required";
    }

    if (profileInfo.state === "") {
      hasError = true;
      formErrors.state = "State is required";
    }

    if (profileInfo.zip === "") {
      hasError = true;
      formErrors.zip = "Postal code is required";
    }

    if (profileInfo.phone === "") {
      hasError = true;
      formErrors.phone = "Phone is required";
    }

    setError(formErrors);
    return hasError;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    // TODO: SERVER SIDE VALIDATION
    // HAS ERROR
    if (validate()) {
      return;
    }

    const billingDetails = {
      name: `${profileInfo.firstName} ${profileInfo.lastName}`,
      address: {
        city: differentBilling ? e.target.billingCity.value : profileInfo.city,
        line1: differentBilling
          ? e.target.billingAddress1.value
          : profileInfo.address1,
        line2: differentBilling
          ? e.target.billingAddress2.value
          : profileInfo.address2,
        state: differentBilling
          ? e.target.billingState.value
          : profileInfo.state,
        postal_code: differentBilling
          ? e.target.billingZip.value
          : profileInfo.zip,
      },
    };

    let paymentInfo = {};
    if (editPayment) {
      paymentInfo = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
        billing_details: billingDetails,
      });

      if (paymentInfo.error) {
        console.error(paymentInfo.error.message);
      }
    }

    if (userEmail !== currentUser.email) {
      try {
        await firebase.auth().currentUser.updateEmail(userEmail);
      } catch (error) {
        console.error(error);
        switch (error.code) {
          case "auth/requires-recent-login":
            setError({
              ...errors,
              email: "Please login again before retrying this request",
              requiresLogin: true,
            });
            break;
          case "auth/user-not-found":
            setError({ ...errors, email: "User is not found" });
            break;
          case "auth/user-disabled":
            setError({ ...errors, email: "User is not active" });
            break;
          default:
            setError({ ...errors, email: error.message });
            break;
        }
        return;
      }
    }

    const data = {
      profile: profileInfo,
      ...paymentInfo,
    };

    try {
      await api.put(
        userContext.token,
        `/api/profile/${currentUser.uid}`,
        JSON.stringify(data)
      );
      setSuccessMessage("Business information updated successfully!");
    } catch (error) {
      console.error(error);
      setError({ ...errors, genericMessage: error });
    }

    return;
  };

  const iframeStyles = {
    base: {
      color: "#666C73",
      fontSize: "20px",
      iconColor: "#fff",
    },
    invalid: {
      iconColor: "##fa755a",
      color: "##fa755a",
    },
    complete: {
      iconColor: "#A1CC37",
    },
  };

  const cardElementOpts = {
    iconStyle: "solid",
    style: iframeStyles,
    hidePostalCode: true,
  };

  return (
    <div className="page">
      <Header location={location} />

      <section className="green-hero-section">
        <div className="regular-container">
          <h1 className="page-title text-center">Business information</h1>
        </div>
      </section>

      <section className="main-content">
        <form onSubmit={handleFormSubmit}>
          <div className="regular-container">
            <div className="main-content_utilities">
              <div className="back-button" onClick={() => history.goBack()}>
                Go back
              </div>
            </div>
            <div className="main-content_box">
              <h2>Review or update your information</h2>
              <div className="divider line"></div>
              <h3>Your account</h3>
              <input
                type="email"
                name="email"
                placeholder="Email"
                className={errors.email ? "error" : ""}
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />

              {errors.email && (
                <span role="alert" className="error-text signup__error">
                  {errors.email}
                  {errors.requiresLogin && (
                    <Link
                      className="green-link"
                      to={{
                        pathname: "/login",
                        state: {
                          forceLogin: true,
                          returnUrl: "/business-information",
                        },
                      }}
                    >
                      Click here to Login
                    </Link>
                  )}
                </span>
              )}
              <div>
                <button className="green-link" onClick={handleResetPassword}> Send Password Reset Link</button>
              </div>

              <h3 className="large-margin-top">Your business information</h3>
              <input
                type="text"
                readOnly
                name="companyName"
                placeholder="Company Name"
                value={profileInfo.companyName}
                className={errors.companyName ? "error" : ""}
                onChange={(e) =>
                  handleChange({
                    ...profileInfo,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <FormError error={errors.companyName} />
              <div className="row">
                <div className="col-1-2">
                  <input
                    type="text"
                    name="firstName"
                    placeholder="First Name"
                    className={errors.firstName ? "error" : ""}
                    value={profileInfo.firstName}
                    onChange={(e) =>
                      handleChange({
                        ...profileInfo,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  <FormError error={errors.firstName} />
                </div>
                <div className="col-1-2">
                  <input
                    type="text"
                    name="lastName"
                    placeholder="Last Name"
                    className={errors.lastName ? "error" : ""}
                    value={profileInfo.lastName}
                    onChange={(e) =>
                      handleChange({
                        ...profileInfo,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  <FormError error={errors.lastName} />
                </div>
              </div>
              <input
                type="phone"
                name="phone"
                placeholder="(+1) 415-466-0000"
                value={profileInfo.phone}
                className={errors.phone ? "error" : ""}
                onChange={(e) =>
                  handleChange({
                    ...profileInfo,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <FormError error={errors.phone} />
              <input
                type="text"
                name="address1"
                placeholder="Business Address"
                className={errors.address1 ? "error" : ""}
                value={profileInfo.address1}
                onChange={(e) =>
                  handleChange({
                    ...profileInfo,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <FormError error={errors.address1} />
              <input
                type="text"
                name="address2"
                placeholder="Address 2"
                value={profileInfo.address2}
                onChange={(e) =>
                  handleChange({
                    ...profileInfo,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <input
                type="text"
                name="city"
                placeholder="City"
                className={errors.city ? "error" : ""}
                value={profileInfo.city}
                onChange={(e) =>
                  handleChange({
                    ...profileInfo,
                    [e.target.name]: e.target.value,
                  })
                }
              />
              <FormError error={errors.city} />
              <div className="row">
                <div className="col-1-2">
                  <div className="select-style">
                    <select
                      name="state"
                      className={errors.state ? "error" : ""}
                      value={profileInfo.state}
                      onChange={(e) =>
                        handleChange({
                          ...profileInfo,
                          [e.target.name]: e.target.value,
                        })
                      }
                    >
                      <option>CA</option>
                      <option>NJ</option>
                      <option>AZ</option>
                      <option>NV</option>
                      <option>ID</option>
                      <option>MN</option>
                    </select>
                  </div>
                  <FormError error={errors.state} />
                </div>
                <div className="col-1-2">
                  <input
                    type="number"
                    name="zip"
                    placeholder="Postal Code"
                    className={errors.zip ? "error" : ""}
                    value={profileInfo.zip}
                    onChange={(e) =>
                      handleChange({
                        ...profileInfo,
                        [e.target.name]: e.target.value,
                      })
                    }
                  />
                  <FormError error={errors.zip} />
                </div>
              </div>

              <h3 className="large-margin-top">Payment method</h3>

              {!editPayment &&
                userContext.profile.paymentOptions.map((x) => {
                  return (
                    <div key={x}>
                      <div className="row">
                        <input
                          type="text"
                          name="company-card-number"
                          value={`XXXX - XXXX - XXXX - ${x.card.last4}`}
                          disabled
                        />
                        <input
                          type="text"
                          name="company-card-exp-date"
                          value={`${x.card.exp_month}/${x.card.exp_year}`}
                          disabled
                        />
                      </div>
                      <button className="green-link" onClick={handleEditPayment}> Edit</button>
                    </div>
                  );
                })}

              {editPayment && (
                <div>
                  <CardElement options={cardElementOpts} />
                  <button className="green-link" onClick={handleEditPayment}>Cancel</button>
                </div>
              )}

              <p>
                <input
                  type="checkbox"
                  name="billing-address-is-different"
                  id="billing-address-is-different"
                  onChange={() => setDifferentBilling(!differentBilling)}
                />
                <label htmlFor="billing-address-is-different">
                  My billing address is different than above
                </label>
              </p>

              {differentBilling && (
                <div>
                  <div className="question" style={{ marginTop: "1rem" }}>
                    <input
                      name="billingAddress1"
                      type="text"
                      placeholder="123 Somewhere Street"
                    />
                  </div>
                  <div className="question">
                    <input
                      name="billingAddress2"
                      type="text"
                      placeholder="Suite number, etc."
                    />
                  </div>
                  <div className="question">
                    <input
                      name="billingCity"
                      type="text"
                      placeholder="City"
                      className="payment__city"
                    />
                  </div>

                  <div className="row">
                    <div className="col-1-2">
                      <input
                        name="billingState"
                        type="text"
                        placeholder="State"
                        maxLength={2}
                        className="payment__state"
                      />
                    </div>
                    <div className="col-1-2">
                      <input
                        name="billingZip"
                        type="text"
                        placeholder="ZIP"
                        maxLength={10}
                        className="payment__zip"
                      />
                    </div>
                  </div>
                </div>
              )}

              <div className="text-center large-margin-top">
                <button type="submit" className="button primary">
                  Save and update
                </button>
              </div>
              {errors.genericMessage && (
                <Alert type="error" message={errors.genericMessage} />
              )}
              {successMessage && (
                <Alert type="success" message={successMessage} />
              )}
            </div>

            <div className="support-box">
              <div className="support-box_text">
                <h2 className="text-white">Support</h2>
                <p className="text-white">
                  For any additional help, pelase send us an email, a fellow
                  human will respond to you.
                </p>
              </div>
              <div className="support-box_actions">
                <a
                  href="mailto:help@trym.co"
                  className="support-box_actions_email-adress"
                >
                  help@trym.co
                </a>
              </div>
            </div>
          </div>
        </form>
      </section>
      <DashboardFooter />
    </div>
  );
};
