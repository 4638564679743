import React from 'react'
import {TitledInfoPage} from './InfoPage'

const TermsOfService = () => (
  <TitledInfoPage className="terms-of-service" title="Terms of service">
    <p className="tbd">Last Revised: February 1, 2021</p>
    <div className="divider"></div>

    <article>
      <p>These Terms of Use (“Terms of Use”) govern your use of and access to the website accessible at trym.co (“Site”). By accessing or using the Site, or by otherwise accepting these Terms of Use, you (an individual or entity) agree to the terms and conditions set forth in these Terms of Use. If you violate or do not agree to these Terms of Use, your access to and use of the Site is unauthorized.</p>
      <p>These Terms of Use may be changed at any time and notice of such changes shall be given by posting updated Terms of Use on the Site.  Your continued use of the Site thereafter constitutes acceptance of the updated Terms of Use.</p>
    </article>
    
    <article>
      <h3>Definitions</h3>
      <p> “Trym” means Trym, Inc. and any subsidiaries and affiliates of Trym (including any that Trym may form or acquire in the future). We also refer to Trym as 'we', 'us' and 'our'.</p>
      <p>“Site” means the website at www.trym.co.</p>
      <p>When we say “you” or “your” we mean users of the Site.</p>
    </article>

    <ol type="1">
      <li>
        <h3>License</h3>
        <p>Trym grants you a limited, revocable, license (“License”) to access and use the Site pursuant to these Terms of Use.</p>
        <p>If you are accessing the Site as an employee or agent, you acknowledge that you are extending the permanent consent and authorization described above on behalf of your employer or principal, and you represent that you have authority from that employer or principal to do so.</p>
        <p>If you are accessing the Site as an employer or principal, you authorize your employee or agent to extend on your behalf the permanent consent and authorization described above, and you acknowledge that your permanent consent and authorization will bind all of your employees and agents.</p>
      </li>
      <li>
        <h3>Account Registration</h3>
        <p>To access certain features of the SIte, you must register for an account.  You must be at least 18 years old to register for an account.</p>
        <p>You agree that all of your registration information will be true and complete, and you will keep your account information current and up-to-date. You agree to keep your Trym username and password confidential, and to access the Site from devices that have up-to-date operating systems and security software. You agree not to allow anybody else to use your account, including in any way that is meant to circumvent these Terms of Use. You will be responsible for all purchases made, and anything else that occurs, through your account.</p>
        <p>Please notify us immediately of any suspected unauthorized activity on your account.</p>
      </li>
      <li>
        <h3>Orders</h3>
        <p>General.  Certain insurance policies, services and other items (collectively “Items”) may be bound, purchased and/or delivered through the Site.  Any such purchases (“Orders”) shall be governed by the purchase terms of the applicable transaction.  The delivery of Items through the Site shall be subject to these Terms of Use, which shall not limit the terms of any Order purchase terms.</p>
        <p>Trym’s policies are backed by Crum & Forster, whose parent company is Fairfax Financial Holding Limited.</p>
        <p>Pricing or Other Errors.  Pricing errors and other errors occasionally occur on the Site. We reserve the right to cancel any orders containing pricing errors or other errors at any time without further obligation to you, including after you have received a confirmation of your order.</p>
        <p>In addition, if any Item you request becomes unavailable on the Site, we may cancel your order.</p>
        <p>Taxes.  Items sold on the Site may be subject to tax. Prior to submitting your purchase request, you will see an estimate of the tax to be collected on your order. This tax amount will depend on various factors, including the items purchased and the shipment destination.</p>
      </li>
      <li>
        <h3>Privacy</h3>
        <p>You consent to the collection, use, disclosure and other handling of information as described in our Privacy Policy, which may be updated from time to time.</p>
      </li>
      <li>
        <h3>Use of Materials on the Site</h3>
        <p>All content on the Site (including, for example, text, designs, graphics, logos, icons, images, audio clips, downloads, interfaces, information, code and software, and the selection and manner of compilation and presentation), is owned by Trym, other content providers (such as Trym’s insurance partners or suppliers) or their licensors, and may be protected by copyright, trademark, and other applicable laws.</p>
        <p>Your access to and use of the Site does not grant you any license or right to use any trademark, logo, or service mark displayed on the Site. Trym, other content providers, or their licensors retain full and complete title to and reserve all rights in the material on the Site, including all associated intellectual property rights. Trym neither warrants nor represents that your use of materials on the Site will not infringe rights of third parties.</p>
        <p>Any other use of the material on the Site, including, without limitation, the modification, distribution, transmission, performance, publication, uploading, licensing, reverse engineering, transfer, or sale of, or the creation of derivative works from, any material, information, software, products, or services obtained from the Site, or use of the Site for purposes competitive with Trym, or for other commercial purposes, is expressly prohibited. You agree to abide by all additional restrictions displayed on the Site as they may be updated from time to time.</p>
      </li>
      <li>
        <h3>Content You Submit</h3>
        <p>You may create, provide or upload certain content (“Content”) on your account profile or other parts of the Site.  By providing or uploading Content, You (a) represent that (i) the Content is consistent with the Acceptable Use provision below, (ii) is accurate, and (iii) you have proper ownership or license to grant Trym the following license to use Content; (b) grant to Trym a worldwide, irrevocable, perpetual, royalty-free license to display, reproduce, and use the Content in the provision of Service to You and others.  We will treat Content provided by You in accordance with our Privacy Policy, which is hereby incorporated by reference.</p>
      </li>
      <li>
        <h3>Acceptable Use</h3>
        <p>As a condition of your license to use and access the Site, you agree that you will not do any of the following:</p>
        <ul>
          <li>Use the Site if you are under the age of 18 or otherwise unable to enter into binding legal agreements;</li>
          <li>Share your account or password with others;</li>
          <li>Use the Site unlawfully or for an unlawful purpose;</li>
          <li>Transmit any Content that is illegal, abusive, harassing, racist, or which constitutes hate speech;</li>
          <li>Stalk, harass, or harm another individual;</li>
          <li>Impersonate another, misrepresent or falsely identify yourself;</li>
          <li>Use any device, software or routine to interfere or attempt to interfere with the proper working of the Site;</li>
          <li>Take any action that imposes an unreasonable or disproportionately large load on Trym's servers;</li>
          <li>Use any robot, spider or other automatic device, or a manual process, to collect or harvest information about users or products for sale.</li>
        </ul>
      </li>
      <li>
        <h3>Limitation of Liability</h3>
        <p>IN NO EVENT SHALL TRYM, ITS OFFICERS, DIRECTORS, AGENTS, AFFILIATES, EMPLOYEES, ADVERTISERS, OR INSURANCE PARTNERS BE LIABLE FOR ANY INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR PUNITIVE DAMAGES (INCLUDING BUT NOT LIMITED TO LOSS OF USE, LOSS OF PROFITS, OR LOSS OF DATA) WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE), EQUITY OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF THIS SITE OR THE PRODUCTS WE SELL ON THE SITE. Some jurisdictions do not allow the exclusion or limitation of liability, so the above limitations may not apply to you.</p>
      </li>
      <li>
        <h3>No Warranty</h3>
        <p>THE SITE AND ANY PRODUCT MADE AVAILABLE TO YOU THROUGH THE SITE IS PROVIDED “AS IS”. TRYM MAKES NO WARRANTIES, EXPRESSED OR IMPLIED, AND HEREBY DISCLAIMS AND NEGATES ALL WARRANTIES, INCLUDING WITHOUT LIMITATION, IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT OF INTELLECTUAL PROPERTY OR OTHER VIOLATION OF RIGHTS. FURTHERMORE, TRYM DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS CONCERNING THE ACCURACY, LIKELY RESULTS, OR RELIABILITY OF THE SITE.</p>
      </li>
      <li>
        <h3>Indemnification</h3>
        <p>You agree, to the fullest extent permitted by applicable law, to indemnify, defend, and hold harmless Trym and its subsidiaries and affiliates, and as to each, their officers, directors, employees, contractors, agents, licensors, service or third party providers, subcontractors, and suppliers, from and against any and all losses, liabilities, expenses, damages, and costs, including reasonable attorneys’ fees and court costs, arising out of or relating to your access to or use of the Site, any content you post or submit to the Site, your violation of law or infringement of the rights of a third party, or your other violation of these Terms of Use. Trym reserves the right, at its own expense, to assume exclusive defense and control of any matter otherwise subject to indemnification by you and, in any case, you agree to cooperate with Trym if and as requested by Trym in the defense of such matter.</p>
      </li>
      <li>
        <h3>Termination by Trym</h3>
        <p>We may close your account at any time in our sole discretion and without notice to you. This means we can close (or suspend) your account if we believe you are using the Site in a manner not intended by us, or in a way that circumvents these Terms of Use, or is contrary to the spirit of any activity on the Site (like a promotion or contest). In no case will Trym’s closure of your account, or reduction of your access to the Site, waive or affect any other right or relief to which Trym may be entitled.</p>
      </li>
      <li>
        <h3>Miscellaneous</h3>
        <ol type="a">
          <li>No Waiver. Trym's failure to exercise or enforce any right or provision of the Terms of Use will not be deemed to be a waiver of such right or provision.</li>
          <li>Entire Agreement. These Terms of Use, together with the Privacy Policy and any amendments and additional agreements you might enter with Trym in connection with our Site, shall constitute the entire agreement between you and Trym concerning the Site, and shall supersede any prior terms you had with Trym regarding the Site.</li>
          <li>Severability. If any provision of these Terms of Use is deemed invalid, then that provision will be limited or eliminated to the minimum extent necessary, and the other provisions of these Terms of Use shall remain in full force and effect.</li>
          <li>Limitation of Claims. Regardless of any statute or law to the contrary, any claim or cause of action arising out of or related to your use of the Site must be filed within one (1) year after such claim or cause of action arose, or else that claim or cause of action will be barred forever.</li>
          <li>Choice of Law. The Terms of Use shall be governed by the laws of the State of Arizona without regard to its conflict of law provisions.</li>
          <li>Arbitration. Any controversy or claim arising out of or relating to these Terms of Use or the Site will be settled by binding arbitration in accordance with the commercial arbitration rules of the American Arbitration Association ("AAA"). Any such controversy or claim must be arbitrated on an individual basis, and must not be consolidated in any arbitration with any claim or controversy of any other party. The arbitration must be conducted in the County of Maricopa, Arizona, and judgment on the arbitration award may be entered into any court having jurisdiction thereof. Either Trym or you may seek any interim or preliminary relief from a court of competent jurisdiction in the County of Maricopa, Arizona, as necessary to protect the rights or property of you or Trym.</li>
        </ol>
      </li>
      <li>
        <h3>DMCA Compliance Information</h3>
        <p>Trym does not knowingly permit anyone to post on the Site materials that infringe on the intellectual property rights of third parties. If you believe that your work has been copied and is available on this Site in a way that constitutes copyright infringement, please provide our designated agent (named below) with a notice that includes substantially the following information:</p>
        <p>(1) a physical or electronic signature of the copyright owner or a person authorized to act on their behalf;</p>
        <p>(2) identification of the copyrighted work claimed to have been infringed;</p>
        <p>(3) identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit us to locate the material;</p>
        <p>(4) your contact information, including your address, telephone number, and email address;</p>
        <p>(5) a statement by you that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; and</p>
        <p>(6) a statement that the information in the notification is accurate, and, under penalty of perjury, that you are authorized to act on behalf of the copyright owner.</p>
        <p>Upon receipt of a notice meeting the above requirements, we will respond in accordance with the Digital Millennium Copyright Act to remove or disable access to the material claimed to be infringing or to be the subject of infringing activity. We will also terminate account access by repeat infringers where we deem appropriate. Our designated agent for notices concerning copyright infringement is:
          <address>
            Scott Bradley <br />
            Trym
            18440 N 68th St #4040 <br />
            Phoenix, AZ 85054 <br />
            <a href="mailto:help@trym.co">help@trym.co</a>
          </address>
        </p>
      </li>
    </ol>
  </TitledInfoPage>
)

export default TermsOfService