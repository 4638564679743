import React, { Fragment, useEffect, useState } from "react";
import { useLocalState, useDebounce, setQuoteProgress } from "../../utils";
import { quoteDefaultAnswers, buildingDefaults } from '../../utils/quoteDefaults';
import Header from "../../Header";
import PrimaryButton from "../../PrimaryButton";
import TrymProgress from "../../components/TrymProgress";
import { sendAnswers } from "../../api";
import {
  SelectLookup,
  IntervalSlider,
  Slider,
  Select,
  Text,
  Statement,
  AddMoreQuestionGroup,
  Toggle,
} from "../../questions/questions";
import states from "../../config/states";
import businessTypes from "../../config/businessTypes";
import classCodeLookup from "../../config/classCodeLookup";
import Alert from "../../components/Alert";

const trymUrl = "/trym";
// min={0} max={501000} step={1000}
// 1-10k: $1000 intervals, 10 possible values
// 10k-50k: $2500 intervals, 16 possible values
// 50k-100k: $5000 intervals, 10 possible values
// 100k-500k: $25,000 intervals, 16 possible values

const bppIntervals = [
  { min: 1000, max: 10000, step: 1000 },
  { min: 10000, max: 50000, step: 2500 },
  { min: 50000, max: 100000, step: 5000 },
  { min: 100000, max: 525000, step: 25000 },
];

const TailorQuestions = ({ history }) => {
  const [answers, setAnswers] = useLocalState(
    "quote-progress",
    {
      state: { ...quoteDefaultAnswers },
      url: "/tailor"
    },
    null
  );
  // if the user has come here without an local storage, they need to start over. 
  if (!answers || !answers.state.contact || !answers.state.contact.email) document.location.href = "/";

  const [errors, setError] = useState({
    fields: {
      buildings: {},
      industryType: "",
      businessType: "",
      contactTitle: "",
      businessName: "",
      mailingAddress: "",
    },
    message: "",
  });

  if (
    answers.state.contact &&
    answers.state.mailing &&
    !answers.state.mailing.mailing_state
  ) {
    answers.state.mailing.mailing_state = answers.state.contact.state; // copy over the modal answers
  }

  function nextStep() {
    if (validateAnswers(answers.state)) {
      return;
    }

    sendAnswers(answers.state)
    history.replace("/tailor", { answers: answers.state});
    const state = { quote: answers };
    setQuoteProgress(trymUrl, answers.state);
    history.push(trymUrl, state);
  }

  const b0 = answers.state.buildings
    ? answers.state.buildings[0]
    : buildingDefaults;
  const b0d = useDebounce(b0, 400);
  useEffect(() => {
    if (
      !b0d.building_address ||
      !b0d.building_city ||
      !b0d.building_state ||
      !b0d.building_zip
    )
      return;
    fetch(
      `/api/county?address=${encodeURIComponent(
        b0d.building_address
      )}&city=${encodeURIComponent(
        b0d.building_city
      )}&state=${encodeURIComponent(
        b0d.building_state
      )}&zip=${encodeURIComponent(b0d.building_zip)}`
    )
      .then((res) => {
        if (res.status !== 200) return null;
        return res.text();
      })
      .then((county) => {
        if (county) {
          setAnswers((a) => ({
            ...a,
            state: {
              ...a.state,
              buildings: [
                { ...a.state.buildings[0], building_county: county },
                ...a.state.buildings.slice(1),
              ],
            },
          }));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    b0d.building_address,
    b0d.building_city,
    b0d.building_state,
    b0d.building_zip,
  ]);
  const buildingMatchesMailing =
    b0.building_address === answers.state.mailing.mailing_address &&
    b0.building_city === answers.state.mailing.mailing_city &&
    b0.building_state === answers.state.mailing.mailing_state &&
    b0.building_zip === answers.state.mailing.mailing_zip;
  function changeBuildingMatchesMailing(e) {
    if (e.target.checked) {
      setAnswers((a) => ({
        ...a,
        state: {
          ...a.state,
          buildings: [
            {
              ...a.state.buildings[0],
              building_address: a.state.mailing.mailing_address,
              building_city: a.state.mailing.mailing_city,
              building_state: a.state.mailing.mailing_state,
              building_zip: a.state.mailing.mailing_zip,
            },
            ...a.state.buildings.slice(1),
          ],
        },
      }));
    } else {
      setAnswers((a) => ({
        ...a,
        state: {
          ...a.state,
          buildings: [
            {
              ...a.state.buildings[0],
              building_address: a.state.mailing.mailing_address + " ",
            },
            ...a.state.buildings.slice(1),
          ],
        },
      }));
    }
  }

  const validateAnswers = (data) => {
    let hasError = false;
    let formErrors = {};
    // check industry_type
    if (!data.industry_type || data.industry_type === "") {
      hasError = true;
      formErrors.industryType = "Industry is required";
    }

    // check business_type
    if (!data.business_type || data.business_type === "") {
      hasError = true;
      formErrors.businessType = "Business type is required";
    }

    // check contact_title
    if (!data.contact_title || data.contact_title === "") {
      hasError = true;
      formErrors.contactTitle = "Title is required";
    }

    // check answers.mailing.business_name
    if (!data.mailing.business_name || data.mailing.business_name === "") {
      hasError = true;
      formErrors.businessName = "Business name is required";
    }

    // check answers.mailing.mailing_address
    if (!data.mailing.mailing_address || data.mailing.mailing_address === "") {
      hasError = true;
      formErrors.mailingAddress = "Address is required";
    }

    // check answers.mailing.mailing_city
    if (!data.mailing.mailing_city || data.mailing.mailing_city === "") {
      hasError = true;
      formErrors.mailingCity = "City is required";
    }

    // check answers.mailing.mailing_state
    if (!data.mailing.mailing_state || data.mailing.mailing_state === "") {
      hasError = true;
      formErrors.mailingState = "State is required";
    }

    // check answers.mailing.mailing_address
    if (!data.mailing.mailing_zip || data.mailing.mailing_zip === "") {
      hasError = true;
      formErrors.mailingZip = "Zip is required";
    }

    // check answers.mailing.business_phone
    if (!data.mailing.business_phone || data.mailing.business_phone === "") {
      hasError = true;
      formErrors.businessPhone = "Business phone is required";
    }

    let buildingErrors = {};
    data.buildings.forEach((x, i) => {
      let hasBuildingError = false;
      let buildingError = {};
      if (!x.building_address || x.building_address === "") {
        hasBuildingError = true;
        buildingError.buildingAddress = "Address is required";
      }

      if (!x.building_city || x.building_city === "") {
        hasBuildingError = true;
        buildingError.buildingCity = "City is required";
      }

      if (!x.building_state || x.building_state === "") {
        hasBuildingError = true;
        buildingError.buildingState = "State is required";
      }

      if (!x.building_zip || x.building_zip === "") {
        hasBuildingError = true;
        buildingError.buildingZip = "Zip is required";
      }

      if (!x.building_county || x.building_county === "") {
        hasBuildingError = true;
        buildingError.buildingCounty = "County is required";
      }

      if (!x.building_square_feet || x.building_square_feet === "") {
        hasBuildingError = true;
        buildingError.buildingSquareFeet = "Building Square Feed is required";
      }

      if (hasBuildingError) {
        hasError = hasBuildingError;
        buildingErrors = { ...buildingErrors, [i]: buildingError };
      }
    });

    setError({
      fields: { buildings: buildingErrors, ...formErrors },
      message: hasError
        ? "There are errors to correct before you can continue"
        : "",
    });
    return hasError;
  };

  const industry = getIndustry(answers);
  const outOfAppetite = industry != null && !industry.appetite;

  // don't render if the data isn't loaded 
  if(!answers || !answers.state.contact.email) return;
  return (
    <div className="app q-flow">
      <Header />
      <div className="app__main">
        <div className="questions">
          <header>
            <h1>Get your tailored quote</h1>
            <h2>This will only take 5 minutes</h2>
          </header>
          <TrymProgress step={1} />
          <div className="questions__main">
            <div className="questions__form">
              <div className="questions__container">
                <div className="question-list">
                  <div className="question-group">
                    <SelectLookup
                      propPath={["state", "industry_type"]}
                      answers={answers}
                      setAnswers={setAnswers}
                      title="What do you do for a living?"
                      desc="Choose what best describes your industry"
                      placeholder="Type to search..."
                      error={errors.fields.industryType}
                      lookup={(searchText, answers) =>
                        classCodeLookup(
                          answers.state.mailing.mailing_state,
                          searchText
                        )
                      }
                      getOptionLabel={(type) => type.name}
                      getOptionValue={(type, i) => type.name}
                    />
                    {answers.state.industry_type != null &&
                      answers.state.industry_type.classCode == null && (
                        <Select
                          propPath={["state", "industry_type", "answerIndex"]}
                          answers={answers}
                          setAnswers={setAnswers}
                          title={answers.state.industry_type.question}
                          desc=" "
                          options={answers.state.industry_type.answers.map(
                            (a, i) => ({ key: i, value: a.name })
                          )}
                        />
                      )}

                    {outOfAppetite ? (
                      <Statement title="Unfortunately, we cannot support this type of business at this time 😐" />
                    ) : (
                      <Fragment>
                        <Slider
                          propPath={["state", "business_age"]}
                          answers={answers}
                          setAnswers={setAnswers}
                          title="When did you start your business?"
                          desc="It can be a rough estimate"
                          min={0}
                          max={4}
                          step={1}
                          tooltips={[
                            "Just starting",
                            "1 year ago",
                            "2 years ago",
                            "3 years ago",
                            "3+ years ago",
                          ]}
                          tooltipFormat={yearsAgo}
                        />
                        <Slider
                          propPath={["state", "num_employees"]}
                          answers={answers}
                          setAnswers={setAnswers}
                          title="How many employees do you have?"
                          desc="Full-time employees (including the owner)"
                          min={1}
                          max={26}
                          step={1}
                          tooltips={["It's just me", "25+ full-time employees"]}
                          tooltipFormat={fullTimeEmployees}
                        />
                        <Slider
                          propPath={["state", "num_parttime_employees"]}
                          answers={answers}
                          setAnswers={setAnswers}
                          title=" "
                          desc="Part-time employees"
                          min={0}
                          max={26}
                          step={1}
                          tooltips={[
                            "0 part-time employees",
                            "25+ part-time employees",
                          ]}
                          tooltipFormat={partTimeEmployees}
                        />
                        <Select
                          propPath={["state", "business_type"]}
                          answers={answers}
                          setAnswers={setAnswers}
                          title="What's your business entity type?"
                          desc="Examples: LLC, corporation, partnership, etc."
                          placeholder="Business Type"
                          error={errors.fields.businessType}
                          options={businessTypes}
                        />
                        <Text
                          propPath={["state", "contact_title"]}
                          answers={answers}
                          setAnswers={setAnswers}
                          title="What's your job title?"
                          desc=" "
                          placeholder="Your title"
                          error={errors.fields.contactTitle}
                        />
                      </Fragment>
                    )}
                  </div>
                  {!outOfAppetite && (
                    <div className="question-group">
                      <Statement
                        title="What is your business contact information?"
                        desc="We’ll show coverage options specific to your location"
                      />
                      <Text
                        propPath={["state", "mailing", "business_name"]}
                        answers={answers}
                        setAnswers={setAnswers}
                        placeholder="Business name"
                        error={errors.fields.businessName}
                      />
                      <Text
                        propPath={["state", "mailing", "mailing_address"]}
                        answers={answers}
                        setAnswers={setAnswers}
                        placeholder="Business address"
                        error={errors.fields.mailingAddress}
                      />
                      <Text
                        propPath={["state", "mailing", "mailing_city"]}
                        answers={answers}
                        setAnswers={setAnswers}
                        placeholder="City"
                        error={errors.fields.mailingCity}
                      />

                      <div className="question question-row">
                        <div className="question-list flex">
                          <Select
                            propPath={["state", "mailing", "mailing_state"]}
                            answers={answers}
                            setAnswers={setAnswers}
                            className="building-state"
                            title="State"
                            placeholder="State"
                            options={states}
                            error={errors.fields.mailingState}
                          />
                          <div className="question zip-spacer" />
                          <Text
                            propPath={["state", "mailing", "mailing_zip"]}
                            maxLength={10}
                            answers={answers}
                            setAnswers={setAnswers}
                            className="building-zip"
                            isValid={isZipValid}
                            placeholder="ZIP Code"
                            error={errors.fields.mailingZip}
                          />
                        </div>
                      </div>
                      <Text
                        propPath={["state", "mailing", "business_phone"]}
                        answers={answers}
                        setAnswers={setAnswers}
                        placeholder="Business phone number"
                        validExample="(555)555-5555"
                        template="(XXX)XXX-XXXX"
                        isValid={isPhoneValid}
                        allowed={/[0-9]/}
                        error={errors.fields.businessPhone}
                      />
                    </div>
                  )}
                  {!!answers.state.mailing.mailing_state && !outOfAppetite && (
                    <Fragment>
                      <div className="question-group">
                        <AddMoreQuestionGroup
                          answers={answers}
                          setAnswers={setAnswers}
                          propPath={["state", "buildings"]}
                          addText="Add another building"
                          defaults={buildingDefaults}
                          renderItem={(index) => (
                            <Fragment>
                              <Statement title="What is your building's address?" />
                              {index === 0 && (
                                <label>
                                  <input
                                    type="checkbox"
                                    checked={buildingMatchesMailing}
                                    onChange={changeBuildingMatchesMailing}
                                  />
                                  Same as mailing address, will autopopulate if
                                  clicked
                                </label>
                              )}
                              <Text
                                propPath={[
                                  "state",
                                  "buildings",
                                  index,
                                  "building_address",
                                ]}
                                answers={answers}
                                setAnswers={setAnswers}
                                placeholder="Building address"
                                error={
                                  errors.fields.buildings[index] &&
                                  errors.fields.buildings[index].buildingAddress
                                }
                              />
                              <Text
                                propPath={[
                                  "state",
                                  "buildings",
                                  index,
                                  "building_city",
                                ]}
                                answers={answers}
                                setAnswers={setAnswers}
                                placeholder="City"
                                error={
                                  errors.fields.buildings[index] &&
                                  errors.fields.buildings[index].buildingCity
                                }
                              />
                              <div className="question question-row">
                                <div className="question-list flex">
                                  <Select
                                    propPath={[
                                      "state",
                                      "buildings",
                                      index,
                                      "building_state",
                                    ]}
                                    answers={answers}
                                    setAnswers={setAnswers}
                                    className="building-state"
                                    placeholder="State"
                                    options={states}
                                    error={
                                      errors.fields.buildings[index] &&
                                      errors.fields.buildings[index]
                                        .buildingState
                                    }
                                  />
                                  <div className="question zip-spacer" />
                                  <Text
                                    propPath={[
                                      "state",
                                      "buildings",
                                      index,
                                      "building_zip",
                                    ]}
                                    maxLength={10}
                                    answers={answers}
                                    setAnswers={setAnswers}
                                    className="building-zip"
                                    isValid={isZipValid}
                                    error={
                                      errors.fields.buildings[index] &&
                                      errors.fields.buildings[index].buildingZip
                                    }
                                    placeholder="ZIP Code"
                                  />
                                </div>
                              </div>
                              <Text
                                propPath={[
                                  "state",
                                  "buildings",
                                  index,
                                  "building_county",
                                ]}
                                answers={answers}
                                setAnswers={setAnswers}
                                error={
                                  errors.fields.buildings[index] &&
                                  errors.fields.buildings[index].buildingCounty
                                }
                                placeholder="County"
                              />
                              <Text
                                propPath={[
                                  "state",
                                  "buildings",
                                  index,
                                  "building_square_feet",
                                ]}
                                answers={answers}
                                setAnswers={setAnswers}
                                title="How many square feet do you occupy in the building?"
                                desc="Estimate the square footage"
                                placeholder="Number of square feet"
                                error={
                                  errors.fields.buildings[index] &&
                                  errors.fields.buildings[index]
                                    .buildingSquareFeet
                                }
                              />
                              <Toggle
                                propPath={[
                                  "state",
                                  "buildings",
                                  index,
                                  "building_is_owned",
                                ]}
                                answers={answers}
                                setAnswers={setAnswers}
                                title="Does your business own this building?"
                              />
                              {answers.state.buildings[index]
                                .building_is_owned === true && (
                                <Slider
                                  propPath={[
                                    "state",
                                    "buildings",
                                    index,
                                    "building_rebuild_cost",
                                  ]}
                                  answers={answers}
                                  setAnswers={setAnswers}
                                  title="How much insurance coverage do you want for your building?"
                                  desc="Covers repair costs if your building is damaged by fire, theft, or disaster"
                                  min={0}
                                  max={1010000}
                                  step={10000}
                                  tooltips={["$0", "Over $1,000,000"]}
                                  tooltipFormat={moneyFormat}
                                />
                              )}
                              {answers.state.buildings[index]
                                .building_rebuild_cost < 1000 &&
                                answers.state.buildings[index].building_is_owned && (
                                  <div className="unset-text">
                                    Minimum $1,000
                                  </div>
                                )}
                              {answers.state.buildings[index]
                                .building_rebuild_cost > 1000000 &&
                                answers.state.buildings[index].building_is_owned && (
                                  <div className="error-text">
                                    $1,000,000 is the maximum available limit
                                  </div>
                                )}
                              {/* { answers.buildings[index].building_is_owned === true && 
                                <Toggle propPath={["state", "buildings", index, "building_has_mortgagee"]}
                                  answers={answers} setAnswers={setAnswers}
                                  title="Is there a mortgage holder on this building?" /> }
                              { answers.buildings[index].building_is_owned === true && answers.buildings[index].building_has_mortgagee === true &&
                                <Fragment>
                                  <Statement title="Mortgage holder’s name and address:" />
                                  <Text propPath={["buildings", index, "mortgagee_name"]}
                                    answers={answers} setAnswers={setAnswers}
                                    placeholder="Mortgage holder name" />
                                  <Text propPath={["buildings", index, "mortgagee_address"]}
                                    answers={answers} setAnswers={setAnswers}
                                    placeholder="Mortgage holder address" />
                                  <Text propPath={["buildings", index, "mortgagee_city"]}
                                    answers={answers} setAnswers={setAnswers}
                                    placeholder="City" />
                                  <div className="question question-row">
                                    <div className="question-list flex">
                                      <Select propPath={["buildings", index, "mortgagee_state"]}
                                        answers={answers} setAnswers={setAnswers}
                                        className="building-state"
                                        placeholder="State"
                                        options={states} />
                                      <div className="question zip-spacer" />
                                      <Text propPath={["buildings", index, "mortgagee_zip"]} maxLength={10}
                                        answers={answers} setAnswers={setAnswers}
                                        className="building-zip"
                                        isValid={isZipValid}
                                        placeholder="ZIP Code" />
                                    </div>
                                  </div>
                                </Fragment> } */}
                              <div className="br" />
                              <Toggle
                                propPath={[]}
                                disabled
                                answers={true}
                                automaticallyIncluded
                                title="Protect the property your business owns"
                                desc="Covers your equipment, furniture, inventory, computers, phone systems, and all the other property owned by your business"
                              />
                              <IntervalSlider
                                propPath={["state", "buildings", index, "bpp"]}
                                answers={answers}
                                setAnswers={setAnswers}
                                title="How much is your business property worth?"
                                intervals={bppIntervals}
                                tooltips={["$1000", "$500,000+"]}
                                tooltipFormat={moneyFormat}
                              />
                              {!answers.state.buildings[index].bpp && (
                                <div className="unset-text">Minimum $1,000</div>
                              )}
                              {answers.state.buildings[index].bpp > 500000 && (
                                <div className="error-text">
                                  $500,000 is the maximum available limit
                                </div>
                              )}
                            </Fragment>
                          )}
                        />
                      </div>
                      <div className="question-group">
                        <Toggle
                          propPath={[]}
                          answers={true}
                          automaticallyIncluded
                          title="Stay afloat financially if there’s a catastrophe"
                          desc="In the event of a covered disaster, business income interruption coverage provides the income your business would’ve earned plus the finances to get your operation back on its feet."
                        />
                        <Toggle
                          propPath={[]}
                          disabled
                          answers={true}
                          automaticallyIncluded
                          title="Do you want to protect your business against lawsuits?"
                          desc="This is general liability insurance usually required by clients and commercial leases"
                        />
                        <Toggle
                          propPath={["cyber"]}
                          answers={answers}
                          setAnswers={setAnswers}
                          comingSoon="CYBER RISK COVERAGE COMING SOON"
                          title="Do hackers pose a potential threat to your business?"
                          desc='If a hacker hacks you, or even worse, breaches your data, cyber risk coverage handles the restoration costs. If you select "YES" then we will email you when the coverage is live and able to be added to your policy.'
                        />
                        <Toggle
                          propPath={["professional"]}
                          answers={answers}
                          setAnswers={setAnswers}
                          comingSoon="PROFESSIONAL LIABILITY COVERAGE COMING SOON"
                          title="Do you want to cover the professional advice you give to clients?"
                          desc='If a client sues you for a professional mistake, professional liability coverage safeguards you from their claims against your business. You can run your practice with peace of mind. If you select "YES" then we will email you when the coverage is live and able to be added to your policy.'
                        />
                        {/* <Toggle propPath={["tria"]}
                        answers={answers} setAnswers={setAnswers}
                        title="Do you want terrorism insurance?"
                        desc="Covers your business from any attacks related to certified acts of terrorism" /> */}
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div>
            {errors.message && <Alert type="error" message={errors.message} />}
            <PrimaryButton className="questions__quote" onClick={nextStep}>
              Next step
            </PrimaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TailorQuestions;

const yearsAgo = (x) => `${x} year${x === 1 ? "" : "s"} ago`;
const fullTimeEmployees = (x) => `${x} full-time employee${x > 1 ? "s" : ""}`;
const partTimeEmployees = (x) => `${x} part-time employee${x > 1 ? "s" : ""}`;
const moneyFormat = (x) => `$${x.toLocaleString()}`;
const isPhoneValid = (val) =>
  !!val && !!val.match(/^\([0-9]{3}\)[0-9]{3}-[0-9]{4}$/);
const isZipValid = (val) => !!val && !!val.match(/^[0-9]{5}(-[0-9]{4})?$/);

function getIndustry(answers) {
  return answers.industry_type == null || answers.industry_type.classCode
    ? answers.industry_type
    : answers.industry_type.answers[+answers.industry_type.answerIndex];
}
