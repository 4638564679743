import React from 'react'
import styled from 'styled-components'
import { lightGreen, darkGray } from '../styles'
import FormError from '../components/FormError'

export const GenericSlider = ({min, max, step, value, onChange, getTooltip, error}) => {
  if (value === undefined) value = min
  const _onChange = e => {
    onChange(+e.target.value)
  }

  return (
    <Container>
      <Value>{getTooltip(value)}</Value>
      <Range
        type="range"
        step={step} min={min} max={max}
        value={value}
        onChange={_onChange}
      />
      <FormError error={error} />
    </Container>
  )
}

const Slider = ({tooltips, tooltipFormat, min, max, step, value, onChange, error}) => {
  function getTooltip(value) {
    if (min === value && tooltips && tooltips.length > 0) return tooltips[0]
    if (max === value && tooltips && tooltips.length > 1) return tooltips[tooltips.length - 1]
    return tooltipFormat ? tooltipFormat(value): value.toLocaleString()
  }

  return <GenericSlider
    min={min}
    max={max}
    error={error}
    getTooltip={getTooltip}
    step={step}
    value={value}
    onChange={onChange}
  />
}

// Wraps a Slider which is given a step of 1
export const IntervalSlider = ({intervals, tooltips, tooltipFormat, value, onChange, error}) => {
  if (value === undefined) value = intervals[0].min
  let length = 1
  const index = intervals.reduce((i, {tooltips, tooltipFormat, min, max, step}, intervalIndex) => {
    const intervalLength = Math.floor((max - min) / step)
    length += intervalLength
    if (value >= max) return i + intervalLength + (intervalIndex === intervals.length - 1 ? 1 : 0)
    if (value >= min) return i + Math.floor((value - min) / step)
    return i
  }, 0)

  function getValue(index) {
    for (let i = 0; i < intervals.length; ++i) {
      const int = intervals[i]
      const intervalLength = Math.floor((int.max - int.min) / int.step)
      if (index > intervalLength) {
        index -= intervalLength
      } else {
        return int.min + index*int.step
      }
    }
    return intervals[intervals.length-1].max
  }

  function onIndexChange(index) {
    onChange(getValue(index))
  }

  function getTooltip(index) {
    const value = getValue(index)
    if (intervals[0].min === value && tooltips && tooltips.length > 0) return tooltips[0]
    if (intervals[intervals.length-1].max === value && tooltips && tooltips.length > 1) return tooltips[tooltips.length - 1]
    return tooltipFormat ? tooltipFormat(value): value.toLocaleString()
  }

  return <GenericSlider
    getTooltip={getTooltip}
    error={error}
    min={0}
    max={length}
    step={1}
    value={index}
    onChange={onIndexChange}
  />
}

export default Slider

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const Value = styled.div`
  color: ${darkGray};
  font-size: 0.8rem;
`

const Range = styled.input`
  display: block;
  -webkit-appearance: none;
  background-color: #ddd !important;
  height: 10px !important;
  border-width: 0 !important;
  margin: 0.5rem 0 1rem 0;
  border-radius: 5px !important;
  padding: 0 0.5rem;
  outline: 0;

  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    background-color: ${lightGreen};
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 4px solid white;
    margin-top: 0;
    cursor: pointer;
    transition: .3s ease-in-out;  
  }
  &::-moz-range-thumb {
    -webkit-appearance: none;
    background-color: ${lightGreen};
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 4px solid white;
    margin-top: 0;
    cursor: pointer;
    transition: .3s ease-in-out;  
  }
  &::-webkit-slider-thumb:active {
    transform: scale(1.25);
  }
  &::-moz-range-thumb:active {
    transform: scale(1.25);
  }
`
