const states = [
  { license: "#120NN8943", initials: "AL", state: "Alabama" },
  { license: "#120NN8943", initials: "AK", state: "Alaska" },
  { license: "#120NN8943", initials: "AZ", state: "Arizona" },
  { license: "#120NN8943", initials: "AR", state: "Arkansas" },
  { license: "#120NN8943", initials: "CA", state: "California" },
  { license: "#120NN8943", initials: "CO", state: "Colorado" },
  { license: "#120NN8943", initials: "CT", state: "Connecticut" },
  { license: "#120NN8943", initials: "DE", state: "Delaware" },
  { license: "#120NN8943", initials: "DC", state: "District of Columbia" },
  { license: "#120NN8943", initials: "FL", state: "Florida" },
  { license: "#120NN8943", initials: "GA", state: "Georgia" },
  { license: "#120NN8943", initials: "HI", state: "Hawaii" },
  { license: "#120NN8943", initials: "ID", state: "Idaho" },
  { license: "#120NN8943", initials: "IL", state: "Illinois" },
  { license: "#120NN8943", initials: "IN", state: "Indiana" },
  { license: "#120NN8943", initials: "IA", state: "Iowa" },
  { license: "#120NN8943", initials: "KS", state: "Kansas" },
  { license: "#120NN8943", initials: "KY", state: "Kentucky" },
  { license: "#120NN8943", initials: "LA", state: "Louisiana" },
  { license: "#120NN8943", initials: "ME", state: "Maine" },
  { license: "#120NN8943", initials: "MD", state: "Maryland" },
  { license: "#120NN8943", initials: "MA", state: "Massachusetts" },
  { license: "#120NN8943", initials: "MI", state: "Michigan" },
  { license: "#120NN8943", initials: "MN", state: "Minnesota" },
  { license: "#120NN8943", initials: "MS", state: "Mississippi" },
  { license: "#120NN8943", initials: "MO", state: "Missouri" },
  { license: "#120NN8943", initials: "MT", state: "Montana" },
]

export default states
