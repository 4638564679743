import React from 'react'

const FraudWarnings = ({mailing, buildings}) => {
  const states = [...buildings.map(b => b.building_state), mailing.mailing_state]
  return (
    <div className="fraud-warnings">
      {showMain(states) && <div>{main}</div>}
      {renderAdditional(states)}
    </div>
  )
}

const showMain = states => states.some(s => !mainStateExemptions.includes(s))
const renderAdditional = states => additional
  .filter(a => a.states.some(s => states.includes(s)))
  .map(a => <div key={a.states.join()}>{a.text}</div>)

const main = `ANY PERSON WHO KNOWINGLY AND WITH INTENT TO DEFRAUD ANY INSURANCE COMPANY OR OTHER PERSON FILES AN APPLICATION FOR INSURANCE OR STATEMENT OF CLAIM CONTAINING ANY MATERIALLY FALSE INFORMATION OR CONCEALS, FOR THE PURPOSE OF MISLEADING, INFORMATION CONCERNING ANY FACT MATERIAL THERETO COMMITS A FRAUDULENT INSURANCE ACT, WHICH IS A CRIME AND SUBJECTS SUCH PERSON TO CRIMINAL AND CIVIL PENALTIES.`
const mainStateExemptions = [
  'AL',
  'AR',
  'CO',
  'DC',
  'FL',
  'KS',
  'KY',
  'LA',
  'MD',
  'ME',
  'NJ',
  'NM',
  'NY',
  'OH',
  'OK',
  'OR',
  'RI',
  'TN',
  'VA',
  'VT',
  'WA',
  'WV'
]

const additional = [
  {
    states: ['AL', 'AR', 'LA', 'NM', 'RI', 'WV'],
    text: `Any person who knowingly presents a false or fraudulent claim for payment of a loss or benefit or knowingly presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.`
  }, {
    states: ['CO'],
    text: `It is unlawful to knowingly provide false, incomplete, or misleading facts or information to an insurance company for the purpose of defrauding or attempting to defraud the company. Penalties may include imprisonment, fines, denial of insurance and civil damages. Any insurance company or agent of an insurance company who knowingly provides false, incomplete, or misleading facts or information to a policyholder or claimant for the purpose of defrauding or attempting to defraud the policyholder or claimant with regard to a settlement or award payable from insurance proceeds shall be reported to the Colorado Division of Insurance within the Department of Regulatory Agencies.`
  }, {
    states: ['DC'],
    text: `WARNING: It is a crime to provide false or misleading information to an insurer for the purpose of defrauding the insurer or any other person. Penalties include imprisonment and/or fines. In addition, an insurer may deny insurance benefits if false information materially related to a claim was provided by the applicant.`
  }, {
    states: ['FL'],
    text: `Any person who knowingly and with intent to injure, defraud, or deceive any insurer files a statement of claim or an application containing any false, incomplete, or misleading information is guilty of a felony of the third degree.`
  }, {
    states: ['KS'],
    text: `Any person who, knowingly and with intent to defraud, presents, causes to be presented or prepares with knowledge or belief that it will be presented to or by an insurer, purported insurer, broker or any agent thereof, any written, electronic, electronic impulse, facsimile, magnetic, oral, or telephonic communication or statement as part of, or in support of, an application for the issuance of, or the rating of an insurance policy for personal or commercial insurance, or a claim for payment or other benefit pursuant to an insurance policy for commercial or personal insurance which such person knows to contain materially false information concerning any fact material thereto; or conceals, for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act.`
  }, {
    states: ['KY'],
    text: `Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance containing any materially false information or conceals, for the purpose of misleading, information concerning any fact material thereto commits a fraudulent insurance act, which is a crime.`
  }, {
    states: ['ME'],
    text: `It is a crime to knowingly provide false, incomplete or misleading information to an insurance company for the purpose of defrauding the company. Penalties may include imprisonment, fines or denial of insurance benefits.`
  }, {
    states: ['MD'],
    text: `Any person who knowingly or willfully presents a false or fraudulent claim for payment of a loss or benefit or who knowingly or willfully presents false information in an application for insurance is guilty of a crime and may be subject to fines and confinement in prison.`
  }, {
    states: ['NJ'],
    text: `Any person who includes any false or misleading information on an application for an insurance policy is subject to criminal and civil penalties.`
  }, {
    states: ['NY'],
    text: `Any person who knowingly and with intent to defraud any insurance company or other person files an application for insurance or statement of claim containing any materially false information, or conceals for the purpose of misleading, information concerning any fact material thereto, commits a fraudulent insurance act, which is a crime, and shall also be subject to a civil penalty not to exceed five thousand dollars and the stated value of the claim for each such violation.`
  }, {
    states: ['OH'],
    text: `Any person who, with intent to defraud or knowing that he is facilitating a fraud against an insurer, submits an application or files a claim containing a false or deceptive statement is guilty of insurance fraud.`
  }, {
    states: ['OK'],
    text: `WARNING: Any person who knowingly, and with intent to injure, defraud or deceive any insurer, makes any claim for the proceeds of an insurance policy containing any false, incomplete or misleading information is guilty of a felony.`
  }, {
    states: ['OR'],
    text: `Any person who knowingly and with intent to defraud or solicit another to defraud the insurer by submitting an application containing a false statement as to any material fact may be violating state law.`
  }, {
    states: ['TN', 'VA', 'WA'],
    text: `It is a crime to knowingly provide false, incomplete, or misleading information to an insurance company for the purpose of defrauding the company. Penalties include imprisonment, fines, and denial of insurance benefits.`
  }, {
    states: ['VT'],
    text: `Any person who knowingly presents a false statement in an application for insurance may be guilty of a criminal offense and subject to penalties under state law.`
  }
]

export default FraudWarnings
