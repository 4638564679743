import React, {PureComponent} from 'react'
import loading from '../images/loading.svg'

export class AuthCallback extends PureComponent {
  render() {
    return (
      <div className="loading">
        <img src={loading} alt="loading"/>
      </div>
    )
  }
}
