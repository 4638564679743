import { useState } from "react";

export const useForm = (initialValues) => {
  const [values, setValues] = useState(initialValues);
  return [
    values,
    (e) => {
      setValues({
        ...values,
        [e.target.name]: getValue(e.target),
      });
    },
  ];
};


function getValue(target) {
  switch(target.type) {
    case 'checkbox': 
      return target.checked;
    case 'text':
      return target.value;
    default: 
      return target.value;
  }
}

