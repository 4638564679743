import React, { useEffect, useState } from 'react'
import md5 from 'md5'
import Header from '../../Header'
import Loading from '../../Loading'
import TrymProgress from '../../components/TrymProgress'
import coverages from '../../coverages'
import selectedQuoteChevron from '../../images/selected-quote-chevron.svg'
import ErrorPage from '../../ErrorPage'
import QuoteDenial  from '../QuoteDenial';
import trymBadge from '../../images/trym-badge-dark.svg'
import downloadIconGray from '../../img/download-icon-gray.svg'
import styled from 'styled-components'
import PrimaryButton from '../../PrimaryButton'
import { useLocalState, setQuoteProgress } from "../../utils";
import { quoteDefaultAnswers } from '../../utils/quoteDefaults';

export default ({auth, history}) => {
  

  const [answers, setAnswers] = useLocalState(
    "quote-progress",
    {
      state: { ...quoteDefaultAnswers },
      url: "/tailor"
    },
    null
  );

  const [quote, setQuote] = useState()
  const [error, setError] = useState({ isDeclination: false, errors: []});
  useEffect(() => {
    if (!answers || !answers.state.contact) return history.replace('/trym') 

    getQuote(answers.state)
      .then(setQuote)
      .catch(error => {
        if(error.message === 'Declination') {
          setError({ isDeclination: true, errors: error.data.errors});
        } else if(error.data) {
          setError({ isDeclination: false, errors: error.data.errors});
        } else {
          setError({errors: ''});
        }
      })
  }, [history, answers])

  const select = quoteName => {
    const quoteBundle = quote[quoteName]
    if (quoteBundle) {
      const {trymId} = quote;
      quoteBundle.name = quoteName;
      const paymentState = {answers: answers.state, quote: quoteBundle, trymId}
      
      localStorage.setItem('payment-state', JSON.stringify(paymentState))
      const url = `/quotes/${quoteName}/select`
      setQuoteProgress(url, paymentState)
      history.push(url)
    }
  }

  const login = () => auth.login()
  const logout = () => auth.logout()

  if (error.errors.length != 0) return <QuoteError error={error.errors} showDenial={error.isDeclination} businessName={answers.state.mailing.business_name} />
  if (!quote) return <Loading text="Putting together coverage options..." />

  return (
    <div className="app q-flow">
      <Header>
        { auth
          ? <button className="header__button button blue" onClick={logout}>Logout</button>
          : <button className="header__button button blue" onClick={login}>Login</button>
        }
      </Header>
      <div className="app__main">
        <div className="quotes">
          <header>
            <h1>Choose your plan</h1>
            <h2>Get the coverage you need at a budget you can afford.</h2>
            <TrymProgress step={3} />
          </header>
          <QuotesTableDesktop quotes={quote} select={select} />
          <QuotesTableMobile quotes={quote} select={select} />
          <div className="quotes__faq-container">
            <div className="quotes__faq">
              <h1>Frequently asked questions</h1>

              <h2>How do claims work?</h2>
              <p>Filing claims is easy. Once you a buy a policy, and something bad happens that&apos;s covered, log in to your Trym dashboard to file the claim. We&apos;ll walk you through the rest of the process from there.</p>

              <h2>Which plan is best for me?</h2>
              <p>The short answer: Go with our recommended plan like most business owners do. The long answer: Depends on your budget and risk tolerance. Compare your options, think on the decision for a few minutes, and go with your gut.</p>

              <h2>Can I make changes later?</h2>
              <p>Yes, you can make changes later in your dashboard—choose a different plan, modify your info, upgrade or downgrade. Trym adjusts with you.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const coverageFor = (cov, quote) => (
  <td className={`quotes__cov${quote[cov.key] ? '' : '--disabled'}`}>
    { cov.format ? cov.format(quote[cov.key]) : quote[cov.key] }
  </td>
)

async function getQuote(quoteReq) {
  const existingQuoteId = localStorage.getItem(getQuoteKey({...quoteReq}));

  if (existingQuoteId) {
    return fetch(`/api/quotes/${quoteReq.mailing.business_name}/${existingQuoteId}`, {
      method: 'GET',
      headers: {accept: 'application/json'},
    }).then(res => {
      if (!res.ok) {
        return res.json().then(body => {throw  {status: res.status, ...body}})
      }
      return res.json()
    })
  }

  const lastQuoteId = localStorage.getItem(quoteLastKey)
  const url = lastQuoteId ? `/api/quotes/${quoteReq.mailing.business_name}/${lastQuoteId}` : '/api/quotes'
  const method = lastQuoteId ? 'PUT' : 'POST';

  return fetch(url, {
    method,
    headers: {accept: 'application/json', 'content-type': 'application/json'},
    body: JSON.stringify(quoteReq)
  })
  .then(res => {
    if (!res.ok) return res.json().then(body => {throw {status: res.status, ...body}})
    return res.json()
  }).then(quote => {
    localStorage.setItem(getQuoteKey(quoteReq), quote.trymId)
    localStorage.setItem(quoteLastKey, quote.trymId)
    return quote;
  })
}

const getQuoteKey = answers => `quote-${md5(JSON.stringify({...answers}))}`
const quoteLastKey = 'quote-last'

const QuotesTableDesktop = ({quotes, select}) => {
  const selectQuote = quoteName => () => select(quoteName)
  return (
    <table className="quotes__table desktop">
      <thead>
        <tr>
          <td style={{display: 'flex', flexDirection: 'row'}}><img src={trymBadge} style={{marginRight: '20px'}} alt="" /><div><div>Want more options?</div><div style={{color: '#777', fontSize: '0.8em'}}>We have 2 new plans coming soon</div></div></td>
          <td>Recommended for you</td>
          {/* <td>Good</td> */}
          {/* <td><div>Better</div><div className="sub">Coming soon</div></td>
          <td><div>Best</div><div className="sub">Coming soon</div></td> */}
          {/* <td><div className="better">Better&nbsp;<span className="green">*</span></div><div className="green sub">Most popular</div></td>
          <td>Best</td> */}
        </tr>
      </thead>
      <tbody>
        { coverages.map(cov => <tr key={cov.key}>
            <td>
              <div className="quotes__coverage-name">{cov.name}</div>
              <div className="quotes__coverage-desc">{cov.desc}</div>
            </td>
            {coverageFor(cov, quotes.good)}
            {/* <td>Coming soon</td>
            <td>Coming soon</td> */}
            {/* {coverageFor(cov, quotes.better)}
            {coverageFor(cov, quotes.best)} */}
          </tr>)
        }
        <tr className="quotes__premiums">
          <td>
            <div className="quotes__coverage-name"><b>Monthly premium</b></div>
            <div className="quotes__coverage-desc" style={{ fontWeight: 'normal'}}>includes taxes and fees</div>
          </td>
          <td><div>${quotes.good.monthlyPremium}/mo</div><div>${(quotes.good.monthlyPremium * 12).toFixed(2)} yearly</div></td>
          {/* <td><div>${quotes.better.monthlyPremium}/mo</div><div>${quotes.better.monthlyPremium * 12} yearly</div></td>
          <td><div>${quotes.best.monthlyPremium}/mo</div><div>${quotes.best.monthlyPremium * 12} yearly</div></td> */}
          {/* <td></td>
          <td></td> */}
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td />
          <td><PrimaryButton className="questions__quote" onClick={selectQuote('good')}>Select</PrimaryButton></td>
          {/* <td><button className="quotes__select button" onClick={selectQuote('better')}>Select</button></td>
          <td><button className="quotes__select button blue" onClick={selectQuote('best')}>Get it all</button></td> */}
          {/* <td>Coming Soon</td>
          <td>Coming Soon</td> */}
        </tr>
        <tr>
          <td />
          <td><Download bundle="good" quotes={quotes} /></td>
        </tr>
      </tfoot>
    </table>
  )
}

const chevronX = {
  good: '16.67%',
  better: '50%',
  best: '83.33%'
}
const QuotesTableMobile = ({quotes, select}) => {
  // const [selected, setSelected] = useState('better')
  const [selected, setSelected] = useState('good')
  const selectQuote = () => select(selected)
  const selectedIf = option => option === selected ? 'selected' : ''
  const chevronStyle = {
    left: chevronX[selected]
  }

  return (
    <table className="quotes__table mobile">
      <thead>
        <tr>
          <td onClick={() => setSelected('good')}>
            <div className={selectedIf('good')}>Good</div>
            <div className="green">${quotes.good.monthlyPremium}/mo</div>
          </td>
          <td><div>Better</div><div className="sub">Coming Soon</div></td>
          <td><div>Best</div><div className="sub">Coming Soon</div></td>
          {/* <td onClick={() => setSelected('better')}>
            <div className={`better ${selectedIf('better')}`}>Better&nbsp;<span className="green">*</span></div>
            <div className="green">${quotes.better.monthlyPremium}/mo</div>
          </td>
          <td onClick={() => setSelected('best')}>
            <div className={selectedIf('best')}>Best</div>
            <div className="green">${quotes.best.monthlyPremium}/mo</div>
          </td> */}
        </tr>
        <tr>
          <td>
            <div className="gray-line" />
            <img className="selected-chevron" src={selectedQuoteChevron} style={chevronStyle} alt="" />
          </td>
        </tr>
      </thead>
      <tbody>
        { coverages.map(cov => <tr key={cov.key}>
            <td>
              <div className="quotes__coverage-name">{cov.name}</div>
              <div className="quotes__coverage-desc">{cov.desc}</div>
            </td>
            {coverageFor(cov, quotes[selected])}
          </tr>)
        }
        <tr className="quotes__premiums">
          <td><span>Monthly premium</span></td>
          <td><span>${quotes[selected].monthlyPremium}/mo</span></td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td><button className="quotes__select button blue" onClick={selectQuote}>Select</button></td>
        </tr>
        <tr>
          <td><Download bundle={selected} quotes={quotes} /></td>
        </tr>
      </tfoot>
    </table>
  )
}

const Download = ({bundle, quotes}) => (
  <DownloadLink href={`/api/quotes/${encodeURIComponent(quotes[bundle].insuredName)}/${quotes.trymId}/${bundle}/pdf`} target="_blank">
    <img src={downloadIconGray} alt="" />&nbsp;&nbsp;<span>Download my quote</span>
  </DownloadLink>
)

const DownloadLink = styled.a`
  color: #222;
  text-align: center;
  text-decoration: none;
  img, span { vertical-align: middle; }
`

const QuoteError = ({error, businessName, showDenial = false}) => {
  let text = "Oh no, we weren't able to look up your quote right now. We're taking a look."
  if (error.status === 400) {
    if (error.error === 'duplicate_submission') text = "Sorry! Looks like you've already requested a quote from us. Check your email to pick up where you left off or reach out to us for help."
    if (error.error === 'cf_unhandled') text = "Oh no, we weren't able to process your quote right now. We're taking a look."
  }

  if(showDenial) {
    return <QuoteDenial reasons={error} businessName={businessName} />
  }

  return <ErrorPage text={text} />
}
