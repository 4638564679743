const dollar   = x => x ? `$${x.toLocaleString()}` : 'Not Included'
// const bool     = x => x ? 'Included' : 'Not included'
const identity = x => x

const coverages = [
  {key: 'generalLiability', format: dollar, name: 'General liability', desc: 'Covers third-party bodily injury, property damage, libel, slander'},
  {key: 'defenseCosts', format: identity, name: 'Legal defense', desc: 'Covers your legal bills and attorney fees if your business is sued'},
  {key: 'property', format: dollar, name: 'Property insurance', desc: 'Covers all the moveable items owned by your business'},
  {key: 'deductible', format: dollar, name: 'Property deductible', desc: 'Your out-of-pocket costs before coverage kicks in'},
  {key: 'businessIncomeProtection', format: identity, name: 'Business income protection', desc: 'Covers lost income if your business shuts down because of a disaster'},
  {key: 'employmentPractices', format: dollar, name: 'Employment practices liability', desc: 'Covers HR related claims against your business'},
  {key: 'equipmentBreakdown', format: dollar, name: 'Equipment breakdown', desc: 'Covers replacement costs for damaged essential equipment'},
  // {key: 'professionalLiability', format: dollar, name: 'Professional liability', desc: 'Protect against professional mistakes'},
  // {key: 'cyberSecurity', format: dollar, name: 'Cyber security', desc: 'Hackers suck'},
]

//STILL NEED TO ADD THE BELOW ONECE THE BACK END IS BUILT OUT
// Building insurance
// Covers physical damage to your building

// Building deductible
// Your out-of-pocket costs before coverage kicks in

// Professional liability -- not available yet but let’s set the stage for it if we can
// Covers your errors and omissions from your work with clients

// Cyber insurance -- not available yet but let’s set the stage for it if we can
// Covers you if a data breach happens and sensitive customer data gets out


export default coverages