import React from "react";


export default ({error}) => {
  return (
    error ? (
      <span role="alert" className="error-text signup__error">
        {error}
      </span>
    ) : null
  );
}