import { useEffect } from 'react'
import Cookies from 'js-cookie'

export function useReferral(shouldStore) {
  let code = getFromUrl() || getFromLocalStorage() || getFromCookie() || null
  if (code) console.log(`Referral: ${code}`)

  useEffect(() => {
    if (shouldStore && code) {
      setInLocalStorage(code)
      setInCookie(code)
    }
  }, [code, shouldStore])
  return code
}

function getFromUrl() {
  const url = window.location.href
  const match = url.match(/^https?:\/\/[a-z.]+(:[0-9]+)?\/([a-z]+\.[a-z0-9]+)$/i)
  if (!match) return null
  if (!match[2]) return null
  return match[2].toLowerCase()
}

const REFERRAL = 'referralCode'
function getFromLocalStorage() {
  if (!window.localStorage) return null
  try {
    return window.localStorage.getItem(REFERRAL)
  } catch (e) {
    return null
  }
}
function setInLocalStorage(code) {
  if (!window.localStorage) return
  try {
    window.localStorage.setItem(REFERRAL, code)
  } catch (e) {}
}

function getFromCookie() {
  return Cookies.get(REFERRAL)
}
function setInCookie(code) {
  Cookies.set(REFERRAL, code, {expires: 365})
}