export const quoteDefaultAnswers = {
  contact: {
    firstName: "",
    lastName: "",
    email: "",
    state: "",
  },
  industry_type: {},
  business_age: 0,
  num_employees: 1,
  num_parttime_employees: 0,
  mailing: {},
  buildings: [],
  cyber: false,
  professional: false,
  tria: true,
  salaries: 0,
  property_losses: 0,
  general_losses: 0,
  professional_losses: 0,
};

export const buildingDefaults = {
  bpp: 1000,
  building_rebuild_cost: 1000,
  revenue: 0,
  revenue_high: 0
}