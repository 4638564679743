import React, { useCallback, useContext, useState } from "react";
import { UserContext } from "./context";
import firebase from "firebase/app";
import { Redirect, Link } from "react-router-dom";
import trymLogoWhite from "./img/trym-logo-white.svg";

const Login = ({ history, location }) => {
  const [errors, setError] = useState({
    email: "",
    password: "",
  });
  const [loading, setLoading] = useState(false);
  const validate = (email, password) => {
    let hasError = false;
    const formErrors = {};
    if (email === "") {
      hasError = true;
      formErrors.email = "Email is required";
    }
    if (password === "") {
      hasError = true;
      formErrors.password = "Password is required";
    }

    setError(formErrors);
    return hasError;
  };

  const handleLogin = useCallback(
    async (e) => {
      e.preventDefault();
      const { email, password } = e.target.elements;
      if (validate(email.value, password.value)) {
        return;
      }

      try {
        setLoading(true);
        await firebase
          .auth()
          .signInWithEmailAndPassword(email.value, password.value);

        let { state } = location;
        if(state && state.returnUrl) {
          history.push(state.returnUrl);
        } else {
          history.push('/dashboard');
        }
        
      } catch (error) {
        switch (error.code) {
          case "auth/invalid-email":
            setError({ email: "Please enter a valid email" });
            break;
          case "auth/user-not-found":
            setError({ email: "User is not found" });
            break;
          case "auth/wrong-password":
            setError({ password: "Invalid Password" });
            break;
          case "auth/user-disabled":
            setError({ email: "User is not active" });
            break;
          default:
            setError({ email: error.message });
            break;
        }
        setLoading(false);
      }
      
    },
    [history, location]
  );

  const auth = useContext(UserContext);
  if (auth && auth.user) {
    const { state } = location;
    if(state === undefined || !state.forceLogin) {
      return <Redirect to="/dashboard" />;
    }
  }

  return (
    <div className="page">
      <header className="logo-only">
        <img src={trymLogoWhite} className="header-logo" alt="Trym" />
      </header>
      <section className="green-hero-section">
        <div className="small-container">
          <h1 className="text-center">Log into your account</h1>
        </div>
      </section>
      <section className="main-content">
        <div className="small-container">
          <div class="main-content_utilities">
            <div class="back-button" onClick={() => history.goBack()}>
              Go back
            </div>
          </div>
          <form onSubmit={handleLogin}>
            <div className="main-content_box">
              <input
                type="text"
                name="email"
                placeholder="Email"
                className={errors.email ? "error" : ""}
              />
              {errors.email && (
                <span role="alert" className="error-text signup__error">
                  {errors.email}
                </span>
              )}
              <input
                type="password"
                name="password"
                placeholder="Password"
                className={errors.password ? "error" : ""}
              />
              {errors.password && (
                <span role="alert" className="error-text signup__error">
                  {errors.password}
                </span>
              )}
              <div>
                <Link to="/forgot-password" className="green-link">
                  Forgot password
                </Link>
              </div>
              <div className="text-center large-margin-top">
                <button type="submit" className="button primary full-width" disabled={loading}>
                  Login
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <footer>
        <div className="regular-container">
          <p className="copyright">
            <span className="copyright_entity">
              &copy; <script>document.write(new Date().getFullYear());</script>{" "}
              Trym Inc.{" "}
            </span>
            <Link to="/terms-of-service" className="copyright_link">
              Terms of Service
            </Link>
            <Link to="/privacy-policy" className="copyright_link">
              Privacy policy
            </Link>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Login;
