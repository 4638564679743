import React from 'react'
import {TitledInfoPage} from './InfoPage'

const Privacy = () => (
  <TitledInfoPage className="privacy-policy" title="Privacy Policy">
    <h2>Privacy Policy</h2>
    <p className="tbd">Last Revised: February 1, 2021</p>

    <p>
    Trym is committed to protecting your privacy and earning your trust. This Privacy Policy (the <b>"Notice"</b>) describes the personal information we collect about you and how we use and protect it. It applies to our current and former customers. It replaces earlier versions that we may have given to you. By using this website (the <b>“Site”</b>), you signify your acceptance of this Notice. 
    </p>
    <p>We will send our policyholders a written privacy policy with any changes at least once a year. Site visitors who are not policyholders should refer to the date at the top of this Notice to see if it has been updated.</p>
    <p>The terms “we,” “us,” or “our” means Trym. The terms “you,” “your,” “customer,” or “user” means you, users of our Site, and any entity or organization you or such users represent. </p>
    <p>For California resident customers, see the “California Consumer Privacy Act Supplement” below for a summary of our personal information practices subject to, and your rights under, that law.</p>
    
    <article>
      <h2>Summary</h2>
      <p>This section summarizes our privacy practices. For more detail, please read the entire Notice.</p>
      <ul>
        <li>We gather information from you, your transactions with us, and outside sources.</li>
        <li>We use your information only to conduct our business and provide insurance to you.</li>
        <li>We will share your information with companies that provide certain products or services you request through us, including Crum & Forster.</li>
        <li>We will not share your information with other companies for their independent marketing purposes without your consent.</li>
        <li>You can limit our use of some of this information for marketing purposes.</li>
        <li>We limit access to your information and use safeguards to help protect it.</li>
        <li>You may review and correct your information.</li>
      </ul>
    </article>

    <article>
      <h2>What information do you collect about me?</h2>
      <p>We collect information about you to quote and service your insurance policy. This is called <b>"Nonpublic Personal Information"</b> or <b>"NPI"</b> if it identifies you and is not available to the public. Depending on the product, we collect it from some or all of the following sources. We have provided a few examples for each source, but not all may apply to you.</p>
      <ul>
        <li>Application Information: You provide this on your online application on our Site. It includes your name, street address, e-mail address, phone number, profession and job title, information about your business property, and information about your business.</li>
        <li>Consumer Report Information: We obtain this from consumer reporting agencies. It includes claims history with other insurers and credit report information. The information is kept by the consumer reporting agencies and disclosed by them to others as permitted by law.</li>
        <li>Transaction Information: This is information about your transactions with us, our affiliates, or others. It includes your insurance coverages, limits and rates, and payment, billing and claims history. It also includes information that we require for billing and payment.</li>
      </ul>
      <p>We also may collect <b>"Website Information"</b> about you. This is unique to Internet activity. It may include how you linked to the Site. It also may include your IP address and information about your device, time of visit, and what pages you visit on our Site. When you visit the Site, we use cookies, web beacons, and other technology to collect information about you and your activities on the Site. We do this to provide services to you, enhance your online experience, and advertise our products and services.</p>
    </article>

    <article>
      <h2>Who might get information about me from you?</h2>
      <p>We will share information about you only as permitted by law. We will not share your NPI with other companies for their independent marketing purposes without your consent. There is no need to "opt out" or tell us not to do this.</p>
      <p>Disclosures include those that we feel are required to provide insurance claims or customer service, prevent fraud, perform research, or comply with the law. Recipients include, for example, our insurance partners, claims representatives, service providers, consumer reporting agencies, insurance agents and brokers, law enforcement, courts and government agencies. They may disclose the information to others as permitted by law. For example, consumer reporting agencies may disclose Transaction Information received from us to other insurance companies with which you do business.</p>
      <p>Where permitted by law, we may also disclose Application or Transaction Information to service providers that help us market our products. These service providers may include financial institutions with which we have joint marketing agreements.</p>
      <p>Some products or services obtained through us will be provided by other companies. We may share your information with these companies. They will use the information as described in their privacy policies. These companies may share with us information about you and your transactions with them.</p>
    </article>

    <article>
      <h2>Can I limit use of my information for marketing?</h2>
      <p>We may use your NPI so we may offer our products and services to you. You may limit some of this marketing by emailing the Trym team at help@trym.co or unsubscribing from marketing emails. Your choice will apply to all people listed on your policy.</p>
    </article>

    <article>
      <h2>How do you protect my information?</h2>
      <p>We restrict access to your information to our employees and others who we feel must use it to provide our products and services or otherwise run our business. Their use of the information is limited by law, our employee code of conduct, and written agreements where appropriate. We also maintain physical, electronic, and procedural safeguards to protect your information.</p>
    </article>

    <article>
      <h2>How can I review and correct information you have about me?</h2>
      <p>To review information we have about you, send a written request to help@trym.co. You must describe the kind of information you want to review and state that your request is in response to this Notice. Include your full name, mailing address, and policy number (if applicable). Within 30 business days, we will describe what is available and how you may request corrections. We will also name anyone we show as having received the information within two years prior to your request. Finally, we will identify the companies that have provided Consumer Report Information about you.</p>
      <p>You may review the information at our offices or receive a copy of it for a fee to cover our costs. We will not provide information that we feel is privileged, such as information about insurance claims or lawsuits.</p>
      <p>To correct information about you, send a written request as described above, explaining your desired correction. Within 30 business days, we will either make the requested correction or tell you why we will not. We cannot correct Consumer Report Information. To do this, you must contact the consumer reporting agency that provided it.</p>
      <p>If we make your requested correction, we will notify you in writing. We will also notify anyone you name who may have received the information within the previous two years. If required by law, we will also notify others who may have given it to or received it from us. If we refuse to make the requested correction, you may file with us a concise written statement about why you object. That statement should include the information you think is correct. We will include your statement in your file. We will send it to the same persons to whom we would send a copy of any correction or change.</p>
    </article>

    <article>
      <h2>Our Insurance Partners</h2>
      <p>Our insurance policies are backed by Crum & Forster, which is governed by a separate privacy policy available at <a href="https://www.cfins.com/terms/">https://www.cfins.com/terms/</a>.</p>
    </article>

    <article>
      <h2>Additional information about internet practices</h2>
      <p>When you visit the Site, we collect Website Information for various reasons. For example, if you start an insurance quote on the Site, but decide to finish it later, we will save your information so you can pick up where you left off. We may use your Website Information to serve ads to you across multiple devices, such as your computer, smartphone, or tablet, or to limit the number of times you see our ads. We may also use your Website Information to customize your experience with and analyze your use of the Site and interaction with our ads.</p>
      <p>We may transmit certain Website Information to third party advertising companies we work with (<b>“Ad Companies”</b>) to, among other purposes, measure our advertising efficiency or track referrals to the Site. Some Ad Companies may be able to associate Website Information with other information they already have about you.</p>
      <p>We advertise through Ad Companies on websites and mobile apps. We also do this on internet-connected platforms such as streaming TV devices. Some of these sites, apps, and platforms may use cookies, web beacons and other technology to capture and share information about you or your device with us or our service providers. These service providers may use information about your visits to and interactions with the Site and other websites, mobile apps and platforms, or the ads themselves, along with information about you, your purchases, or interests (which may include Website Information), to provide ads about goods and services that may interest you (<b>"Interest Based Advertising"</b> or <b>"IBA"</b>). Some of this information may be exchanged with us or other service providers for market research or to analyze and manage our advertising and marketing efforts.</p>
      <p>You can also opt out of receiving certain IBA from Trym by setting the user preferences on your account profile.</p>
      <p>You may have other means of controlling the use of cookies and other tracking technologies on the Site and other websites. You may be able to instruct your browser or mobile device to warn you each time a cookie is being sent, or to reject all or certain kinds of cookies. Typically, you can do this by changing the privacy settings on your internet browser or device. However, if you reject cookies, some websites may not function properly, and some website services may be diminished. You may manage Flash cookies using the Adobe Flash PlayerTM Settings Manager at www.adobe.com. In most cases, you must manage these settings on each of your browsers or devices. For example, blocking cookies on your desktop browser may not work for your mobile device.</p>
      <p>We believe that these means of controlling the use of cookies and other tracking technologies constitute reasonable choice regarding Website Information and IBA. But some cookies and other tracking technologies may persist on the Site and other websites.</p>
    </article>

    <article>
      <h2>Why do you ask for my e-mail address?</h2>
      <p>When you quote or buy insurance on the Site, we collect your e-mail address. We do this so that we can send you e-mails concerning your transactions with us. We may also send updates with other information we think you will find useful. You can stop these updates by using the links provided in the emails. Customers may also manage their preferences in the user preferences of your account profile.</p>
      <p>We will only share your e-mail address with our service providers and partners. We will not share your e-mail address with other companies for their marketing purposes without your consent.</p>
    </article>

    <article>
      <h2>What about website security?</h2>
      <p>Our security measures help secure your data, so that others cannot access it while in transit. We limit access to our servers and require our employees to adhere to high standards of ethics and integrity in performing their jobs.</p>
    </article>

    <article>
      <h2>What about links to other websites?</h2>
      <p>The Site contains links to other sites that we do not maintain. We make no warranties or representations about other websites. We recommend that you read the privacy and security policies of those sites.</p>
    </article>

    <article>
      <h2>California Consumer Privacy Act Supplement</h2>
      <p>This Supplement (<b>"Supplement"</b>) applies only to California residents. It describes our collection and use of Personal Information that is subject to the California Consumer Privacy Act (<b>"CCPA"</b>) and your rights under that law. This Supplement does not apply to information that is exempt from or otherwise unregulated by CCPA.</p>
    </article>

    <article>
      <h2>Personal Information we may collect and purposes for which we may use it</h2>
      <p><b>“Personal Information”</b> is information that identifies, relates to, describes, is capable of being associated with, or could reasonably be linked, directly or indirectly with a particular consumer (a natural person who resides in California) or household in California. We collect some or all the following categories of Personal Information about you and your household:</p>
      <ul>
        <li><b>“Identifiers”</b> such as a real name, alias, postal address, unique personal identifier, online identifier, internet protocol address, email address, account name, Social Security number, driver’s license number, passport number, or other similar identifiers.</li>
        <li><b>“CCR Categories”</b> may include the following categories of Personal Information described in the California Customer Records statute: name, signature, Social Security number, physical characteristics or description, address, telephone number, passport number, driver’s license or state identification card number, insurance policy number, education, employment, employment history, bank account number, credit card number, debit card number, or any other financial information, medical information, or health insurance information.</li>
        <li><b>“Protected Classification Characteristics”</b> under California or federal law such as age, marital status, gender, race, religion, national origin, and disability.</li>
        <li><b>“Commercial Information”</b> may include records of personal property, products or services purchased, obtained, or considered, or other purchasing or consuming histories or tendencies.</li>
        <li><b>“Biometric Information”</b> means an individual's physiological, biological, or behavioral characteristics that can be used to establish an individual's identity. Examples may include fingerprints; face, hand, palm or vein pattern imagery; voice recordings; or sleep, health, or exercise data that contain identifying information.</li>
        <li><b>“Internet or Other Electronic Network Activity Information”</b> may include, for example, website information, browsing history, search history, information on a consumer’s interaction with an internet website, application, or advertisement.
  “Geolocation Data” about physical location or movements.</li>
        <li><b>“Audio, Electronic, Visual, and Similar Information”</b> such as recorded phone call and other audio recordings."</li>
        <li><b>“Professional or Employment--Related Information”</b> such as information about current or past employment.</li>
        <li><b>“Education Information”</b> such as education level.</li>
        <li><b>“Inferences”</b> drawn from other Personal Information to create a profile about a consumer reflecting the consumer's preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes.</li>
      </ul>
    </article>

    <article>
    <h2>Our Personal Information practices</h2>
    <p>This section describes our practices with respect to Personal Information. For each category of Personal Information that we collect about consumers and may collect about you and your household, we describe the categories of (1) sources from which the Personal Information has been collected from and (2) Third Parties with whom the Personal Information has been shared.</p>
    
    <section>
      <h3>Identifiers</h3>
      <p>Sources collected from:</p>
      <ul>
        <li>Consumers or observation of consumers or their interactions with us</li>
        <li>Service providers</li>
        <li>Data vendors</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
        <li>Third-party product providers</li>
        <li>Government entities</li>
      </ul>
      <p>Third parties shared with:</p>
      <ul>
        <li>Service providers</li>
        <li>Data vendors</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
        <li>People and entities involved in insurance claims, litigation, and fraud prevention and detection</li>
        <li>Third-party product providers</li>
        <li>Government entities</li>
      </ul>
    </section>

    <section>
      <h3>CCR Categories</h3>
      <p>Sources collected from:</p>
      <ul>
        <li>Consumers or observation of consumers or their interactions with us</li>
        <li>Service providers</li>
        <li>Data vendors</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
        <li>Third-party product providers</li>
        <li>Government entities</li>
      </ul>

      <p>Third parties shared with:</p>
      <ul>
        <li>Service providers</li>
        <li>Data vendors</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
        <li>People and entities involved in insurance claims, litigation, and fraud prevention and detection</li>
        <li>Third-party product providers</li>
        <li>Government entities</li>
      </ul>
    </section>

    <section>
      <h3>Protected Classification Characteristics</h3>
      <p>Sources collected from:</p>
      <ul>
        <li>Consumers or observation of consumers or their interactions with us</li>
        <li>Data vendors</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
        <li>Third-party product providers</li>
        <li>Government entities</li>
      </ul>

      <p>Third parties shared with:</p>
      <ul>
        <li>Service providers</li>
        <li>Data vendors</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
        <li>People and entities involved in insurance claims, litigation, and fraud prevention and detection</li>
        <li>Third-party product providers</li>
        <li>Government entities</li>
      </ul>
    </section>

    <section>
      <h3>Commercial Information</h3>
      <p>Sources collected from:</p>
      <ul>
        <li>Consumers or observation of consumers or their interactions with us</li>
        <li>Data vendors</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
        <li>Third-party product providers</li>
        <li>Government entities</li>
      </ul>

      <p>Third parties shared with:</p>
      <ul>
        <li>Service providers</li>
        <li>Data vendors</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
        <li>People and entities involved in insurance claims, litigation, and fraud prevention and detection</li>
        <li>Third-party product providers</li>
        <li>Government entities</li>
      </ul>
    </section>

    <section>
      <h3>Internet or Other Electronic Network Activity Information</h3>
      <p>Sources collected from:</p>
      <ul>
        <li>Consumers or observation of consumers or their interactions with us</li>
        <li>Service providers</li>
        <li>Data vendors</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
        <li>Third-party product providers</li>
        <li>Government entities</li>
      </ul>

      <p>Third parties shared with:</p>
      <ul>
        <li>Service providers</li>
        <li>Data vendors</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
        <li>People and entities involved in insurance claims, litigation, and fraud prevention and detection</li>
        <li>Third-party product providers</li>
        <li>Government entities</li>
      </ul>
    </section>

    <section>
      <h3>Geolocation Data</h3>
      <p>Sources collected from:</p>
      <ul>
        <li>Consumers or observation of consumers or their interactions with us</li>
        <li>Service providers</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
      </ul>

      <p>Third parties shared with:</p>
      <ul>
        <li>Service providers</li>
        <li>Data vendors</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
        <li>Government entities</li>
      </ul>
    </section>

    <section>
      <h3>Audio, Electronic, Visual, and Similar Information</h3>
      <p>Sources collected from:</p>
      <ul>
        <li>Consumers or observation of consumers or their interactions with us</li>
      </ul>

      <p>Third parties shared with:</p>
      <ul>
        <li>Service providers</li>
        <li>Data vendors</li>
        <li>People and entities involved in insurance claims, litigation, and fraud prevention and detection</li>
        <li>Government entities</li>
      </ul>
    </section>

    <section>
      <h3>Professional or Employment-Related Information</h3>
      <p>Sources collected from:</p>
      <ul>
        <li>Consumers or observation of consumers or their interactions with us</li>
        <li>Data vendors</li>
        <li>Government entities</li>
      </ul>

      <p>Third parties shared with:</p>
      <ul>
        <li>Service providers</li>
        <li>Data vendors</li>
        <li>Government entities</li>
      </ul>
    </section>

    <section>
      <h3>Education Information</h3>
      <p>Sources collected from:</p>
      <ul>
        <li>Consumers or observation of consumers or their interactions with us</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
      </ul>

      <p>Third parties shared with:</p>
      <ul>
        <li>Service providers</li>
        <li>Data vendors</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
        <li>Government entities</li>
      </ul>
    </section>

    <section>
      <h3>Inferences</h3>
      <p>Sources collected from:</p>
      <ul>
        <li>Service providers</li>
        <li>Data vendors</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
        <li>Third-party product providers</li>
        <li>Government entities</li>
        <li>Ourselves</li>
      </ul>

      <p>Third parties shared with:</p>
      <ul>
        <li>Service providers</li>
        <li>Data vendors</li>
        <li>Lead sources and aggregators, social media, advertising networks, and other third-party sites, web browsers and search engines</li>
        <li>Third-party product providers</li>
        <li>Government entities</li>
      </ul>

      <p>We also may collect Biometric Information about you or your household.</p>
      <p>We have disclosed each category of Personal Information to one or more third parties for a business or commercial purpose. The business or commercial purposes for which we collected each category of Personal Information and the purposes for which we will use the Personal Information that we collect about you and your household may include the following:
        <ul>
          <li>communicating and interacting with consumers;</li>
          <li>consumer experience and service;</li>
          <li>marketing products and services offered by us or others;</li>
          <li>analytics, research, design, development, and pricing;</li>
          <li>counting ad impressions, verifying positioning and quality of ad impressions, auditing compliance, and other auditing;</li>
          <li>monitoring social media and other online references to us;</li>
          <li>litigation and claims handling;</li>
          <li>preventing, detecting and prosecuting malicious, deceptive, fraudulent or illegal activity;</li>
          <li>risk control, debugging and otherwise maintaining or protecting our data and systems and ourselves;</li>
          <li>to effect, administer, enforce, service, process, provide or maintain a product, service, account, or transaction with or for a consumer or business;</li>
          <li>in connection with a proposed or actual securitization, merger, acquisition, sale (including sale of servicing rights) or similar transaction related to, involving or impacting a consumer;</li>
          <li>providing information to regulators, law enforcement, other governmental entities and our attorneys, accountants and auditors;</li>
          <li>making disclosures required by and otherwise complying with legal requirements or legal, regulatory or other governmental investigations or process;</li>
          <li>any other purposes disclosed to or authorized or approved by a consumer; and</li>
          <li>sharing information with our insurance partners, including Crum & Forster.</li>
        </ul>
      </p>
      </section>
    </article>

    <article>
      <h2>Your rights to know and delete</h2>
      <p>To the extent provided by law, up to twice per year, you may request that we disclose to you free of charge:</p>
      <ul>
        <li>the categories of Personal Information we collected about you in the past twelve months;</li>
        <li>the categories of sources from which it is collected;</li>
        <li>our business or commercial purpose for collecting or selling Personal Information;</li>
        <li>the categories of third parties with whom we share Personal Information;</li>
        <li>the specific pieces of Personal Information we collected about you; and</li>
        <li>the categories of Personal Information about you that we disclosed for a business purpose.</li>
      </ul>
      <p>To the extent provided by law, you may request that we delete Personal Information that we collected from you.</p>
      <p>To exercise the rights described above, please email help@trym.co with your request. If you want us to disclose specific pieces of Personal Information that we collected about you or delete Personal Information that we collected from you, you also must provide us with a copy of the front and back of your driver's license or other government-issued identification card. We will either make the requested deletion or tell you why we will not.</p>
    </article>

    <article>
      <h2>Your rights regarding the sale of your Personal Information</h2>
      <p>We will not sell information about you. You do not need to tell us to refrain from selling your information.</p>
    </article>

    <article>
      <h2>Nondiscrimination</h2>
      <p>We may charge a different price for goods or services if the difference is reasonably related to the value of your Personal Information. We will not otherwise discriminate against you for exercising your rights under the CCPA.</p>
    </article>

    <article>
      <h2>Verifying requests</h2>
      <p>When you ask us to disclose specific pieces of Personal Information to you or to delete Personal Information we obtained from you, we will verify that you are the consumer about whom we have collected the Personal Information. We will do this by matching identifying information provided by you to Personal Information about you that we have on file, when possible. If we do not have enough information to do this matching, we may try to call you to verify your identity. Examples of information you may be asked to provide during such calls include your name, address history, and information about your interactions with us.</p>
    </article>

    <article>
      <h2>How an agent can make requests on your behalf</h2>
      <p>You may designate an agent to make requests on your behalf under the CCPA. To make such a request, your agent must obtain a request form by emailing help@trym.co. The agent must mail the form to 18440 N 68th St #4040, Phoenix, AZ 85054 with proof of a power of attorney from you or your signed permission authorizing the agent to make the request for you. If the agent requests disclosure of specific pieces of personal information or deletion of personal information, the agent also must send us a copy of the front and back of your driver's license or other government-issued ID card. If the agent does not provide us with proof of power of attorney, we may contact you to directly verify with you your identity and that you gave the agent permission to submit the request.</p>
    </article>

    <article>
      <h2>Questions and concerns</h2>
      <p>If you want to access this notice in a different format because of disability, or if you have any other CCPA questions or concerns, you may email <a href="mailto:help@trym.co">help@trym.co</a></p>
    </article>
  </TitledInfoPage>
)

export default Privacy
