import React from 'react'

export default ({step}) => {
  return (
    <div className="progress">
      <div className="progress__bar">
        <div className="progress__bar-line" style={{width: `${(steps.length-1)/steps.length*100}%`, left: `${50/steps.length}%`}} />
        <div className="progress__bar-line-complete" style={{width: `${(step)/steps.length*100}%`, left: `${50/steps.length}%`}} />
        {steps.map((s, i) => (
          <div key={s}>
            <div key={s} className={`progress__bar-circle${i === step ? '--current' : (i > step ? '--pending' : '')}`} />
          </div>
        ))}
      </div>
      <div className="progress__labels">
        {steps.map((s, i) => <div key={s} className={`progress__label${i === step ? '--current' : ''}`}>{s}</div>)}
      </div>
    </div>
  )
}

const steps = [
  'Start',
  'Tailor',
  'Trym',
  'Quote',
  'Purchase'
]