module.exports = classCodeLookup

const stateLookups = {}

// Returns a Promise
function classCodeLookup(state, searchText) {
    if (!state) return Promise.resolve([])
    if (!stateLookups[state]) {
        stateLookups[state] = fetch(`/api/class-codes/${state}`).then(res => res.json())
    }

    if (!searchText) return stateLookups[state] // optimization
    return stateLookups[state].then(results => results.filter(codeMatchesSearch(searchText)))
}

function codeMatchesSearch(searchText) {
    searchText = searchText.toLowerCase()
    return function (type) {
        return type.name.toLowerCase().includes(searchText)
    }
}
