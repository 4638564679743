import React from "react";
import darnIt from "../img/darn-it.svg";
import Header from "../Header";
import PrimaryButtonLink from "../PrimaryButton";

const QuoteDenial = ({ reasons, businessName }) => {
  return (
    <div className="app q-flow ">
      <Header></Header>
      <div className="app__main home__top denial__container">
        <div className="text-center padding-bottom--medium">
          <h2>
            <img alt="Darn It!" src={darnIt} />
          </h2>
          <span> we can't insure {businessName} </span>
        </div>
        <div className="quote-denial-page">
          <section>
            <p>
              <b>The reason why?</b>
            </p>
            {reasons &&
              reasons.map((reason) => {
                return <p key={reason}>{reason}</p>
              })}
            <p>
              You still have options. Your best bet is to search online for
              other business insurance providers
            </p>
            <p>
              We'll stay in touch with helpful content on how to protect, start
              and grow your business
            </p>
            <p>Much love,</p>
            <p>The Trym team</p>
          </section>
        </div>
        <div className="margin-top--medium margin-bottom--medium">
          <PrimaryButtonLink className="primary">
            Go back to the homepage
          </PrimaryButtonLink>
        </div>
      </div>
    </div>
  );
};

export default QuoteDenial;
