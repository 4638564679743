import React from 'react'
import FormError from '../components/FormError';
export default ({onChange, value, options, placeholder, error}) => {
  const _onChange = e => {
    onChange(e.target.value)
  }

  return (
    <>
      <select className={`select ${error ? "error" : ""}`} value={value || ""} onChange={_onChange}>
        { !value && <option value="">{placeholder || 'Select'}</option> }
        { options.map((opt, i) => (
          <option value={opt.key == null ? opt : opt.key} key={i}>{opt.value || opt}</option>
        )) }
      </select>    
      <FormError error={error} />
    </>
  )
}
