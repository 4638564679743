import { useEffect, useState } from 'react'

export const merge = (a, b) => (a && a.constructor === Object && b && b.constructor === Object)
? {...a, ...b}
: b == null && a != null
  ? a
  : b

export function getLocalJSON(key) {
  const val = localStorage.getItem(key)
  return val == null ? null : JSON.parse(val)
}
export function useLocalState(key, defaultValue, supersedingValue) {
  const cv = supersedingValue == null ? getLocalJSON(key) : supersedingValue
  const initialValue = cv == null ? defaultValue : cv
  const [val, setVal] = useState(initialValue)
  const setStorageAndVal = update => {
    if (typeof update === 'function') {
      function spy(state, props) {
        const newState = update(state, props)
        localStorage.setItem(key, JSON.stringify(newState))
        return newState
      }
      return setVal(spy)
    } else {
      localStorage.setItem(key, JSON.stringify(update))
      return setVal(update)
    }
  }
  return [val, setStorageAndVal]
}

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay)

    return () => {
      clearTimeout(handler)
    };
  }, [value, delay])

  return debouncedValue
}

export function setQuoteProgress(url, state) {
  localStorage.setItem('quote-progress', JSON.stringify({url, state}))
}

export function getQuoteProgress() {
  const progress = getLocalJSON('quote-progress')
  if (!progress || !progress.url) return null
  return progress
}

export function useLocalSchemaVersion(version) {
  const versionKey = '__schema'
  const storedVersion = localStorage.getItem(versionKey)
  if (storedVersion !== version) {
    localStorage.clear()
    localStorage.setItem(versionKey, version)
  }
}
