import React, { useEffect, createContext, useState } from "react";
import api from "../api/index";
import firebase from "firebase/app";
import cookies from "js-cookie";

// initial state
export const UserContext = createContext({
  user: {},
  signOut: {},
  profile: {},
  setAuthData: () => {},
});

export const UserProvider = ({ children, history }) => {
  const [profile, setProfile] = useState("");
  const [auth, setAuth] = useState({loggedIn: null});

  const setAuthData = async (data) => {
    let idToken = null;
    if(data.user) {
      idToken = await data.user.getIdToken();
      cookies.set("idToken", idToken);
      await setProfile(await api.get(idToken, `/api/profile`));
    }
    setAuth({ ...data, token: idToken });
  };

  const signOut = () => {
    firebase.auth().signOut()
    history.replace('/');
  }

  useEffect(() => {
    const firebaseAuth = firebase.auth();
    firebaseAuth.onAuthStateChanged(
      (user) => { 
        setAuthData({
          user,
          signOut,
          ...auth,
          loggedIn: user !== null,
        })},
      (error) => console.error(error)
    )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UserContext.Provider value={{...auth, profile}}>
      {children}
    </UserContext.Provider>
  );
};
