import React from 'react'
import { Link } from 'react-router-dom'
import logo from './images/trym-logo-black.svg'

// import facebook from './images/facebook.svg'
// import twitter from './images/twitter.svg'
// import linkedin from './images/linkedin.svg'
import styled from 'styled-components'
import { gray, darkGray, lightGreen, mobileMedia } from './styles'

const FooterComponent = () => {

  return (
    <FooterContainer>
      <Footer>
        <FooterTop>
          <Link to="/">
            <FooterLogo src={logo} alt="trym logo" />
          </Link>
          <FooterEmail href="mailto:help@trym.co">help@trym.co</FooterEmail>
        </FooterTop>
        <FooterMain>
          <FooterSection>
            <FooterSectionTitle>Insurance</FooterSectionTitle>
            <FooterLink to="/whats-covered">What's covered</FooterLink>
            <FooterLink to="/who-we-insure">Who we insure</FooterLink>
            <FooterLink to="/how-claims-work">How claims work</FooterLink>
            <FooterLink to="/certificate-of-insurance">Certificate of Insurance</FooterLink>
            <FooterLink to="/switch-to-trym">Switch to Trym</FooterLink>
          </FooterSection>
          <FooterSection>
            <FooterSectionTitle>About Trym</FooterSectionTitle>
            <FooterLink to="/our-story">Our story</FooterLink>
            <FooterLink to="/agents">Agent program</FooterLink>
            <FooterLink to="/states">States we're in</FooterLink>
            {/* <FooterLink to="#" className="tbd">Press</FooterLink>
            <FooterLink to="#" className="tbd">Contact</FooterLink> */}
          </FooterSection>
          {/* <FooterSection>
            <FooterSectionTitle>Learn</FooterSectionTitle>
            <FooterLink to="#" className="tbd">Should you start a business?</FooterLink>
            <FooterLink to="#" className="tbd">Start your business</FooterLink>
            <FooterLink to="#" className="tbd">Run your business</FooterLink>
            <FooterLink to="#" className="tbd">Grow your business</FooterLink>
            <FooterLink to="#" className="tbd">Insure your business</FooterLink>
          </FooterSection> */}
        </FooterMain>
        <FooterBottom>
          {/* <FooterSocial>
            <a href="#" className="tbd"><FooterSocialIcon src={facebook} /></a>
            <a href="#" className="tbd"><FooterSocialIcon src={twitter} /></a>
            <a href="#" className="tbd"><FooterSocialIcon src={linkedin} /></a>
          </FooterSocial> */}
          <FooterBottomLink to="/terms-of-service">Terms of service</FooterBottomLink>
          <FooterBottomLink to="/privacy-policy">Privacy policy</FooterBottomLink>
          <FooterBottomLink to="/licenses">Licenses</FooterBottomLink>
          <FooterBottomLink to="/dashboard">Login</FooterBottomLink>
        </FooterBottom>
      </Footer>
    </FooterContainer>
  )
}

export const PrimaryButtonLink = styled.a`
  color: white;
  cursor: pointer;
  background-color: ${lightGreen};
  font-size: 1.16rem;
  font-weight: 500;
  padding: 1rem 3rem;
  border-radius: 1.58rem;

  &.small {
    padding: 0.5rem 1.5rem;
    border-radius: 1.08rem;  
  }

  &.extra.small {
    font-size: 0.9rem;
    padding: 0.25rem 0.8rem;
  }
`

const FooterContainer = styled.div`
  background-color: #f3f3f3;
`
const Footer = styled.footer`
  padding: 4rem 0 3rem 0;
  width: 800px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  ${mobileMedia} {
    padding: 1rem;
    width: unset;
    align-items: center;
  }
`
const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 4px solid rgba(216,216,216,0.2);
  
  ${mobileMedia} {
    flex-direction: column;
    width: 100%;
    padding-bottom: 2rem;
  }
`
const FooterLogo = styled.img`
  padding: 1rem 0;
`
const FooterEmail = styled.a`
  color: black;
  text-decoration: none;
  &:hover { text-decoration: underline; }
  font-weight: 500;
`
const FooterMain = styled.div`
  display: flex;
  margin-bottom: 3rem;

  ${mobileMedia} {
    flex-direction: column;
    align-items: center;
    margin-bottom: 2rem;
  }
`
const FooterSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 1rem;

  ${mobileMedia} {
    align-items: center;
  }
`
const FooterSectionTitle = styled.h2`
  font-size: 1.25rem;
  font-weight: 500;
`
const FooterLink = styled(Link)`
  color: ${darkGray};
  text-decoration: none;
  &:hover { text-decoration: underline; }
  font-size: 0.9rem;
  line-height: 2.5rem;

  ${mobileMedia} {
    line-height: 1.8rem;
  }
`
const FooterBottom = styled.div`
  display: flex;
  align-items: center;

  ${mobileMedia} {
    flex-direction: column;
    margin-bottom: 1rem;
  }
`
// const FooterSocial = styled.div`
//   width: 6rem;
//   display: flex;
//   justify-content: space-between;
//   margin: 0 4rem 0 0;

//   ${mobileMedia} {
//     margin: 1rem 0;
//   }
// `
// const FooterSocialIcon = styled.img`
//   height: 1rem;
// `
const FooterBottomLink = styled(Link)`
  color: ${gray};
  text-decoration: none;
  &:hover { text-decoration: underline; }
  margin-right: 1.25rem;
  line-height: 1.4rem;

  ${mobileMedia} {
    margin: 0;
  }
`

export default FooterComponent
