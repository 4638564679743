import React from 'react'
import AsyncSelect from 'react-select/async'
import FormError from "../components/FormError";

export default ({ onChange, lookup, answers, getOptionLabel, getOptionValue, placeholder, value, error }) => {
  const loadOptions = inputValue => {
    return lookup(inputValue, answers)
  }

  return (
    <>
    <AsyncSelect
      defaultOptions
      loadOptions={loadOptions}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      placeholder={placeholder}
      escapeClearsValue
      isClearable
      value={value}
      onChange={onChange}
    />
    { error && <FormError error={error} />}
    </>
  )
}
