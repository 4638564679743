import React from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react";
import config from './config'
import firebase from 'firebase/app'
import 'firebase/auth'
import './base.scss'
import './index.scss'
import './questions.css'
import 'firebaseui/dist/firebaseui.css'
import './firebaseui-overrides.css'
import App from './App'

firebase.initializeApp(config.identity)
Sentry.init({ dsn: config.sentry.dsn });

ReactDOM.render(<App />, document.getElementById('root'))
window.AOS && window.AOS.init()

if ((new URLSearchParams(window.location.search)).has('c')) {
  const style = document.createElement('style')
  document.head.appendChild(style)
  style.type = 'text/css'
  const css = 'a[href="#"], .tbd { background: repeating-linear-gradient(45deg, #ff7, #ff7 10px, #fc7 10px, #fc7 20px) !important; }'
  style.appendChild(document.createTextNode(css))
}
