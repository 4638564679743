import React, { useCallback, useState } from "react";
import firebase from "firebase/app";

const Signup = ({ history }) => {
  const [errors, setError] = useState({});

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { email, password, confirmPassword } = e.target.elements;
      if (email.value === "") {
        setError({ ...errors, email: "Email is required" });
        setError({ ...errors, unknown: "An unknown error has occurred" });
        return;
      }

      if (password.value === "") {
        setError({ ...errors, password: "Password is Required" });
        return;
      }

      if (confirmPassword.value !== password.value) {
        setError({ ...errors, confirmPassword: "Passwords do not match" });
        return;
      }

      // verify email password match
      try {
        await firebase
          .auth()
          .createUserWithEmailAndPassword(email.value, password.value);
      } catch (error) {
        let errorCode = error.code;
        if (errorCode === "auth/weak-password") {
          setError({ ...errors, password: "The password is too weak" });
        } else if (errorCode === "auth/email-already-in-use") {
          setError({ ...errors, email: "Email Already in Use" });
        } else if (errorCode === "auth/invalid-email") {
          setError({ ...errors, email: "The email is invalid" });
        } else {
          setError({ ...errors, unknown: "An unknown error has occurred" });
          console.error(error)
        }
      }
    },
    [errors]
  );

  return (
    <form onSubmit={handleSubmit}>
      <div className="signup__form">
        <input
          type="text"
          name="email"
          placeholder="Email"
          className={errors.email ? "error" : ""}
        />
        {errors.email && (
          <span role="alert" className="error-text signup__error">
            {errors.email}
          </span>
        )}
        <input
          type="password"
          name="password"
          placeholder="Password"
          className={errors.password ? "error mt-1" : "mt-1"}
        />
        {errors.password && (
          <span role="alert" className="error-text signup__error">
            {errors.password}
          </span>
        )}
        <input
          type="password"
          name="confirmPassword"
          placeholder="Confirm Password"
          className={errors.confirmPassword ? "error mt-1" : "mt-1"}
        />
        {errors.confirmPassword && (
          <span role="alert" className="error-text signup__error">
            {errors.confirmPassword}
          </span>
        )}
        <div className="text-center large-margin-top">
          <button type="submit" className="button blue signup">
            Create Account
          </button>
          {errors.unknown && (
            <span role="alert" className="error-text signup__error">
              {errors.unknown}
            </span>
          )}
        </div>
      </div>
    </form>
  );
};

export default Signup;
