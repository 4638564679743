import React from 'react'
import styled from 'styled-components'

const HamburgerToggle = height => {
  const Styled = styled.div`
    width: ${height*1.3333}px;
    height: ${height}px;
    position: relative;
    margin: ${height*1.1111}px auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;

    span {
      display: block;
      position: absolute;
      height: ${height*0.075}px;
      width: 100%;
      background: #979797;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;

      &:nth-child(1) {
        top: 0px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
      &:nth-child(2) {
        top: ${height*0.4}px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
      &:nth-child(3) {
        top: ${height*0.8}px;
        -webkit-transform-origin: left center;
        -moz-transform-origin: left center;
        -o-transform-origin: left center;
        transform-origin: left center;
      }
    }
    &.open span {
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: -${height*0.0666}px;
        left: ${height*0.1777}px;
      }
      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: ${height*0.8666}px;
        left: ${height*0.1777}px;
      }
    }
  `

  return ({value, onChange}) => {
    return (
      <Styled className={value ? 'open' : ''} onClick={() => onChange(!value)}>
        <span />
        <span />
        <span />
      </Styled>
    )
  }
}

export default HamburgerToggle