import React, {Fragment} from 'react'
import InfoPage from './InfoPage'
import uiCert from './img/ui-certificate.png'
import copyGreen from './img/copy-green-icon.svg'
import clipboardGreen from './img/clipboard-green-icon.svg'
import hourglassGreen from './img/hourglass-green-icon.svg'

export default () => (
  <InfoPage className="page certificate-page" render={render} />
)

function render(openQuote) {
  return (
    <Fragment>
      <section className="page-header">
        <div className="small-container">
          <h1>Send a certificate of insurance today.</h1>
          <p>Right after you buy a policy, you can send proof of insurance to landlords, clients, whoever</p>
        </div>
        <div className="regular-container">
          <img src={uiCert} className="fluid-img certificate-ui" alt="Page to generate a new certificate. Fill out the form below and we will prepare your proof of insurance for your certificate holder. Certificate holder's information." />
        </div>
      </section>

      <section className="hiw-certificate">
        <div className="regular-container">
          <h2 className="large-margin-bottom">How it works</h2>
          
          <div className="list-item">
            <div className="list-number">1</div>Buy a policy in a few clicks
          </div>
          <div className="list-item">
            <div className="list-number">2</div>Tell us about your certificate holder
          </div>
          <div className="list-item">
            <div className="list-number">3</div>Enter custom language required by your certificate holder (like “this insurance is for project XYZ”)
          </div>
          <div className="list-item">
            <div className="list-number">4</div>Add any additional insured folks you’d like
          </div>
          <div className="list-item">
            <div className="list-number">5</div>Generate a new certificate; we'll send you back an official ACORD-PDF in less than 24 hours
          </div>
          <div className="list-item green">
            <div className="list-number">6</div>Send!
          </div>

          <div className="wait-box">
            The steps take a few minutes; we'll get you your certificate, and you’ll be done showing proof of insurance so you can get back to business.
          </div>
        </div>
      </section>

      <section className="with-trym-certificate">
        <div className="regular-container">
          <h2 className="text-white medium-margin-bottom">With Trym...</h2>
          <div className="point-with-icon">
            <img src={copyGreen} alt="Create" />
            <p className="text-white">Create as many certificates of insurance as you need, anytime you want, 24/7/365 days a year</p>
          </div>
          <div className="point-with-icon">
            <img src={clipboardGreen} alt="Proof" />
            <p className="text-white">Give peace of mind to the person and/or company asking you for proof of insurance</p>
          </div>
          <div className="point-with-icon">
            <img src={hourglassGreen} alt="Fast" />
            <p className="text-white">Complete the process in a few minutes, because we know insurance is the last thing on your mind (even though we’re an insurance company!)</p>
          </div>
        </div>
      </section>


      <div className="bottom-cta">
        <div className="large-container">
          <div className="cta-box">
            <h2 className="text-center medium-margin-bottom">Let’s get your small business insured</h2>
            <button className="button primary full-width" onClick={openQuote}>Start your quote</button>
          </div>
        </div>
      </div>
    </Fragment>
  )
}
